import React from 'react';
import './index.css';

type Props = {
  widthImage: any;
  region: any;
  topImage: any;
  choose: any;
  number: any;
  selectedRegion: any;
};

const ArrowIndicator: React.FC<Props> = ({ widthImage, region, topImage, choose, number, selectedRegion }) => {
  let element = document.getElementById(region);
  const positionImage = element?.getBoundingClientRect();
  const top = positionImage?.top || 0;
  const left = positionImage?.left || 0;
  const width = positionImage?.width || 0;
  const widthSection = widthImage - (left + width / 2);
  const widthArrow = widthSection - 14;
  // console.log(selectedRegion, region);
  
  if (selectedRegion !== region) {
    // @ts-ignore
    element.style.fill = '#A4ADC9';
    // @ts-ignore
    element.style.stroke = '#FFFFFF';
    // @ts-ignore
    element.classList.add('exist');
  }

  function onChoose() {
    choose(region);
  }

  return (
    <section
      className="arrowIndicator"
      style={{ width: `${widthSection}px`, top: `${top - topImage + 50 + 15}px`, left: `${left + width / 2 - 10}px` }}
    >
      <div className="dot" />
      <div className="arrow" style={{ width: `${widthArrow}px` }} />
      <div className="button" onClick={onChoose} />
      <div className="number">{number}</div>
    </section>
  );
};

export default ArrowIndicator;
