import React, { useEffect, useState } from 'react';
import StoreItem from './StoreItem/StoreItem';
import './index.css';

type Props = {
  storeHosts: any;
  warning: any;
  critical: any;
  selectStore: any;
};

const StoreList: React.FC<Props> = ({ storeHosts, warning, critical, selectStore }) => {
  const [infoStore, setInfoStore] = useState<any>({});

  function getStores() {
    // @ts-ignore
    const stores = [];
    for (const key in infoStore) {
      if (Object.prototype.hasOwnProperty.call(infoStore, key)) {
        const storeElement = (
          <StoreItem
            name={key}
            arrayHosts={infoStore[key]}
            warning={warning}
            critical={critical}
            selectStore={selectStore}
            infoStore={infoStore[key]}
          />
        );
        stores.push(storeElement);
      }
    }
    console.log(stores);
    
    // @ts-ignore
    return stores;
  }

  useEffect(() => {
    const arryStoresInfo = {};
    storeHosts.map((store: any) => {
      const [
        hostStatus,
        hostName,
        storeName,
        device,
        numberDevice,
        numServicesOk,
        numServicesWarn,
        numServicesUnknown,
        numServicesCrit,
        numServicesPending,
      ] = store;
      // @ts-ignore
      if (!arryStoresInfo[storeName]) {
        // @ts-ignore
        arryStoresInfo[storeName] = [];
      }
      // @ts-ignore
      arryStoresInfo[storeName].push({
        hostName,
        hostStatus,
        numServicesOk,
        numServicesWarn,
        numServicesUnknown,
        numServicesCrit,
        numServicesPending,
        device,
        numberDevice
      });
    });
    setInfoStore(arryStoresInfo);
  }, [storeHosts]);

  return <section className="store-list">{storeHosts.length ? getStores() : null}</section>;
};

export default StoreList;
