// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.elements-container {
  width: 280px;
  min-width: 280px;
  height: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  background: white;
  overflow-y: auto;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  border-radius: 8px;
  margin-right: 20px;
}

.elements-container .device-name {
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: deepskyblue;
}`, "",{"version":3,"sources":["webpack://./components/ElementsContainer/index.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,gBAAgB;EAChB,2FAA2F;EAC3F,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;AACpB","sourcesContent":[".elements-container {\n  width: 280px;\n  min-width: 280px;\n  height: 100%;\n  padding: 5px;\n  display: flex;\n  flex-direction: column;\n  background: white;\n  overflow-y: auto;\n  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;\n  border-radius: 8px;\n  margin-right: 20px;\n}\n\n.elements-container .device-name {\n  margin-bottom: 12px;\n  font-size: 16px;\n  font-weight: bold;\n  text-align: center;\n  color: deepskyblue;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
