import React, { useEffect } from 'react';
import CardDevice from './CardDevice/CardDevice';
import './index.css';

type Props = {
  name: any;
  arrayDevices: any;
};

const ElementsContainer: React.FC<Props> = ({ name, arrayDevices }) => {
  function getTypeDevice() {
    if (name === 'AP') {
      return 'Access point';
    } else if (name === 'RT') {
      return 'Router';
    } else if (name === 'SW') {
      return 'Switch';
    } else if (name === 'FW') {
      return 'Firewall';
    } else {
      return 'Host';
    }
  }

  function getDevices() {
    arrayDevices.sort(function (dvc1: any, dvc2: any) {
      if (dvc1.status === 'DOWN') {
        return -1;
      } else {
        return 1;
      }
    });
    return arrayDevices.map((dvc: any, index: any) => (
      <CardDevice
        key={`${index}-card-device`}
        status={dvc.status}
        deviceName={dvc.host}
        deviceId={dvc.number}
        deviceIp={'0.0.0.0'}
        deviceType={name}
      />
    ));
  }
  useEffect(() => {}, []);

  return (
    <section className="elements-container">
      <div className="device-name">{getTypeDevice()}</div>
      {getDevices()}
    </section>
  );
};

export default ElementsContainer;
