/* eslint-disable */
// @ts-nocheck
import React, { useEffect, useState, useRef } from 'react';
import ChileMapSvg from './chile';
import ArrowIndicator from './components/ArrowIndicator/ArrowIndicator';
import './index.css';

type Props = {
  chooseRegion: any;
  regiones: any;
  info: any;
  regionNumber: any;
  region: any
};

const GeographicMap: React.FC<Props> = ({ chooseRegion, regiones, info, regionNumber, region }) => {
  const [indicators, setIndicators] = useState<any>([]);
  let ref = useRef();

  function showOptionRegions() {
    const arrayIndicators = [];
    let elementImageContainer = document.getElementById('image-container');
    const elementImageContainerPosition = elementImageContainer?.getBoundingClientRect();
    const widthImageContainer = elementImageContainerPosition?.width || 0;
    const topImageContainer = elementImageContainerPosition?.top || 0;
    regiones.forEach((reg, index: any) => {
      if (info && info[reg]) {
        arrayIndicators.push(
          <ArrowIndicator
            key={`arrow-${index}`}
            choose={chooseRegion}
            widthImage={widthImageContainer}
            topImage={topImageContainer}
            region={reg}
            number={regionNumber[reg]}
            selectedRegion={region}
          />
        );
      }
    });
    setIndicators(arrayIndicators);
  }

  useEffect(() => {
    showOptionRegions();
  }, [info, region]);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      showOptionRegions();
    });
    observer.observe(ref.current);
    return () => ref.current && observer.unobserve(ref.current);
  }, [info, region]);

  return (
    <section className="region-map-section" ref={ref}>
      <div className="image-header">CHILE CONTINENTAL</div>
      <div className="image-container" id="image-container">
        <ChileMapSvg choose={chooseRegion} regiones={info}/>
        {indicators}
      </div>
    </section>
  );
};

export default GeographicMap;
