// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrap-region-comuna-list {
  display: flex;
  height: 100%;
}

.wrap-region-comuna-list .wrap-region-store-list {
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./components/ComunaStoreList/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,YAAY;AACd","sourcesContent":[".wrap-region-comuna-list {\n  display: flex;\n  height: 100%;\n}\n\n.wrap-region-comuna-list .wrap-region-store-list {\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
