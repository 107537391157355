// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.services-for-host {
  width: 100%;
  height: 30px;
}

.svc-name {
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  color: black;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
}

.svc-crit,
.svc-ok,
.svc-pending,
.svc-warn,
.svc-unknown {
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  color: black;
}
`, "",{"version":3,"sources":["webpack://./components/StoreList/StoreItem/ServicesForHost/index.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;EACjB,YAAY;EACZ,8BAA8B;EAC9B,4BAA4B;EAC5B,6BAA6B;AAC/B;;AAEA;;;;;EAKE,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;EACjB,YAAY;AACd","sourcesContent":[".services-for-host {\n  width: 100%;\n  height: 30px;\n}\n\n.svc-name {\n  width: 150px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background: white;\n  color: black;\n  border-bottom: 1px solid black;\n  border-left: 1px solid black;\n  border-right: 1px solid black;\n}\n\n.svc-crit,\n.svc-ok,\n.svc-pending,\n.svc-warn,\n.svc-unknown {\n  width: 48px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background: white;\n  color: black;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
