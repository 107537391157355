import React, { useEffect, useState } from 'react';
import ServicesForHost from './ServicesForHost/ServicesForHost';
import './index.css';

type Props = {
  name: any;
  arrayHosts: any;
  warning: any;
  critical: any;
  selectStore: any;
  infoStore: any;
};

const StoreItem: React.FC<Props> = ({ name, arrayHosts, warning, critical, selectStore, infoStore }) => {
  const [storeStatus, setStoreStatus] = useState('OK');
  const [showDetails, setShowDetails] = useState(false);
  const [hostsToRender, setHostsToRender] = useState<any>([]);
  function getColorStatus() {
    if (storeStatus === 'OK') {
      return '#10D884';
    } else if (storeStatus === 'WARNING') {
      return '#EFCB0D';
    } else {
      return '#ED4C5C';
    }
  }

  function onShowDetails() {
    setShowDetails(!showDetails);
  }

  function onHandleShowDetails() {
    onShowDetails();
    selectStore(name, infoStore);
  }

  useEffect(() => {
    let auxCount = 0;
    // @ts-ignore
    let toRender = [];
    arrayHosts.forEach((host: any) => {
      const {
        hostName,
        hostStatus,
        numServicesCrit,
        numServicesOk,
        numServicesPending,
        numServicesUnknown,
        numServicesWarn,
      } = host;
      toRender.push(
        <ServicesForHost
          hostName={hostName}
          numServicesCrit={numServicesCrit}
          numServicesOk={numServicesOk}
          numServicesPending={numServicesPending}
          numServicesUnknown={numServicesUnknown}
          numServicesWarn={numServicesWarn}
        />
      );
      if (hostStatus !== 'UP') {
        auxCount++;
      }
    });
    if (auxCount >= critical) {
      setStoreStatus('CRITICAL');
    } else if (auxCount >= warning) {
      setStoreStatus('WARNING');
    } else {
      setStoreStatus('OK');
    }
    // @ts-ignore
    setHostsToRender(toRender);
  }, [arrayHosts, warning, critical]);

  return (
    <>
      <section className="store-item">
        <div className="status" style={{ background: getColorStatus() }} />
        <div className="name">{`Tienda ${name} (${hostsToRender.length})`}</div>
        <div
          className="details"
          onClick={onHandleShowDetails}
          style={{ backgroundColor: showDetails ? 'blue' : '#55565b' }}
        >
          Ver tienda
          <div className="play-button">
            <div className="triangle"></div>
          </div>
        </div>
      </section>
      {showDetails ? (
        <div className="hosts-details">
          <div className="services-for-host">
            <div className="name">{'Hostname'}</div>
            <div className="ok">{'OK'}</div>
            <div className="warn">{'WARN'}</div>
            <div className="crit">{'CRIT'}</div>
            <div className="pending">{'PEND'}</div>
            <div className="unknown">{'UNKN'}</div>
          </div>
          {hostsToRender}
        </div>
      ) : null}
    </>
  );
};

export default StoreItem;
