// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { PanelProps } from '@grafana/data';
import { PanelOptions } from 'types';
import { comunas_maule, regiones } from '../../constants/general.js';
import { info_chile, region_dictionary, region_number } from '../../constants/info_chile.js';
import { REQUEST } from '../../constants/valid_request.js';
import GeographicMap from '../GeographicMap/GeographicMap';
import RegionMap from '../GeographicRegion/GeographicRegion';
import ComunaStoreList from '../ComunaStoreList/ComunaStoreList';
// import ComunaList from '../ComunaList/ComunaList';
// import StoreList from '../StoreList/StoreList';
import ElementsContainer from '../ElementsContainer/ElementsContainer';
import './index.css';

interface Props extends PanelProps<PanelOptions> {}

export const ImagePanel: React.FC<Props> = ({ options, data, width, height }) => {
  const [currentVisualizationBody, setCurrentVisualizationBody] = useState('main');
  const [choosenRegion, setChoosenRegion] = useState<any>(null);
  const [region, setRegion] = useState();
  const [hostsByStore, setHostsByStore] = useState<any>(null);
  const [selectedStoreName, setSelectedStoreName] = useState('');
  const [hostsByRegion, setHostsByRegion] = useState<any>({});
  const [isThereComunas, setIsThereComunas] = useState<boolean>(false);
  const [comunaSelected, setComunaSelected] = useState<any>(null);

  const COUNTRY = options.COUNTRY || 'CL';
  const STORE_WARNING = options.STORE_WARNING || 1;
  const STORE_CRITICAL = options.STORE_CRITICAL || 2;

  function selectComuna(comuna: any) {
    // console.log(comuna);
    setComunaSelected(comuna);
  }

  function processData(dataToWork: any) {
    console.log('datawork', dataToWork);
    const infoData = {};
    dataToWork.shift();
    dataToWork.forEach((hostinfo) => {
      let [
        host_state,
        host,

        host_icons,
        num_services_ok,
        num_services_warn,
        num_services_unknown,
        num_services_crit,
        num_services_pending,
      ] = hostinfo;

      try {
        let digitIndex = host.search(/\d/);
        let firstPart = host.substring(0, digitIndex);
        let secondPart = host.substring(digitIndex);
        let country = firstPart.substring(0, 2);
        let city = firstPart.substring(2, firstPart.length - 2);
        let storeInitial = firstPart.substring(firstPart.length - 2);
        let storeEnd = secondPart.match(/\d+/)[0];
        let deviceType = secondPart.match(/[A-Za-z]+/)[0];
        let deviceNumber = secondPart.match(/\d+$/)[0];

        if (infoData[country]) {
          if (!infoData[country][region_dictionary[city]]) {
            infoData[country][region_dictionary[city]] = [];
          }
        } else {
          infoData[country] = {};

          infoData[country][region_dictionary[city]] = [];
        }

        infoData[country][region_dictionary[city]].push([
          host_state,
          host,
          `${storeInitial}${storeEnd}`,
          deviceType,
          deviceNumber,
          num_services_ok,
          num_services_warn,
          num_services_unknown,
          num_services_crit,
          num_services_pending,
        ]);
      } catch (error) {
        // console.log(error);
        // console.log(host);
      }
    });
    setChoosenRegion(infoData);
  }

  useEffect(() => {
    let dataToWork;
    if (data && data.series.length) {
      console.log(data.series);
      
      const [dtsc1, dtsc2] = data.series;
      // console.log('dtsc1:', dtsc1);
      // console.log('dtsc2:', dtsc2);
      const arrayData = [];
      if (dtsc1.first) {
        dtsc1.first.forEach((dt: any) => {
          const auxData = JSON.parse(dt);
          arrayData.push(auxData);
        });
        dataToWork = arrayData;
      }
      if (!!dtsc1['length']) {
        const [dtscvalues] = dtsc1.fields;
        const [dtscvalues2] = dtsc2.fields;
        console.log('values1:', dtscvalues.values);
        dtscvalues.values.forEach((dt: any) => {
          const auxData = JSON.parse(dt);
          arrayData.push(auxData);
        });
        if (dtscvalues2 && dtscvalues2.values && dtscvalues2.values.length) {
          console.log('values2:',dtscvalues2.values);
          dtscvalues2.values.shift();
          dtscvalues2.values.forEach((dt: any) => {
            const auxData = JSON.parse(dt);
            arrayData.push(auxData);
          });
        }
        dataToWork = arrayData;
      }
    } else {
      dataToWork = REQUEST;
    }
    if (dataToWork) {
      processData(dataToWork);
    }
  }, [data]);

  useEffect(() => {
    const STORE_RELATIONSHIP = options.STORE_RELATIONSHIP || '';
    const arrayByComuna = STORE_RELATIONSHIP.split('\n');
    const jsonComun = {};
    arrayByComuna.forEach((comunaDevice: any) => {
      const [comn, dev] = comunaDevice.split(':');

      if (!jsonComun[comn]) {
        jsonComun[comn] = [];
      }

      jsonComun[comn].push(dev);
    });
    setHostsByRegion(jsonComun);
  }, [options]);

  function fChooseRegion(chooseRegion: any) {
    if (region) {
      const svgLastChoosenRegion = document.getElementById(region);
      svgLastChoosenRegion.style.fill = '#A4ADC9';
      svgLastChoosenRegion.classList.add('exist');
    }
    const svgChoosenRegion = document.getElementById(chooseRegion);
    svgChoosenRegion.style.fill = '#66BCF4';
    svgChoosenRegion.classList.remove('exist');
    svgChoosenRegion.classList.add('choosen');
    setRegion((last) => chooseRegion);
  }

  function onIsThereComunas(isThere) {
    // return !!info_chile[region];
    setIsThereComunas(isThere);
  }

  function selectStore(storeName: any, infoHostsByStore: any) {
    console.log(storeName, infoHostsByStore);

    const dictTypeDevices = {};
    infoHostsByStore.forEach((host: any) => {
      const indexDict = host.device;
      if (!dictTypeDevices[indexDict]) {
        dictTypeDevices[indexDict] = [];
      }
      dictTypeDevices[indexDict].push({
        host: host.hostName,
        status: host.hostStatus,
        number: host.numberDevice,
      });
    });
    setHostsByStore(dictTypeDevices);
    setCurrentVisualizationBody('distribution');
    setSelectedStoreName(storeName);
  }

  function getDistributionBoard() {
    const sections = [];
    for (const key in hostsByStore) {
      if (Object.prototype.hasOwnProperty.call(hostsByStore, key)) {
        const arrayByDeviceType = hostsByStore[key];
        sections.push(<ElementsContainer name={key} arrayDevices={arrayByDeviceType} />);
      }
    }
    return sections;
  }

  function getFlag() {
    if (COUNTRY === 'CL') {
      return <img src="https://nagios.dparadig.com/thruk/themes/Thruk2/images/logos/chile.png" />;
    } else {
      return null;
    }
  }

  function getBodyVisualization() {
    if (currentVisualizationBody === 'main') {
      return (
        <div className="plugin-dashboard-body">
          <section className="map-section">
            <GeographicMap
              chooseRegion={fChooseRegion}
              regiones={regiones}
              info={choosenRegion && choosenRegion[COUNTRY]}
              regionNumber={region_number}
              region={region}
            />
          </section>
          <section className="details-section">
            <section className="comuna-map-section">
              <section className="region-details-title">{`REGION - ${region}`}</section>
              <section className="region-details-body">
                <section className="wrap-region-map" style={{ width: isThereComunas ? '55%' : '75%' }}>
                  <RegionMap region={region} selectComuna={selectComuna} hostsByRegion={hostsByRegion} />
                </section>
                {region && COUNTRY && choosenRegion[COUNTRY][region] && (
                  <ComunaStoreList
                    region={region}
                    comunasByRegion={info_chile[region]}
                    hostsByRegion={hostsByRegion}
                    choosenRegion={choosenRegion[COUNTRY][region]}
                    STORE_WARNING={STORE_WARNING}
                    STORE_CRITICAL={STORE_CRITICAL}
                    selectStore={selectStore}
                    width={isThereComunas ? '45%' : '25%'}
                    onChange={onIsThereComunas}
                    selectComuna={selectComuna}
                    comunaSelected={comunaSelected}
                  />
                )}
              </section>
            </section>
          </section>
        </div>
      );
    } else if (currentVisualizationBody === 'distribution') {
      return (
        <div className="plugin-dashboard-body">
          <div className="wrapper-distribution">
            <div className="header-distribution">
              <div className="wrapper-title">
                <div className="icon" onClick={() => setCurrentVisualizationBody('main')}></div>
                <div className="logo"></div>
                <div className="hint-title">{`${COUNTRY} > ${region} > ${comunaSelected ? comunaSelected : 'All'} > ${selectedStoreName}`}</div>
                <div className="title">Tienda / Centro de distribucion / Datacenter</div>
              </div>
              <div className="store-name">
                <div className="name">{selectedStoreName}</div>
                <div className="flag">{getFlag()}</div>
              </div>
            </div>
            <div className="body-distribution">
              {/* <div className="back-button" onClick={() => setCurrentVisualizationBody('main')}>
                ← Atras
              </div> */}
              <div className="wrap-columns">{getDistributionBoard()}</div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  return (
    <div className="plugin-dashboard" style={{ width: `${width}px`, height: `${height}px`, display: 'flex' }}>
      <div className="plugin-dashboard-header">
        <div className="logo">
          <img src="https://upload.wikimedia.org/wikipedia/commons/d/dc/Cencosud_logo.svg" />
        </div>
        <div className="title">{'DASHBOARD OPERACIONES'}</div>
      </div>
      {getBodyVisualization()}
    </div>
  );
};
