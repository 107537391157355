// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.comuna-list {
  width: calc(100% - 400px);
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  text-transform: uppercase;
  border-right: 1px solid #c1c4c5;
}

.comuna-list .comuna-list-item {
  cursor: pointer;
  display: flex;
  width: 100%;
  height: 45px;
  align-items: center;
  /* border-left: 1px solid gray; */
  border-bottom: 1px solid #c1c4c5;
  /* border-right: 1px solid #c1c4c5; */
}

.comuna-list .comuna-list-item .button-image-region {
  width: 14px;
  height: 14px;
  margin-left: 10px;
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./components/ComunaList/index.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,yBAAyB;EACzB,+BAA+B;AACjC;;AAEA;EACE,eAAe;EACf,aAAa;EACb,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,iCAAiC;EACjC,gCAAgC;EAChC,qCAAqC;AACvC;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".comuna-list {\n  width: calc(100% - 400px);\n  height: 100%;\n  overflow-y: auto;\n  overflow-x: hidden;\n  text-transform: uppercase;\n  border-right: 1px solid #c1c4c5;\n}\n\n.comuna-list .comuna-list-item {\n  cursor: pointer;\n  display: flex;\n  width: 100%;\n  height: 45px;\n  align-items: center;\n  /* border-left: 1px solid gray; */\n  border-bottom: 1px solid #c1c4c5;\n  /* border-right: 1px solid #c1c4c5; */\n}\n\n.comuna-list .comuna-list-item .button-image-region {\n  width: 14px;\n  height: 14px;\n  margin-left: 10px;\n  margin-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
