/* eslint-disable */
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { regiones } from '../../constants/general.js';
import './index.css';

type Props = {
  listNames: any;
  listStatus: any;
  hostsByRegion: any;
  onSelect: any;
  width: any;
};

const ComunaList: React.FC<Props> = ({ listNames, listStatus, hostsByRegion, onSelect, width }) => {
  const [elemComunas, setElemComunas] = useState(null);

  useEffect(() => {
    if (listNames) {
      const comunas = listNames.map((comuna: any, index: number) => {
        const status = listStatus[index];
        let colorButton = '#10D884';
        if (status === 0) {
          colorButton = '#10D884';
        } else if (status === 1) {
          colorButton = '#EFCB0D';
        } else if (status === 2) {
          colorButton = '#ED4C5C';
        } else if (status === 3) {
          colorButton = '#FF8B1F';
        }

        const arraySvgComuna = document.querySelectorAll(`[id=comuna-${comuna}]`);
        arraySvgComuna.forEach((com) => {
          com.style.fill = colorButton;
          com.style.stroke = 'white';
          com.addEventListener('mouseover', () => {
            com.style.strokeWidth = '4px';
            com.style.stroke = 'white';
            com.style.cursor = 'pointer';
          });
          com.addEventListener('mouseout', () => {
            com.style.fill = colorButton;
            com.style.stroke = 'white';
            com.style.strokeWidth = '1px';
          });
        });

        const item = (
          <div
            className="comuna-list-item"
            onClick={() => {
              onSelect(comuna);
            }}
          >
            <div className="button-image-region" style={{ background: colorButton }}></div>
            <div>{`${comuna}`}</div>
          </div>
        );
        return item;
      });

      setElemComunas(comunas);
    }
  }, [listNames, listStatus, hostsByRegion]);

  return (
    <section className="comuna-list" style={{ width: width }}>
      {elemComunas ? elemComunas : null}
    </section>
  );
};

export default ComunaList;
