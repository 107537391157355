const REQUEST = [
  [
    'host_state',
    'host',
    'host_icons',
    'num_services_ok',
    'num_services_warn',
    'num_services_unknown',
    'num_services_crit',
    'num_services_pending',
  ],
  ['UP', 'CLANTEA534AP020', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTEA534AP021', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTEA534AP022', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTEA534AP023', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTEA534AP024', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTEA534AP025', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTEA534AP026', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTEA534AP027', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTEA534AP028', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTEA534AP029', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTEA534AP030', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTEA534AP031', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTEA534AP032', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTEA534AP033', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTEA534AP034', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTEA534AP035', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTEA534AP036', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTEA534AP037', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTEA534AP038', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTEA534AP039', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTEA534RT001', '', '16', '1', '1', '0', '0'],
  ['UP', 'CLANTEA534SW011', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLANTEA534SW109', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLANTEA534SW114', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLANTEA534SW130', '', '24', '0', '0', '0', '0'],
  ['UP', 'CLANTEA534SW131', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLANTEA534SW132', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLANTEA534SW133', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLANTEA534SW134', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLANTEA534SW139', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLANTEA534SW140', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLANTEA534SW141', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLANTEA534SW142', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588AP099', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588AP104', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588AP105', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588AP106', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588AP107', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588AP108', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588AP109', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588AP200', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588AP201', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588AP202', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588AP203', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588AP204', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588AP205', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588AP206', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588AP207', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588AP208', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588AP209', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588AP210', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588AP211', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588AP212', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588AP213', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588AP214', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588AP215', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588AP216', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588AP217', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588AP218', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588AP219', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588AP220', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588AP221', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588AP222', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588AP223', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588AP226', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588AP227', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588AP228', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588AP229', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588FW103', '', '18', '0', '0', '10', '0'],
  ['DOWN', 'CLANTPA588FW230', '', '1', '0', '0', '2', '0'],
  ['UP', 'CLANTPA588RT003', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588RT005', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588SW004', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLANTPA588SW018', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLANTPA588SW050', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLANTPA588SW054', '', '12', '1', '0', '0', '0'],
  ['UP', 'CLANTPA588SW055', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLANTPA588SW056', '', '2', '1', '0', '0', '0'],
  ['UP', 'CLANTPA588SW057', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLANTPA588SW058', '', '12', '1', '0', '0', '0'],
  ['UP', 'CLANTPA588SW059', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLANTPA588SW060', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLANTPA588SW061', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLANTPA588SW062', '', '12', '1', '0', '0', '0'],
  ['UP', 'CLANTPA588SW063', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLANTPA588SW064', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLANTPA588SW065', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLANTPA588SW224', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588SW225', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLANTPA588SW251', '', '19', '1', '0', '0', '0'],
  ['UP', 'CLANTPA588SW252', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLARAN517AP200', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAN517AP201', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAN517AP202', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAN517AP203', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAN517RT003', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLARAN517SW130', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLARAN517SW131', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARAN517SW132', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARAN517SW133', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARAN517SW134', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLARAN517SW135', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLARAN517SW136', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARAN517VG130', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLARAN547AP002', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAN547AP003', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAN547AP004', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAN547FW043', '', '19', '1', '1', '0', '0'],
  ['UP', 'CLARAN547RT001', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLARAN547SW025', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARAN547SW116', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLARAN547SW118', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLARAN547SW119', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARAN554AP004', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAN554AP005', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAN554AP006', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAN554FW148', '', '11', '1', '1', '1', '0'],
  ['UP', 'CLARAN554RT002', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLARAN554RT003', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLARAN554SW025', '', '12', '1', '1', '0', '0'],
  ['UP', 'CLARAN554SW116', '', '13', '1', '0', '0', '0'],
  ['UP', 'CLARAN554SW119', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLARAN554SW120', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLARAN597AP002', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAN597AP003', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAN597AP004', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAN597FW050', '', '19', '1', '1', '0', '0'],
  ['UP', 'CLARAN597RT001', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLARAN597SW117', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARAN597SW119', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARAN597SW120', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARAN597SW122', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLARAN597SW123', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLARAN625AP002', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAN625AP003', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAN625AP004', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAN625FW051', '', '15', '1', '1', '0', '0'],
  ['UP', 'CLARAN625RT001', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLARAN625SW116', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLARAN625SW117', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLARAN625SW120', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARAN625SW122', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLARAN625SW123', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARAN678SW121', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLARAN732AP002', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAN732AP003', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAN732AP004', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAN732AP005', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAN732FW233', '', '19', '1', '1', '0', '0'],
  ['UP', 'CLARAN732RT001', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLARAN732SW116', '', '21', '0', '0', '0', '0'],
  ['UP', 'CLARAN732SW117', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARAN732SW119', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLARAN732SW120', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLARAN732SW122', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLARAN743AP002', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAN743AP003', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAN743AP004', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAN743FW101', '', '13', '1', '1', '0', '0'],
  ['UP', 'CLARAN743RT002', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLARAN743RT003', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLARAN743SW025', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLARAN743SW116', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLARAN743SW117', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARAN743SW118', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLARAN743SW119', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLARAN743SW121', '', '7', '0', '0', '4', '0'],
  ['UP', 'CLARAN743SW122', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLARAN826AP201', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAN826AP202', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAN826AP203', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAN826AP204', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAN826FW085', '', '19', '1', '1', '0', '0'],
  ['UP', 'CLARAN826RT001', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLARAN826SW130', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLARAN826SW131', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLARAN826SW132', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLARAN826SW133', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLARAN826SW134', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARAN826SW135', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARAN826SW136', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARAN826VG131', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLARAN978AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAN978AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAN978AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAN978AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAN978FW012', '', '10', '1', '1', '1', '0'],
  ['UP', 'CLARAN978RT125', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLARAN978RT126', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLARAN978SW066', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLARAN978SW067', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLARAN978SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARAN978SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARAN978SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARAN985AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAN985AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAN985AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAN985AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAN985FW015', '', '11', '1', '1', '1', '0'],
  ['UP', 'CLARAN985RT125', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLARAN985RT126', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLARAN985SW066', '', '17', '1', '0', '0', '0'],
  ['UP', 'CLARAN985SW067', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARAN985SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARAN985SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARAN985SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARAN985SW071', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506AP002', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506AP003', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506AP004', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506AP005', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506AP006', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506AP007', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506AP008', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506AP009', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506AP010', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506AP011', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506AP012', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506AP013', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506AP014', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506AP015', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506AP016', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506AP017', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506AP018', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506AP019', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506AP020', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506AP021', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506AP022', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506AP023', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506AP024', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506AP025', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506AP026', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506AP027', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506AP028', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506AP029', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506AP030', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506AP031', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506AP032', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506AP033', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506AP034', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506AP035', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506AP071', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506FW094', '', '21', '2', '0', '5', '0'],
  ['UP', 'CLARAPA506FW196', '', '56', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506RT001', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506SW004', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506SW017', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506SW018', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506SW019', '', '17', '0', '0', '1', '0'],
  ['UP', 'CLARAPA506SW020', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506SW053', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506SW054', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLARAPA506SW055', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506SW056', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLARAPA506SW057', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506SW058', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506SW059', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506SW060', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLARAPA506SW061', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLARAPA506SW062', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLARAPA506SW063', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLARAPA506SW064', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLARAPA506SW065', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506SW133', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506SW134', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506SW135', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506SW136', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506SW137', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506SW138', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLARAPA506SW139', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566AP091', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566AP095', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566AP131', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566AP132', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566AP133', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566AP134', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566AP141', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566AP142', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566AP143', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566AP144', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566AP145', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566AP146', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566AP147', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566AP148', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566AP149', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566AP150', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566AP151', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566AP152', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566AP153', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566AP154', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566AP155', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566AP156', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566AP157', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566AP159', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566AP160', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566AP161', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566AP162', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566AP163', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566AP164', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566AP165', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566AP166', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566AP167', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566AP168', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566AP169', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566AP190', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566AP191', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566FW095', '', '31', '2', '0', '5', '0'],
  ['UP', 'CLARAPA566RT001', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566SW004', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566SW017', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566SW019', '', '20', '0', '0', '1', '0'],
  ['UP', 'CLARAPA566SW052', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566SW053', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566SW054', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLARAPA566SW055', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566SW056', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLARAPA566SW057', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566SW058', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLARAPA566SW060', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLARAPA566SW061', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLARAPA566SW062', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLARAPA566SW063', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLARAPA566SW064', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566SW065', '', '13', '0', '0', '1', '0'],
  ['UP', 'CLARAPA566SW066', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566SW067', '', '16', '1', '0', '0', '0'],
  ['UP', 'CLARAPA566SW170', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566SW171', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566SW172', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566SW251', '', '21', '0', '0', '0', '0'],
  ['UP', 'CLARAPA566SW252', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLARIN729AP003', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIN729AP004', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIN729AP005', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIN729AP006', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIN729AP007', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIN729FW065', '', '19', '1', '1', '0', '0'],
  ['UP', 'CLARIN729RT001', '', '20', '0', '0', '0', '0'],
  ['UP', 'CLARIN729SW025', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARIN729SW116', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLARIN729SW117', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARIN729SW119', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARIN729SW122', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARIN804AP037', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIN804AP038', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIN804AP039', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIN804AP040', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIN804AP041', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIN804FW078', '', '18', '1', '0', '8', '0'],
  ['UP', 'CLARIN804RT001', '', '16', '1', '1', '0', '0'],
  ['UP', 'CLARIN804SW026', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARIN804SW027', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLARIN804SW028', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLARIN804SW030', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLARIN804SW032', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARIN804SW033', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLARIN804SW100', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLARIN812AP201', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIN812AP202', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIN812AP203', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIN812AP204', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIN812FW080', '', '19', '1', '1', '0', '0'],
  ['UP', 'CLARIN812RT129', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLARIN812SW130', '', '2', '1', '0', '0', '0'],
  ['UP', 'CLARIN812SW131', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLARIN812SW132', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARIN812SW133', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLARIN812SW134', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARIN812SW135', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLARIN812SW136', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804AP070', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804AP071', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804AP072', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804AP073', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804AP074', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804AP075', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804AP076', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804AP077', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804AP078', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804AP079', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804AP080', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804AP081', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804AP082', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804AP083', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804AP084', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804AP085', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804AP086', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804AP087', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804AP088', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804AP089', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804AP090', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804AP091', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804AP092', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804AP093', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804AP094', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804AP095', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804AP096', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804AP097', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804AP098', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804AP099', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804RT001', '', '16', '1', '1', '0', '0'],
  ['UP', 'CLARIPA804SW002', '', '14', '0', '0', '1', '0'],
  ['UP', 'CLARIPA804SW003', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804SW004', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804SW004-2', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804SW005', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804SW006', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804SW007', '', '14', '0', '0', '1', '0'],
  ['UP', 'CLARIPA804SW008', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804SW009', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804SW010', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804SW013', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804SW014', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804SW015', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804SW016', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLARIPA804SW017', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLATAN841AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLATAN841AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLATAN841AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLATAN841AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLATAN841FW133', '', '16', '1', '0', '8', '0'],
  ['UP', 'CLATAN841RT065', '', '16', '1', '0', '0', '0'],
  ['UP', 'CLATAN841SW066', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLATAN841SW067', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLATAN841SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLATAN841SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLATAN841SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLATAN841SW071', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLATAN841VG130', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLATAN901AP080', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLATAN901AP081', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLATAN901AP082', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLATAN901AP083', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLATAN901AP084', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLATAN901AP085', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLATAN901AP086', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLATAN901AP087', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLATAN901AP088', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLATAN901AP089', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLATAN901AP090', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLATAN901AP091', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLATAN901AP092', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLATAN901AP093', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLATAN901AP094', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLATAN901AP095', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLATAN901AP096', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLATAN901AP097', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLATAN901AP098', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLATAN901FW228', '', '16', '1', '0', '8', '0'],
  ['UP', 'CLATAN901RT065', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLATAN901SW066', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLATAN901SW067', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLATAN901SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLATAN901SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLATAN901SW070', '', '11', '0', '0', '1', '0'],
  ['UP', 'CLATAN901SW071', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLATAN901SW072', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLATAN901SW075', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLATAN901VG066', '', '2', '1', '0', '0', '0'],
  ['UP', 'CLATAN902AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLATAN902AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLATAN902AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLATAN902AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLATAN902FW091', '', '16', '1', '1', '3', '0'],
  ['UP', 'CLATAN902RT065', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLATAN902SW066', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLATAN902SW067', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLATAN902SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLATAN902SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLATAN902SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBIOEA983AP130', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOEA983AP131', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOEA983AP132', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOEA983AP133', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOEA983AP134', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOEA983AP135', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOEA983AP136', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOEA983AP137', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOEA983AP138', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOEA983AP139', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOEA983AP140', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOEA983AP141', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOEA983AP142', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOEA983AP143', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOEA983AP144', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOEA983AP145', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOEA983SW010', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBIOEA983SW011', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBIOEA983SW012', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBIOEA983SW013', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBIOEA983SW014', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBIOEA983SW015', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBIOEA983SW016', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBIOEA983SW017', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBIOEA983SW018', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBIOEA983SW019', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBIOEA983SW020', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLBIOEA983SW251', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLBIOEA983SW252', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLBION569AP004', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION569AP005', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION569FW047', '', '15', '1', '1', '0', '0'],
  ['UP', 'CLBION569RT001', '', '16', '1', '1', '0', '0'],
  ['UP', 'CLBION569SW025', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLBION569SW117', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLBION569SW118', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLBION569SW119', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION569SW122', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLBION596AP080', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION596AP081', '', '1', '0', '0', '0', '0'],
  ['DOWN', 'CLBION596AP082', '', '0', '0', '0', '1', '0'],
  ['UP', 'CLBION596FW149', '', '10', '1', '1', '1', '0'],
  ['UP', 'CLBION596RT125', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLBION596RT126', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLBION596SW066', '', '13', '1', '1', '0', '0'],
  ['UP', 'CLBION596SW067', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION596SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION596SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION596SW070', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLBION596SW072', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION678AP002', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION678AP003', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION678FW124', '', '15', '3', '1', '1', '0'],
  ['UP', 'CLBION678RT002', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLBION678RT003', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLBION678SW025', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLBION678SW116', '', '25', '0', '0', '0', '0'],
  ['UP', 'CLBION678SW117', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLBION678SW119', '', '18', '1', '0', '0', '0'],
  ['UP', 'CLBION678SW120', '', '7', '0', '0', '1', '0'],
  ['UP', 'CLBION678VG126', '', '2', '1', '0', '0', '0'],
  ['UP', 'CLBION679AP201', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION679AP202', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION679AP203', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION679FW142', '', '17', '0', '0', '8', '0'],
  ['UP', 'CLBION679RT129', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLBION679SW130', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLBION679SW131', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION679SW132', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION679SW133', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION679SW134', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION679SW135', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION680AP200', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION680AP201', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION680AP203', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION680FW229', '', '19', '1', '1', '0', '0'],
  ['UP', 'CLBION680RT129', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLBION680RT131', '', '2', '1', '0', '0', '0'],
  ['UP', 'CLBION680SW130', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLBION680SW131', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLBION680SW132', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION680SW133', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION680SW134', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION680SW135', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION680SW136', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION680VG130', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLBION688AP002', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION688AP003', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION688AP004', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION688AP005', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION688FW062', '', '18', '1', '1', '1', '0'],
  ['UP', 'CLBION688RT001', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLBION688SW025', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLBION688SW116', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION688SW117', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLBION688SW119', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLBION737AP004', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION737FW068', '', '15', '1', '1', '0', '0'],
  ['UP', 'CLBION737RT001', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLBION737SW025', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLBION737SW116', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLBION737SW117', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLBION737SW119', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLBION737SW120', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLBION737SW122', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLBION742AP012', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION742AP013', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION742AP014', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION742AP015', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION742FW163', '', '15', '0', '0', '3', '0'],
  ['UP', 'CLBION742RT002', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLBION742RT003', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLBION742SW116', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLBION742SW117', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION742SW118', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLBION742SW120', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION742SW123', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLBION742SW124', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLBION742SW125', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION761AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION761AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION761AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION761FW072', '', '18', '1', '1', '1', '0'],
  ['UP', 'CLBION761RT001', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLBION761SW116', '', '13', '1', '0', '0', '0'],
  ['UP', 'CLBION761SW117', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLBION761SW119', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLBION761SW120', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLBION761SW122', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION761SW123', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLBION784AP200', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION784AP201', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION784AP202', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION784AP203', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION784AP204', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION784AP205', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION784AP206', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION784AP207', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION784AP208', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION784AP209', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION784AP210', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION784AP211', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION784AP212', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION784AP213', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION784RT253', '', '14', '1', '1', '0', '0'],
  ['UP', 'CLBION784RT254', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLBION784SW132', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLBION784SW133', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLBION784SW134', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLBION794AP151', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP152', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP153', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP154', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP155', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP156', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP157', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP158', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP159', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP160', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP161', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP162', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP163', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP164', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP165', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP166', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP167', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP168', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP169', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP170', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP171', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP172', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP173', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP174', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP175', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP176', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP177', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP178', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP179', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP180', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP181', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP182', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP183', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP184', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP185', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP186', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP187', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP188', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP189', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP190', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP191', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP192', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794AP193', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLBION794RT253', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLBION794RT254', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLBION794SW132', '', '24', '0', '0', '0', '0'],
  ['UP', 'CLBION794SW133', '', '22', '0', '0', '0', '0'],
  ['UP', 'CLBION794SW134', '', '23', '0', '0', '0', '0'],
  ['UP', 'CLBION794SW135', '', '25', '0', '0', '0', '0'],
  ['UP', 'CLBION794SW136', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLBION794SW137', '', '13', '1', '0', '0', '0'],
  ['UP', 'CLBION794SW138', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLBION794SW139', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLBION794SW140', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLBION794SW141', '', '21', '0', '0', '0', '0'],
  ['UP', 'CLBION794SW142', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLBION794SW143', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLBION835AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION835AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION835AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION835AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION835FW191', '', '15', '0', '0', '3', '0'],
  ['UP', 'CLBION835RT125', '', '17', '1', '1', '0', '0'],
  ['UP', 'CLBION835RT126', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLBION835SW066', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLBION835SW067', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLBION835SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION835SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION835SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION835SW071', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLBION835SW072', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION835VG130', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLBION907AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION907AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION907AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION907AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION907AP104', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION907AP105', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION907FW024', '', '15', '1', '1', '0', '0'],
  ['UP', 'CLBION907RT065', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLBION907SW066', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLBION907SW067', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLBION907SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION907SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION907SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION927AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION927AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION927AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION927AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION927FW008', '', '10', '1', '1', '1', '0'],
  ['UP', 'CLBION927RT125', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLBION927RT126', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLBION927SW066', '', '16', '1', '0', '0', '0'],
  ['UP', 'CLBION927SW067', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLBION927SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION927SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION927SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION927SW071', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION977AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION977AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION977AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION977AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION977FW011', '', '10', '1', '1', '1', '0'],
  ['UP', 'CLBION977RT125', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLBION977RT126', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLBION977SW066', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLBION977SW067', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION977SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION977SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION977SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION977SW071', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION983AP070', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION983AP071', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION983AP072', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION983AP073', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION983AP074', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBION983FW086', '', '19', '3', '0', '5', '0'],
  ['UP', 'CLBION983RT001', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLBION983RT002', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLBION983SW002', '', '26', '0', '0', '0', '0'],
  ['UP', 'CLBION983SW050', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLBION983SW051', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION983SW052', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION983SW053', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION983SW054', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION983SW055', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION983SW091', '', '12', '0', '0', '2', '0'],
  ['UP', 'CLBION983SW094', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBION983SW095', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBIONA778AP150', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIONA778AP151', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIONA778AP152', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIONA778AP153', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIONA778AP154', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIONA778AP155', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIONA778AP156', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIONA778AP157', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIONA778AP158', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIONA778AP159', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIONA778FW112', '', '12', '1', '1', '0', '0'],
  ['UP', 'CLBIONA778RT002', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLBIONA778RT003', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLBIONA778SW116', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLBIONA778SW117', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBIONA778SW119', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLBIONA778SW120', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLBIONA778SW122', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLBIONA778SW145', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLBIONA783AP140', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIONA783AP141', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIONA783AP142', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIONA783AP143', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIONA783AP144', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIONA783AP145', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIONA783AP146', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIONA783AP147', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIONA783AP148', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIONA783AP149', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIONA783FW073', '', '19', '1', '1', '0', '0'],
  ['UP', 'CLBIONA783RT001', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLBIONA783RT130', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLBIONA783SW130', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLBIONA783SW131', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBIONA783SW132', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLBIONA783SW133', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBIONA783SW134', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLBIONA783SW135', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBIONA783SW136', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBIONA783VG131', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLBIONA801AP140', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIONA801AP141', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIONA801AP142', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIONA801AP143', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIONA801AP144', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIONA801AP145', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIONA801AP146', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIONA801AP147', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIONA801AP148', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIONA801AP149', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIONA801AP150', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIONA801AP151', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIONA801FW077', '', '15', '1', '1', '0', '0'],
  ['UP', 'CLBIONA801RT001', '', '16', '1', '1', '0', '0'],
  ['UP', 'CLBIONA801RT130', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLBIONA801SW130', '', '16', '0', '0', '1', '0'],
  ['UP', 'CLBIONA801SW131', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBIONA801SW133', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLBIONA801SW134', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLBIONA801SW135', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLBIONA801SW136', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLBIONA801VG131', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP110', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP111', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP112', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP113', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP114', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP115', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP116', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP117', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP118', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP119', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP120', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP121', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP122', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP123', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP124', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP125', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP126', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP127', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP128', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP129', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP130', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP131', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP132', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP133', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP134', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP135', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP136', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP137', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP138', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP140', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP141', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP142', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP143', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP144', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP145', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP146', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP147', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP148', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP149', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP150', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP151', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP152', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP153', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP154', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP155', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP156', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP157', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP158', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP159', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP160', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP161', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP162', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP163', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP164', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP165', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP166', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569AP167', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569FW096', '', '28', '2', '0', '5', '0'],
  ['UP', 'CLBIOPA569RT001', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569SW018', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569SW019', '', '29', '0', '0', '1', '0'],
  ['UP', 'CLBIOPA569SW052', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569SW053', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569SW054', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569SW055', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569SW056', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569SW057', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569SW058', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569SW059', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569SW060', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569SW061', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569SW062', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569SW064', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569SW065', '', '11', '0', '0', '1', '0'],
  ['UP', 'CLBIOPA569SW066', '', '10', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569SW067', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569SW071', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569SW072', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569SW182', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569SW183', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA569SW184', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578AP131', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578AP132', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578AP133', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578AP134', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578AP135', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578AP136', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578AP137', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578AP138', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578AP139', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578AP140', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578AP141', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578AP142', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578AP143', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578AP144', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578AP145', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578AP146', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578AP147', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578AP148', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578AP149', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578AP150', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578AP151', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578AP152', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578AP153', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578AP154', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578AP155', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578AP156', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578AP157', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578AP158', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578AP159', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578AP160', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578AP161', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578AP162', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578AP163', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578AP164', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578FW022', '', '29', '1', '0', '8', '0'],
  ['UP', 'CLBIOPA578RT001', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578SW004', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLBIOPA578SW017', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578SW018', '', '15', '1', '0', '0', '0'],
  ['UP', 'CLBIOPA578SW056', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578SW057', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578SW058', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLBIOPA578SW059', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLBIOPA578SW060', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLBIOPA578SW061', '', '10', '0', '0', '1', '0'],
  ['UP', 'CLBIOPA578SW062', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLBIOPA578SW063', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLBIOPA578SW064', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLBIOPA578SW070', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578SW165', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578SW166', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578SW167', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578SW251', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA578SW252', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA581AP110', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA581AP111', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA581AP112', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA581AP113', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA581AP114', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA581AP115', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA581AP116', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA581AP117', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA581AP118', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA581AP119', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA581AP120', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA581AP121', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA581AP122', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA581AP123', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA581AP124', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA581AP125', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA581AP126', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA581AP127', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA581AP128', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA581AP129', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA581AP130', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA581AP131', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA581FW017', '', '13', '0', '0', '12', '0'],
  ['UP', 'CLBIOPA581RT002', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA581RT003', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA581SW004', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA581SW017', '', '17', '0', '0', '1', '0'],
  ['UP', 'CLBIOPA581SW018', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA581SW050', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLBIOPA581SW052', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA581SW053', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA581SW054', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA581SW055', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA581SW056', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA581SW058', '', '8', '0', '0', '1', '0'],
  ['UP', 'CLBIOPA581SW059', '', '8', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA581SW060', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA581SW061', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBIOPA581SW062', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLBIOX680FW140', '', '17', '0', '0', '3', '0'],
  ['UP', 'CLBIOX680RT002', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLBIOX680RT003', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLBIOX983AP150', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOX983AP151', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOX983AP152', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOX983AP153', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOX983AP154', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOX983AP155', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOX983AP156', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOX983AP157', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOX983AP158', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOX983AP159', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOX983AP160', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOX983AP161', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOX983AP162', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOX983AP163', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOX983AP164', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOX983AP165', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOX983AP166', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOX983AP167', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOX983AP168', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOX983AP169', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOX983AP170', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOX983AP171', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLBIOX983SW090', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLBIOX983SW091', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBIOX983SW092', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBIOX983SW093', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBIOX983SW094', '', '10', '0', '0', '0', '0'],
  ['UP', 'CLBIOX983SW095', '', '10', '0', '0', '0', '0'],
  ['UP', 'CLBIOX983SW096', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLBIOX983SW097', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCHICDNOVFW025', '', '27', '1', '0', '0', '0'],
  ['UP', 'CLCHICDNOVRT001', '', '113', '0', '0', '0', '0'],
  ['UP', 'CLCHICDNOVRT018', '', '15', '1', '0', '0', '0'],
  ['UP', 'CLCHICDNOVRT019', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLCHICDNOVSW010', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCHICDNOVSW012', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCHICDNOVSW013', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCHICDNOVSW014', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCHICDNOVSW015', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLCHICDNOVSW016', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCHICDNOVSW017', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCHICDNOVSW019', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCHICDNOVSW020', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLCHICDNOVSW021', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCHICDNOVSW022', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCHICDNOVSW023', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLCHICDNOVSW024', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCHICDNOVSW025', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLCHICDNOVSW026', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCHICDNOVSW027', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCHICDNOVSW028', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCHICDNOVSW029', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLCHICDNOVSW032', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLCHICDNOVSW033', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCHICDNOVSW050', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLCHICDNOVSW061', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLCHICDNOVSW062', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLCHIU018RT113', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLCHIU018SW114', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLCONU009RT049', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLCONU009SW050', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLCOQN551AP006', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQN551FW045', '', '15', '1', '1', '0', '0'],
  ['UP', 'CLCOQN551RT001', '', '16', '1', '1', '0', '0'],
  ['UP', 'CLCOQN551SW025', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLCOQN551SW116', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLCOQN551SW117', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLCOQN551SW118', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLCOQN551SW120', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLCOQN648AP201', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQN648AP202', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQN648AP203', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQN648AP204', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQN648FW090', '', '12', '1', '1', '0', '0'],
  ['UP', 'CLCOQN648RT130', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLCOQN648RT253', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLCOQN648RT254', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLCOQN648SW130', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLCOQN648SW132', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCOQN648SW133', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCOQN648VG131', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLCOQN658AP150', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQN658AP151', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQN658AP152', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQN658AP153', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQN658AP154', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQN658AP155', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQN658AP156', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQN658AP157', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQN658AP158', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQN658AP159', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQN658AP160', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQN658FW142', '', '14', '1', '1', '0', '0'],
  ['UP', 'CLCOQN658RT253', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLCOQN658RT254', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLCOQN658SW131', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLCOQN658SW132', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLCOQN658SW133', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCOQN658SW134', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLCOQN658SW135', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLCOQN658SW136', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLCOQN658SW137', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLCOQN658SW138', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCOQN658VG253', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLCOQN658VG254', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLCOQN790AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQN790AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQN790AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQN790FW074', '', '15', '1', '1', '0', '0'],
  ['UP', 'CLCOQN790RT001', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLCOQN790SW116', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLCOQN790SW119', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLCOQN790SW120', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLCOQN790SW122', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLCOQN790SW126', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLCOQN827AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQN827AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQN827AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQN827AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQN827FW243', '', '15', '1', '1', '0', '0'],
  ['UP', 'CLCOQN827RT129', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLCOQN827RT190', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLCOQN827SW066', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLCOQN827SW067', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCOQN827SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCOQN827SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCOQN827SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCOQN827SW071', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCOQN827VG130', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLCOQN827VG190', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLCOQN836AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQN836AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQN836AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQN836FW248', '', '19', '1', '1', '0', '0'],
  ['UP', 'CLCOQN836RT193', '', '16', '1', '1', '0', '0'],
  ['UP', 'CLCOQN836SW066', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLCOQN836SW067', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLCOQN836SW068', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLCOQN836SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCOQN836SW072', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLCOQN836SW073', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLCOQN836SW100', '', '8', '0', '0', '0', '0'],
  ['UP', 'CLCOQN836VG130', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLCOQN845AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQN845AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQN845AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQN845AP104', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQN845FW090', '', '19', '1', '1', '0', '0'],
  ['UP', 'CLCOQN845RT065', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLCOQN845SW66', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLCOQN845SW67', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCOQN845SW68', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCOQN845SW69', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCOQN845SW70', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCOQN845SW71', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCOQN845SW72', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCOQN908AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQN908AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQN908AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQN908FW092', '', '19', '1', '1', '0', '0'],
  ['UP', 'CLCOQN908RT193', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLCOQN908SW066', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCOQN908SW067', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLCOQN908SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCOQN908SW069', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLCOQN908SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCOQN908SW071', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCOQN908VG130', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLCOQN979AP030', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQN979AP031', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQN979AP032', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQN979AP033', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQN979AP034', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQN979AP035', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQN979FW019', '', '22', '1', '0', '8', '0'],
  ['UP', 'CLCOQN979SW011', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLCOQN979SW012', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCOQN979SW013', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCOQN979SW014', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCOQN979SW015', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCOQN979SW016', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCOQNA730AP162', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQNA730AP163', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQNA730AP164', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQNA730AP165', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQNA730AP166', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQNA730AP167', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQNA730AP168', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQNA730FW111', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLCOQNA730RT209', '', '15', '1', '1', '0', '0'],
  ['UP', 'CLCOQNA730SW194', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLCOQNA730SW195', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLCOQNA730SW196', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLCOQNA730SW197', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571AP090', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571AP130', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571AP131', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571AP132', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571AP140', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571AP141', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571AP142', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571AP143', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571AP144', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571AP145', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571AP146', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571AP147', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571AP148', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571AP149', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571AP150', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571AP151', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571AP152', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571AP153', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571AP154', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571AP155', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571AP156', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571AP157', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571AP161', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571AP162', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571AP163', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571AP164', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571AP165', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571AP166', '', '1', '0', '0', '0', '0'],
  ['DOWN', 'CLCOQPA571AP167', '', '0', '0', '0', '1', '0'],
  ['UP', 'CLCOQPA571AP168', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571AP169', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571AP170', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571AP171', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571AP172', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571AP173', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571AP174', '', '1', '0', '0', '0', '0'],
  ['DOWN', 'CLCOQPA571AP175', '', '0', '0', '0', '1', '0'],
  ['UP', 'CLCOQPA571FW120', '', '33', '1', '0', '5', '0'],
  ['UP', 'CLCOQPA571RT001', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571RT010', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571SW020', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571SW052', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLCOQPA571SW053', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLCOQPA571SW054', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571SW055', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLCOQPA571SW056', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571SW057', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571SW059', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571SW061', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLCOQPA571SW062', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLCOQPA571SW063', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLCOQPA571SW064', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLCOQPA571SW065', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571SW080', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571SW082', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571SW083', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571SW085', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571SW086', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571SW087', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571SW100', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571SW101', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571SW158', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571SW159', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571SW251', '', '31', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA571SW252', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA960AP050', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA960AP051', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA960AP052', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA960AP053', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA960AP054', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA960AP055', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA960AP056', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA960AP057', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA960AP058', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA960AP059', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA960AP060', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA960AP061', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA960AP062', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA960AP063', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA960AP064', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA960AP065', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA960AP066', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA960AP067', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA960AP068', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA960AP069', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA960AP070', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA960FW234', '', '18', '1', '0', '8', '0'],
  ['UP', 'CLCOQPA960RT001', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA960SW10', '', '35', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA960SW11', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA960SW12', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA960SW13', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA960SW14', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA960SW15', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA960SW16', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA960SW17', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA960SW18', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA960SW19', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA960SW20', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA960SW21', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA960SW22', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA960SW23', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA960SW24', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLCOQPA960SW25', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLCURU022RT177', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLCURU022SW178', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLINDPA989RT129', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLINDPA989RT130', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLAGN585AP066', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGN585AP067', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGN585AP068', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGN585SW030', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLLAGN585SW031', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLLAGN585SW032', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLLAGN585SW033', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLLAGN585SW034', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLLAGN585SW035', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLLAGN733AP106', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGN733AP107', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGN733AP108', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGN733AP109', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGN733AP110', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGN733AP111', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGN733FW150', '', '16', '1', '0', '8', '0'],
  ['UP', 'CLLAGN733RT001', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLLAGN733SW116', '', '20', '0', '0', '0', '0'],
  ['UP', 'CLLAGN733SW117', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLLAGN733SW119', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLLAGN733SW120', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLAGN733SW121', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLLAGN733SW122', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLLAGN734AP002', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGN734AP004', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGN734FW067', '', '15', '1', '1', '0', '0'],
  ['UP', 'CLLAGN734RT001', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLLAGN734SW025', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLLAGN734SW116', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLLAGN734SW117', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLLAGN734SW119', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLAGN734SW122', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLLAGN824AP201', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGN824AP202', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGN824AP203', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGN824AP204', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGN824FW084', '', '19', '1', '1', '0', '0'],
  ['UP', 'CLLAGN824RT001', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLLAGN824SW130', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLLAGN824SW131', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLAGN824SW132', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLLAGN824SW133', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLLAGN824SW134', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLLAGN824SW135', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLLAGN824SW136', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLAGN824VG131', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLLAGN904AP100', '', '1', '0', '0', '0', '0'],
  ['DOWN', 'CLLAGN904AP102', '', '0', '0', '0', '1', '0'],
  ['UP', 'CLLAGN904AP103', '', '1', '0', '0', '0', '0'],
  ['DOWN', 'CLLAGN904AP104', '', '0', '0', '0', '1', '0'],
  ['UP', 'CLLAGN904FW002', '', '10', '1', '1', '1', '0'],
  ['UP', 'CLLAGN904RT125', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLLAGN904RT126', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLLAGN904RT133', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLLAGN904SW066', '', '21', '0', '0', '0', '0'],
  ['UP', 'CLLAGN904SW067', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLLAGN904SW068', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLLAGN904SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLAGN904SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLAGN904SW071', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLAGN904SW072', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLAGN904SW073', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLAGN904VG130', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLLAGN906AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGN906AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGN906AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGN906AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGN906FW190', '', '16', '1', '0', '8', '0'],
  ['UP', 'CLLAGN906RT065', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLLAGN906SW066', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLLAGN906SW067', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLLAGN906SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLAGN906SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLAGN906SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLAGN906SW071', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLAGN906SW072', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLAGN906VG130', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA529AP171', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA529AP172', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA529AP173', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA529AP174', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA529AP175', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA529AP176', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA529AP177', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA529AP178', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA529AP179', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA529AP180', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA529AP181', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA529AP182', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA529AP183', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA529AP184', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA529AP185', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA529SW162', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA529SW163', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA529SW164', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA529SW165', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA529SW166', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA529SW167', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA587AP050', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA587AP051', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA587AP052', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA587AP053', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA587AP054', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA587AP055', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA587AP056', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA587AP057', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA587AP058', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA587AP059', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA587AP060', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA587AP061', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA587AP062', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA587AP063', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA587AP064', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA587FW138', '', '11', '0', '0', '12', '0'],
  ['UP', 'CLLAGNA587RT001', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA587SW025', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA587SW116', '', '15', '1', '0', '0', '0'],
  ['UP', 'CLLAGNA587SW117', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA587SW118', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA587SW119', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLAGNA587SW120', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLLAGNA587SW122', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP110', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP111', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP112', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP113', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP114', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP115', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP116', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP117', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP118', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP119', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP120', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP121', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP122', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP123', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP124', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP125', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP126', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP127', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP128', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP129', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP130', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP131', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP132', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP133', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP134', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP135', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP136', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP137', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP138', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP139', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP140', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP141', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP142', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP143', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP144', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP145', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP146', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP147', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP148', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP149', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP150', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP151', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP152', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP153', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584AP154', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584FW100', '', '22', '1', '0', '8', '0'],
  ['UP', 'CLLAGPA584RT001', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584SW004', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584SW017', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584SW019', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584SW020', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584SW054', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLLAGPA584SW056', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584SW057', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLLAGPA584SW058', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584SW059', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLLAGPA584SW060', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLLAGPA584SW061', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLLAGPA584SW062', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584SW063', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLLAGPA584SW064', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584SW065', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584SW066', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584SW251', '', '24', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA584SW252', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748AP150', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748AP151', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748AP152', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748AP153', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748AP154', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748AP155', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748AP156', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748AP157', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748AP158', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748AP159', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748AP160', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748AP161', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748AP162', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748AP163', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748AP164', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748AP165', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748AP166', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748AP167', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748AP168', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748AP169', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748AP170', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748AP171', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748AP172', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748AP173', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748AP174', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748AP175', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748AP176', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748RT002', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748SW004', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748SW130', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748SW131', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748SW132', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748SW133', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748SW134', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748SW135', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748SW136', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLLAGPA748SW137', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748SW138', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748SW139', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748SW140', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748SW141', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748SW142', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748SW143', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748SW251', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLLAGPA748SW252', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLLANA529FW119', '', '18', '2', '1', '0', '0'],
  ['UP', 'CLLANA529RT161', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLLANA529SW162', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLLANA529SW163', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLLANA529SW164', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLANA529SW165', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLANA529SW166', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLANA529SW167', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLANU017RT177', '', '18', '1', '0', '0', '0'],
  ['UP', 'CLLANU017SW178', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLLRSN684AP002', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLRSN684AP003', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLRSN684AP004', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLRSN684FW060', '', '12', '1', '1', '3', '0'],
  ['UP', 'CLLRSN684RT001', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLLRSN684SW025', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLLRSN684SW116', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLLRSN684SW117', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLLRSN684SW119', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLLRSN684SW120', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLLRSN684SW121', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLLRSN744AP002', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLRSN744AP004', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLLRSN744FW102', '', '12', '1', '1', '0', '0'],
  ['UP', 'CLLRSN744RT002', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLLRSN744RT003', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLLRSN744SW025', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLLRSN744SW116', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLLRSN744SW117', '', '9', '0', '0', '3', '0'],
  ['UP', 'CLLRSN744SW119', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLMAUN524AP002', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUN524AP003', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUN524FW217', '', '11', '0', '0', '12', '0'],
  ['UP', 'CLMAUN524RT001', '', '16', '1', '1', '0', '0'],
  ['UP', 'CLMAUN524SW025', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLMAUN524SW116', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLMAUN524SW117', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLMAUN524SW118', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLMAUN524SW119', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLMAUN524SW120', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLMAUN645AP080', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUN645AP081', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUN645FW053', '', '19', '1', '1', '0', '0'],
  ['UP', 'CLMAUN645RT065', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLMAUN645SW066', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLMAUN645SW067', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLMAUN645SW068', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLMAUN687AP002', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUN687AP003', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUN687AP004', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUN687FW061', '', '19', '1', '1', '0', '0'],
  ['UP', 'CLMAUN687RT001', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLMAUN687SW116', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLMAUN687SW118', '', '9', '0', '0', '0', '0'],
  ['UP', 'CLMAUN687SW120', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLMAUN828AP200', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUN828AP201', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUN828AP202', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUN828AP203', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUN828FW168', '', '10', '1', '1', '1', '0'],
  ['UP', 'CLMAUN828RT253', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLMAUN828RT254', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLMAUN828SW130', '', '18', '1', '0', '1', '0'],
  ['UP', 'CLMAUN828SW131', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLMAUN828SW132', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLMAUN828SW133', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLMAUN828SW134', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLMAUN828SW135', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLMAUN828SW136', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLMAUN828VG131', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLMAUN838AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUN838AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUN838AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUN838AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUN838FW119', '', '10', '1', '1', '1', '0'],
  ['UP', 'CLMAUN838RT125', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLMAUN838RT126', '', '16', '1', '0', '0', '0'],
  ['UP', 'CLMAUN838SW066', '', '17', '1', '1', '0', '0'],
  ['UP', 'CLMAUN838SW067', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLMAUN838SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLMAUN838SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLMAUN838SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLMAUN838SW071', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLMAUN838SW072', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLMAUN838VG130', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLMAUN909AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUN909AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUN909AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUN909AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUN909FW191', '', '16', '1', '0', '8', '0'],
  ['UP', 'CLMAUN909RT065', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLMAUN909SW066', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLMAUN909SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLMAUN909SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLMAUN909SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLMAUN909SW071', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLMAUN909SW072', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLMAUN909VG130', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLMAUN973AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUN973AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUN973AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUN973AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUN973FW010', '', '10', '1', '1', '1', '0'],
  ['UP', 'CLMAUN973RT065', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLMAUN973RT125', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLMAUN973RT126', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLMAUN973SW066', '', '17', '1', '1', '0', '0'],
  ['UP', 'CLMAUN973SW067', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLMAUN973SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLMAUN973SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLMAUN973SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLMAUN973SW071', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLMAUN973SW072', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA576AP110', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA576AP111', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA576AP112', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA576AP113', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA576AP114', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA576AP115', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA576AP117', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA576AP118', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA576AP119', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA576AP120', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA576AP121', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA576AP122', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA576AP123', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA576AP124', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA576AP125', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA576AP126', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA576AP127', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA576AP128', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA576AP129', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA576AP130', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA576FW098', '', '19', '1', '0', '11', '0'],
  ['UP', 'CLMAUPA576RT001', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA576SW004', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA576SW017', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA576SW018', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA576SW055', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA576SW056', '', '2', '1', '0', '0', '0'],
  ['UP', 'CLMAUPA576SW057', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA576SW058', '', '8', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA576SW059', '', '8', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA576SW060', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA576SW061', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA576SW062', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA576SW063', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA576SW064', '', '10', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA576SW067', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA592AP150', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA592AP151', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA592AP152', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA592AP153', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA592AP154', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA592AP155', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA592AP156', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA592AP157', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA592AP158', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA592AP159', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA592AP160', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA592AP161', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA592AP162', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA592AP163', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA592AP164', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA592AP165', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA592AP166', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA592AP167', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA592AP168', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA592AP169', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA592AP170', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA592AP171', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA592AP172', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA592AP173', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA592AP174', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA592AP175', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA592AP176', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA592FW117', '', '19', '0', '0', '8', '0'],
  ['UP', 'CLMAUPA592RT001', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA592SW004', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA592SW005', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA592SW017', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA592SW018', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA592SW019', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA592SW057', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA592SW058', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA592SW059', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA592SW061', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA592SW062', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA592SW063', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA592SW064', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLMAUPA592SW065', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLOHIN740AP002', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIN740AP003', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIN740AP004', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIN740FW069', '', '17', '1', '1', '2', '0'],
  ['UP', 'CLOHIN740RT001', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLOHIN740SW025', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLOHIN740SW116', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLOHIN740SW117', '', '10', '0', '0', '0', '0'],
  ['UP', 'CLOHIN740SW118', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLOHIN740SW119', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLOHIN740SW122', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLOHIN740SW123', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLOHIN803AP202', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIN803AP203', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIN803AP204', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIN803AP205', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIN803FW239', '', '19', '2', '0', '8', '0'],
  ['UP', 'CLOHIN803RT193', '', '15', '1', '1', '0', '0'],
  ['UP', 'CLOHIN803SW194', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLOHIN803SW195', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLOHIN803SW196', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLOHIN803SW197', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLOHIN803SW198', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLOHIN803SW199', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLOHIN803SW200', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLOHIN803VG067', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLOHIN807AP202', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIN807AP203', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIN807AP204', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIN807AP205', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIN807FW240', '', '23', '0', '0', '8', '0'],
  ['UP', 'CLOHIN807RT129', '', '16', '1', '1', '0', '0'],
  ['UP', 'CLOHIN807RT140', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLOHIN807SW131', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLOHIN807SW132', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLOHIN807SW133', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLOHIN807SW134', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLOHIN807VG141', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLOHIN829AP201', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIN829AP202', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIN829AP203', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIN829AP204', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIN829FW179', '', '17', '0', '0', '8', '0'],
  ['UP', 'CLOHIN829RT253', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLOHIN829SW130', '', '20', '0', '0', '0', '0'],
  ['UP', 'CLOHIN829SW131', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLOHIN829SW132', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLOHIN829SW133', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLOHIN829SW134', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLOHIN831AP201', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIN831AP202', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIN831AP203', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIN831FW178', '', '17', '0', '0', '8', '0'],
  ['UP', 'CLOHIN831RT253', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLOHIN831SW130', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLOHIN831SW131', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLOHIN831SW132', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLOHIN832AP201', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIN832AP202', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIN832AP203', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIN832FW167', '', '15', '0', '0', '3', '0'],
  ['UP', 'CLOHIN832RT253', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLOHIN832RT254', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLOHIN832SW130', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLOHIN832SW131', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLOHIN832SW132', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLOHIN915AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIN915AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIN915AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIN915FW093', '', '19', '1', '1', '0', '0'],
  ['UP', 'CLOHIN915RT065', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLOHIN915SW066', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLOHIN915SW067', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLOHIN915SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLOHIN915SW069', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLOHIN915SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLOHIN915SW071', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLOHIN915SW072', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLOHIN915SW073', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLOHIN915VG130', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLOHIN924AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIN924AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIN924AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIN924AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIN924FW126', '', '10', '1', '1', '1', '0'],
  ['UP', 'CLOHIN924RT125', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLOHIN924RT126', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLOHIN924SW066', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLOHIN924SW067', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLOHIN924SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLOHIN924SW069', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLOHIN924SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLOHIN924SW071', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP002', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP003', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP004', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP005', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP006', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP007', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP008', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP009', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP010', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP011', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP012', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP013', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP014', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP015', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP016', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP017', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP018', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP019', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP020', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP021', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP022', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP023', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP024', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP025', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP026', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP027', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP028', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP029', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP030', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP031', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP032', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP033', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP034', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP035', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP036', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP037', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP038', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP039', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP040', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP041', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP042', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP043', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP044', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504AP045', '', '1', '0', '0', '0', '0'],
  ['DOWN', 'CLOHIPA504AP093', '', '0', '0', '0', '1', '0'],
  ['UP', 'CLOHIPA504AP099', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504FW148', '', '16', '0', '0', '10', '0'],
  ['UP', 'CLOHIPA504FW196', '', '70', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504RT002', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504RT003', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504SW004', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504SW017', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504SW018', '', '12', '1', '0', '0', '0'],
  ['UP', 'CLOHIPA504SW019', '', '24', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504SW055', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLOHIPA504SW056', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504SW057', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504SW058', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504SW059', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLOHIPA504SW060', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504SW061', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504SW062', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLOHIPA504SW063', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504SW064', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLOHIPA504SW065', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504SW066', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504SW130', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504SW131', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504SW132', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504SW133', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA504SW134', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807AP150', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807AP151', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807AP152', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807AP153', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807AP154', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807AP155', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807AP156', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807AP157', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807AP158', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807AP159', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807AP160', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807AP161', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807AP162', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807AP163', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807AP164', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807AP165', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807AP166', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807AP167', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807AP168', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807AP169', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807AP170', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807AP171', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807AP172', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807AP173', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807AP174', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807AP175', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807AP176', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807AP177', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807AP178', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807AP179', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807AP180', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807AP181', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807AP182', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807AP183', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807AP184', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807RT130', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807SW130', '', '21', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807SW131', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807SW132', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807SW133', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807SW134', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807SW135', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807SW136', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807SW137', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807SW138', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807SW139', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807SW140', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807SW141', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807SW142', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLOHIPA807SW143', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLOSOU026RT177', '', '12', '0', '0', '1', '0'],
  ['UP', 'CLOSOU026SW178', '', '6', '0', '0', '0', '0'],
  ['UP', 'Cloud-Fortigate-Azure', '', '50', '1', '0', '1', '0'],
  ['UP', 'CLRANU001RT049', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLRANU001SW050', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLRANU024RT241', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLRANU024SW242', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLRIOEA744AP160', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLRIOEA744AP161', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLRIOEA744AP162', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLRIOEA744FW143', '', '14', '0', '0', '12', '0'],
  ['UP', 'CLRIOEA744RT129', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLRIOEA744SW130', '', '10', '1', '1', '0', '0'],
  ['UP', 'CLRIOEA744SW131', '', '12', '1', '0', '0', '0'],
  ['UP', 'CLRION928AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLRION928AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLRION928AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLRION928AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLRION928FW193', '', '15', '1', '0', '9', '0'],
  ['UP', 'CLRION928RT065', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLRION928SW066', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLRION928SW067', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLRION928SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLRION928SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLRION928SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLRION928SW071', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLRIONA828RT130', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLSCLN553AP080', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN553AP081', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN553AP082', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN553AP083', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN553AP084', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN553AP085', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN553AP086', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN553AP087', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN553AP088', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN553AP089', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN553AP090', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN553AP091', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN553AP092', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN553AP093', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN553FW147', '', '15', '0', '0', '3', '0'],
  ['UP', 'CLSCLN553RT125', '', '17', '1', '1', '0', '0'],
  ['UP', 'CLSCLN553RT126', 'themes/facelift/images/icon_host_graph.svg', '16', '0', '0', '0', '0'],
  ['UP', 'CLSCLN553SW066', '', '20', '0', '0', '0', '0'],
  ['UP', 'CLSCLN553SW067', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSCLN553SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN553SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN553SW070', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSCLN553SW071', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN589AP002', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN589AP003', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN589AP004', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN589RT125', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLSCLN589SW116', '', '15', '1', '0', '0', '0'],
  ['UP', 'CLSCLN589SW117', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSCLN589SW118', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSCLN589SW119', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN589SW120', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSCLN593AP085', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN593AP086', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN593AP087', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN593AP088', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN593AP089', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN593AP090', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN593AP091', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN593AP092', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN593AP093', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN593AP094', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN593AP095', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN593AP096', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN593AP097', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN593FW057', '', '14', '1', '0', '3', '0'],
  ['UP', 'CLSCLN593RT125', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSCLN593RT126', '', '17', '1', '1', '0', '0'],
  ['UP', 'CLSCLN593SW066', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSCLN593SW067', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSCLN593SW068', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSCLN593SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN593SW070', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSCLN593SW071', '', '20', '0', '0', '0', '0'],
  ['UP', 'CLSCLN593SW072', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSCLN600AP002', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN600AP003', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN600AP004', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN600FW150', '', '10', '1', '1', '1', '0'],
  ['UP', 'CLSCLN600RT240', '', '16', '0', '1', '0', '0'],
  ['UP', 'CLSCLN600RT254', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLSCLN600SW130', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLSCLN600SW131', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN600SW132', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN600SW133', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN600SW134', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSCLN602SW122', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSCLN607AP212', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN607AP213', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN607AP215', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN607FW152', '', '10', '1', '1', '1', '0'],
  ['UP', 'CLSCLN607RT253', '', '22', '0', '0', '0', '0'],
  ['UP', 'CLSCLN607RT254', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSCLN607SW194', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSCLN607SW195', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN607SW196', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSCLN607SW197', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN609AP010', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN609AP011', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN609AP012', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN609AP013', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN609AP014', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN609AP015', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN609AP016', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN609AP017', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN609AP018', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN609AP019', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN609AP020', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN609AP021', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN609AP022', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN609AP023', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN609AP024', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN609FW086', '', '19', '0', '0', '3', '0'],
  ['UP', 'CLSCLN609RT125', '', '15', '0', '0', '3', '0'],
  ['UP', 'CLSCLN609RT126', '', '18', '1', '0', '0', '0'],
  ['UP', 'CLSCLN609SW116', '', '16', '1', '1', '0', '0'],
  ['UP', 'CLSCLN609SW117', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSCLN609SW118', '', '13', '1', '0', '0', '0'],
  ['UP', 'CLSCLN609SW119', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSCLN609SW120', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN609SW122', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN626AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN626AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN626AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN626AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN626AP104', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN626AP105', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN626AP106', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN626AP107', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN626AP108', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN626AP109', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN626AP110', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN626AP111', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN626FW088', '', '19', '0', '0', '3', '0'],
  ['UP', 'CLSCLN626RT125', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSCLN626RT126', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSCLN626SW066', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLSCLN626SW067', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN626SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN626SW069', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSCLN626SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN626SW071', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSCLN634AP080', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN634AP081', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN634AP082', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN634RT125', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLSCLN634RT126', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSCLN634SW066', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSCLN634SW067', '', '12', '1', '0', '0', '0'],
  ['UP', 'CLSCLN634SW068', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSCLN634SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN634SW071', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSCLN635AP002', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN635AP003', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN635AP004', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN635FW089', '', '16', '1', '1', '0', '0'],
  ['UP', 'CLSCLN635RT252', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSCLN635RT253', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSCLN635SW130', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSCLN635SW131', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSCLN635SW132', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSCLN635SW133', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN635SW134', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN635SW135', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSCLN636AP080', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN636AP081', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN636AP082', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN636AP083', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN636AP084', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN636AP085', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN636AP086', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN636AP087', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN636AP088', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN636AP089', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN636AP090', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN636AP091', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN636AP092', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN636AP093', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN636AP094', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN636FW121', '', '23', '2', '0', '5', '0'],
  ['UP', 'CLSCLN636RT001', '', '15', '0', '0', '2', '0'],
  ['UP', 'CLSCLN636SW066', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSCLN636SW067', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN636SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN636SW069', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSCLN639AP071', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN639AP072', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN639AP073', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN639AP074', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN639AP075', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN639AP076', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN639AP077', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN639AP078', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN639FW045', '', '33', '0', '0', '3', '0'],
  ['UP', 'CLSCLN639RT125', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSCLN639RT126', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSCLN639SW066', '', '12', '1', '0', '0', '0'],
  ['UP', 'CLSCLN639SW067', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSCLN639SW068', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSCLN639SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN639SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN639SW071', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSCLN639SW114', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLSCLN639SW115', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLSCLN643AP208', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN643AP210', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN643AP211', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN643AP212', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN643FW066', '', '18', '1', '0', '0', '0'],
  ['UP', 'CLSCLN643RT254', '', '16', '1', '1', '0', '0'],
  ['UP', 'CLSCLN643SW141', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSCLN643SW143', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSCLN643SW145', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN643SW146', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN643SW147', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN643SW148', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN643VG253', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLSCLN669AP003', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN669AP004', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN669AP005', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN669AP006', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN669AP007', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN669FW190', '', '12', '1', '0', '3', '0'],
  ['UP', 'CLSCLN669RT002', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSCLN669RT003', '', '15', '1', '0', '0', '0'],
  ['UP', 'CLSCLN669SW025', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN669SW117', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSCLN669SW120', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSCLN669SW121', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSCLN682AP030', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN682AP031', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN682AP032', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN682AP033', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN682AP034', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN682AP035', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN682AP036', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN682AP037', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN682AP038', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN682AP039', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN682AP040', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN682AP041', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN682AP042', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN682AP043', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN682AP044', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN682AP045', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN682AP046', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN682AP047', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN682AP048', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN682AP049', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN682AP050', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN682AP051', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN682AP052', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN682FW092', '', '19', '0', '0', '3', '0'],
  ['UP', 'CLSCLN682RT002', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSCLN682RT003', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSCLN682SW025', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN682SW116', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSCLN682SW117', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN682SW120', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN689AP002', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN689AP003', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN689AP004', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN689FW143', '', '18', '1', '0', '0', '0'],
  ['UP', 'CLSCLN689RT002', '', '17', '1', '1', '0', '0'],
  ['UP', 'CLSCLN689RT003', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLSCLN689SW025', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN689SW116', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN689SW117', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSCLN689SW119', '', '7', '0', '0', '0', '0'],
  ['UP', 'CLSCLN689SW120', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN698RT001', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSCLN698SW025', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN698SW116', '', '13', '0', '0', '2', '0'],
  ['UP', 'CLSCLN698SW117', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSCLN698SW119', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN698SW120', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN699AP002', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN699AP003', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN699AP004', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN699FW146', '', '19', '1', '1', '0', '0'],
  ['UP', 'CLSCLN699RT001', '', '20', '0', '0', '0', '0'],
  ['UP', 'CLSCLN699SW025', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN699SW116', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLSCLN699SW117', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSCLN699SW119', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSCLN699SW120', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN701AP002', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN701AP003', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN701AP004', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN701FW095', '', '12', '1', '1', '0', '0'],
  ['UP', 'CLSCLN701RT002', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSCLN701RT003', '', '15', '0', '0', '1', '0'],
  ['UP', 'CLSCLN701SW025', '', '9', '2', '0', '0', '0'],
  ['UP', 'CLSCLN701SW116', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSCLN701SW117', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSCLN701SW119', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSCLN702SW116', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLSCLN702SW117', '', '9', '0', '0', '0', '0'],
  ['UP', 'CLSCLN704AP030', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN704AP031', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN704AP032', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN704AP033', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN704AP034', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN704AP035', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN704AP036', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN704AP037', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN704AP038', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN704AP039', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN704AP040', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN704AP041', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN704AP042', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN704AP043', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN704AP044', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN704AP045', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN704AP046', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN704AP047', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN704AP048', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN704AP049', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN704AP050', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN704AP051', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN704FW097', '', '22', '1', '0', '0', '0'],
  ['UP', 'CLSCLN704RT125', '', '22', '0', '0', '0', '0'],
  ['UP', 'CLSCLN704RT126', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSCLN704SW025', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN704SW116', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLSCLN704SW117', '', '10', '1', '0', '1', '0'],
  ['UP', 'CLSCLN704SW119', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN707AP030', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN707AP031', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN707AP032', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN707AP033', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN707AP034', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN707AP035', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN707AP036', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN707AP037', '', '1', '0', '0', '0', '0'],
  ['DOWN', 'CLSCLN707AP038', '', '0', '0', '0', '1', '0'],
  ['UP', 'CLSCLN707AP039', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN707AP040', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN707FW098', '', '19', '0', '0', '3', '0'],
  ['UP', 'CLSCLN707RT002', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSCLN707RT003', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSCLN707SW025', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN707SW116', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSCLN707SW117', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN707SW119', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSCLN725AP047', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN725AP094', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN725AP115', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN725AP116', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN725AP117', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN725AP118', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN725AP119', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN725AP120', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN725AP121', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN725AP122', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN725AP123', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN725AP124', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN725AP125', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN725AP126', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN725AP127', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN725AP128', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN725AP129', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN725AP130', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN725AP131', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN725AP132', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN725AP133', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN725AP134', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN725AP135', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN736AP010', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN736AP011', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN736AP012', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN736AP013', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN736AP014', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN736AP015', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN736AP016', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN736AP017', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN736AP018', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN736AP019', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN736AP020', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN736AP021', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN736AP022', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN736AP023', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN736AP024', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN736FW099', '', '17', '1', '0', '1', '0'],
  ['UP', 'CLSCLN736RT001', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSCLN736RT002', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSCLN736RT003', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLSCLN736SW116', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSCLN736SW117', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSCLN736SW119', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN736SW121', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSCLN736SW122', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN736SW124', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSCLN736SW125', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLSCLN736SW132', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSCLN736SW160', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN755AP201', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN755AP202', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN755AP204', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN755SW130', '', '21', '0', '0', '0', '0'],
  ['UP', 'CLSCLN755SW131', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN755SW132', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN755SW133', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN755SW134', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSCLN755SW135', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSCLN755VG131', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLSCLN757AP080', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN757AP081', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN757AP082', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN757FW104', '', '16', '1', '1', '0', '0'],
  ['UP', 'CLSCLN757RT124', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSCLN757RT125', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSCLN757SW066', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSCLN757SW067', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSCLN757SW068', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSCLN757SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN757SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN757SW071', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSCLN758AP150', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN758AP151', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN758AP152', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN758AP153', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN758AP154', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN758AP155', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN758AP156', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN758AP157', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN758AP158', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN758AP159', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN758AP160', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN758AP161', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN758AP162', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN758AP163', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN758AP164', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN758AP165', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN758FW105', '', '19', '0', '0', '3', '0'],
  ['UP', 'CLSCLN758RT253', '', '21', '0', '0', '0', '0'],
  ['UP', 'CLSCLN758RT254', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSCLN758RTV254', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSCLN758SW130', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSCLN758SW131', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSCLN758SW132', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN758SW133', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSCLN758SW134', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSCLN758SW135', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSCLN758VG253', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLSCLN759AP200', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN759AP201', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN759AP202', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN759AP203', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN759FW009', '', '19', '2', '1', '0', '0'],
  ['UP', 'CLSCLN759RT001', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSCLN759SW130', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSCLN759SW131', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSCLN759SW132', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN759SW133', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN759SW134', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN759SW135', '', '12', '0', '0', '1', '0'],
  ['UP', 'CLSCLN759SW136', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN759VG131', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLSCLN763AP002', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN763AP003', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN763AP004', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN763FW106', '', '16', '1', '1', '0', '0'],
  ['UP', 'CLSCLN763RT002', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSCLN763RT003', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLSCLN763SW116', '', '13', '1', '0', '0', '0'],
  ['UP', 'CLSCLN763SW117', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN763SW119', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLSCLN763SW120', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLSCLN763SW121', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSCLN763SW122', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN763SW123', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN764AP010', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN764AP011', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN764AP012', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN764AP013', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN764AP014', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN764AP015', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN764AP016', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN764AP017', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN764AP018', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN764AP019', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN764AP020', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN764AP021', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN764AP022', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN764AP023', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN764FW107', '', '17', '1', '1', '0', '0'],
  ['UP', 'CLSCLN764RT002', '', '20', '0', '0', '0', '0'],
  ['UP', 'CLSCLN764RT003', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLSCLN764SW116', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSCLN764SW117', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN764SW118', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSCLN764SW119', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSCLN764SW120', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN764SW122', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSCLN765AP010', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN765AP011', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN765AP012', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN765AP013', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN765AP014', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN765AP015', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN765AP016', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN765AP017', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN765AP018', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN765AP019', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN765AP020', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN765AP021', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN765AP022', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN765FW108', '', '12', '1', '1', '0', '0'],
  ['UP', 'CLSCLN765RT002', '', '20', '0', '0', '0', '0'],
  ['UP', 'CLSCLN765RT003', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLSCLN765SW025', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN765SW116', '', '12', '1', '0', '0', '0'],
  ['UP', 'CLSCLN765SW120', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLSCLN765SW121', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSCLN765SW122', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSCLN766AP030', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN766AP031', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN766AP032', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN766AP033', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN766AP034', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN766AP035', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN766AP036', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN766AP037', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN766AP038', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN766AP039', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN766AP040', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN766AP041', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN766AP042', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN766AP043', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN766AP044', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN766FW010', '', '15', '1', '1', '1', '0'],
  ['UP', 'CLSCLN766RT001', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSCLN766SW025', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN766SW116', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLSCLN766SW117', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSCLN766SW119', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSCLN766SW120', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSCLN766SW122', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSCLN767AP002', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN767AP003', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN767FW011', '', '19', '2', '1', '0', '0'],
  ['UP', 'CLSCLN767RT001', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSCLN767SW025', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN767SW116', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSCLN767SW117', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN767SW119', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSCLN767SW120', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN767SW122', '', '12', '0', '0', '0', '0'],
  ['DOWN', 'CLSCLN769AP003', '', '0', '0', '0', '1', '0'],
  ['UP', 'CLSCLN769AP005', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN769FW109', '', '16', '1', '1', '0', '0'],
  ['UP', 'CLSCLN769RT002', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSCLN769RT003', '', '15', '0', '0', '1', '0'],
  ['UP', 'CLSCLN769SW025', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSCLN769SW116', '', '15', '1', '0', '0', '0'],
  ['UP', 'CLSCLN769SW117', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN769SW119', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSCLN769SW121', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN769SW122', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSCLN776AP030', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN776AP031', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN776AP032', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN776AP033', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN776AP034', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN776AP035', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN776AP036', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN776AP037', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN776AP038', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN776AP039', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN776AP040', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN776AP041', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN776AP042', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN776AP043', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN776AP044', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN776AP045', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN776AP046', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN776FW110', '', '15', '0', '0', '3', '0'],
  ['UP', 'CLSCLN776RT002', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSCLN776RT003', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLSCLN776SW025', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN776SW116', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSCLN776SW117', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN776SW118', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN776SW119', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSCLN777AP030', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN777AP031', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN777AP032', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN777AP033', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN777AP034', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN777AP035', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN777AP036', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN777AP037', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN777AP038', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN777AP039', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN777FW111', '', '15', '0', '0', '3', '0'],
  ['UP', 'CLSCLN777RT002', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSCLN777RT003', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLSCLN777SW025', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSCLN777SW116', '', '23', '0', '0', '0', '0'],
  ['UP', 'CLSCLN777SW117', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSCLN777SW118', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSCLN777SW120', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSCLN777SW122', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSCLN802AP201', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN802AP202', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN802AP203', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN802AP204', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN802FW012', '', '18', '2', '1', '0', '0'],
  ['UP', 'CLSCLN802RT001', '', '16', '1', '1', '0', '0'],
  ['UP', 'CLSCLN802SW130', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSCLN802SW132', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSCLN802SW133', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN802SW134', '', '11', '0', '0', '1', '0'],
  ['UP', 'CLSCLN802SW135', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN802SW136', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN802VG131', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLSCLN806AP201', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN806AP202', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN806AP203', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN806AP204', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN806FW114', '', '16', '1', '1', '0', '0'],
  ['UP', 'CLSCLN806RT253', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSCLN806RT254', '', '17', '1', '1', '0', '0'],
  ['UP', 'CLSCLN806SW130', '', '20', '0', '0', '0', '0'],
  ['UP', 'CLSCLN806SW131', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN806SW132', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSCLN806SW133', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN806SW134', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN806SW135', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSCLN806SW136', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN806VG131', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLSCLN818AP080', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN818AP081', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN818AP082', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN818AP083', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN818AP084', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN818AP085', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN818AP086', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN818AP087', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN818AP088', '', '1', '0', '0', '0', '0'],
  ['DOWN', 'CLSCLN818AP089', '', '0', '0', '0', '1', '0'],
  ['UP', 'CLSCLN818AP090', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN818AP091', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN818AP092', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN818AP093', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN818AP094', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN818AP095', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN818FW236', '', '17', '0', '0', '8', '0'],
  ['UP', 'CLSCLN818RT125', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSCLN818SW066', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSCLN818SW067', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN818SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN818SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN818SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN818SW071', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN818VG130', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLSCLN823AP080', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN823AP081', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN823AP082', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN823AP083', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN823AP084', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN823AP085', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN823AP086', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN823AP087', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN823AP088', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN823AP089', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN823AP090', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN823AP091', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN823AP092', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN823AP093', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN823AP094', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN823AP095', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN823AP096', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN823FW116', '', '19', '0', '0', '3', '0'],
  ['UP', 'CLSCLN823RT125', '', '14', '0', '0', '2', '0'],
  ['UP', 'CLSCLN823RT126', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSCLN823SW066', '', '20', '0', '0', '0', '0'],
  ['UP', 'CLSCLN823SW067', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN823SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN823SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN823SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN823SW071', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSCLN823SW072', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN823VG130', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLSCLN834AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN834AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN834AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN834AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN834FW118', '', '12', '1', '1', '0', '0'],
  ['UP', 'CLSCLN834RT125', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLSCLN834RT126', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSCLN834SW066', '', '20', '0', '0', '0', '0'],
  ['UP', 'CLSCLN834SW067', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSCLN834SW068', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSCLN834SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN834SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN834SW071', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSCLN834SW072', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN834SW073', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN834VG130', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLSCLN847AP080', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN847AP081', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN847AP082', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN847AP083', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN847AP084', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN847AP085', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN847AP086', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN847AP087', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN847AP088', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN847AP089', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN847AP090', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN847AP091', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN847AP092', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN847AP093', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN847AP094', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN847AP095', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN847FW122', '', '15', '0', '0', '3', '0'],
  ['UP', 'CLSCLN847RT125', '', '22', '0', '0', '0', '0'],
  ['UP', 'CLSCLN847RT126', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSCLN847SW066', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSCLN847SW067', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSCLN847SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN847SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN847SW070', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSCLN847SW071', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN847SW072', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN847SW130', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSCLN847VG130', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLSCLN914AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN914AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN914AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN914SW066', '', '16', '1', '0', '0', '0'],
  ['UP', 'CLSCLN914SW067', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSCLN914SW068', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSCLN914SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN914SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN914SW071', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN914VG130', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLSCLN918AP080', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN918AP081', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN918AP082', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN918AP083', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN918AP084', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN918AP085', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN918AP086', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN918AP087', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN918AP088', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN918AP089', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN918AP090', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN918AP091', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN918AP092', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN918AP093', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN918AP094', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN918FW124', '', '19', '0', '0', '3', '0'],
  ['UP', 'CLSCLN918RT125', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSCLN918RT126', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSCLN918SW066', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSCLN918SW067', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN918SW068', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSCLN918SW069', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSCLN918SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN918SW071', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN918SW072', '', '12', '0', '0', '0', '0'],
  ['DOWN', 'CLSCLN918VG130', '', '1', '0', '0', '2', '0'],
  ['UP', 'CLSCLN923AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN923AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN923AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN923AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN923FW125', '', '15', '0', '0', '3', '0'],
  ['UP', 'CLSCLN923RT125', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSCLN923RT126', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSCLN923SW066', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSCLN923SW067', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN923SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN923SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN926FW081', '', '16', '1', '1', '1', '0'],
  ['UP', 'CLSCLN926RT125', '', '22', '0', '0', '0', '0'],
  ['UP', 'CLSCLN926RT126', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSCLN926SW066', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSCLN926SW067', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSCLN926SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN926SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN926SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN926SW071', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSCLN926VG130', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLSCLN991AP015', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN991AP016', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN991AP017', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN991AP018', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN991AP019', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLN991FW166', '', '14', '1', '0', '3', '0'],
  ['UP', 'CLSCLN991RT061', '', '22', '0', '0', '0', '0'],
  ['UP', 'CLSCLN991RT062', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSCLN991SM002', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSCLN991SW003', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN991SW004', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN991SW005', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN991SW006', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLN991SW007', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLSN595AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLSN595AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLSN595AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLSN595AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLSN595AP104', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLSN595AP105', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSCLSN595FW152', '', '19', '1', '1', '0', '0'],
  ['UP', 'CLSCLSN595RT065', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSCLSN595SW066', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSCLSN595SW067', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLSN595SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLSN595SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSCLSN595SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOBIMOLFW169', '', '21', '1', '0', '5', '0'],
  ['UP', 'CLSGOBIMOLRT193', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOFRIAP139', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOFRIAP140', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOFRIRT129', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOFRIRT253', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOFRIRT254', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOFRISW130', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARAP030', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARAP031', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARAP032', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARAP033', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARAP034', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARAP035', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARAP036', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARAP037', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARAP038', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARAP039', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARAP040', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARAP041', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARAP042', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARAP043', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARAP044', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARAP045', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARAP046', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARAP047', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARAP048', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARAP049', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARAP050', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARAP051', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARAP061', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARAP062', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARAP063', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARAP064', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARAP065', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARAP066', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARAP067', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARAP068', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARAP069', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARAP070', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARRT002', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARRT003', '', '15', '1', '1', '0', '0'],
  ['UP', 'CLSGOBOGARSW004', '', '27', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARSW005', '', '25', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARSW006', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARSW010', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARSW011', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARSW012', '', '20', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARSW013', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARSW024', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARSW130', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARSW140', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGARSW165', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP037', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP038', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP039', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP040', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP041', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP042', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP043', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP044', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP045', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP046', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP047', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP048', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP051', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP052', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP053', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP054', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP055', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP056', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP057', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP058', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP059', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP060', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP061', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP062', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP063', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP064', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP065', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP066', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP067', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP068', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP069', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP070', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP071', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP072', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP073', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP074', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP075', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP076', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP077', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP078', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP079', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP080', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP081', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP082', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP083', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP084', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP085', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP086', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP087', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP088', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP089', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP090', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP091', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP092', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP093', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP094', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUAAP095', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUART002', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUART003', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUASW035', '', '20', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUASW036', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUASW051', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUASW052', '', '20', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUASW053', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUASW054', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUASW055', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUASW056', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUASW057', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUASW058', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUASW059', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOGUASW060', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGOBOLGZSW024', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGOBSFK2SW002', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOBSFX7AP010', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOBSFX7RT002', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGOBSFX7RT003', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOBSFX7SW004', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP011', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP012', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP013', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP014', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP015', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP016', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP017', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP018', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP019', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP020', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP021', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP022', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP023', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP024', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP025', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP026', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP027', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP028', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP029', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP030', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP031', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP032', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP033', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP034', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP035', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP036', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP037', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP038', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP039', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP040', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP041', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP042', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP043', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP044', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP045', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP046', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP048', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP049', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP050', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP051', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP052', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP053', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP054', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP055', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP056', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP057', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP058', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP059', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP061', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP062', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP064', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP065', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP066', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP067', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP068', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP069', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP070', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP071', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP072', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP073', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP074', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP075', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP076', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP077', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP078', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP079', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP080', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP081', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP082', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP083', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP084', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP085', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP095', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP096', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVAP097', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVSW011', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVSW031', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVSW035', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVSW036', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVSW037', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVSW038', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVSW040', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVSW042', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVSW043', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVSW045', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDNOVSW047', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP031', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP032', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP033', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP034', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP035', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP038', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP039', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP040', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP041', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP042', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP043', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP044', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP045', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP046', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP047', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP048', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP049', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP050', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP051', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP052', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP053', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP054', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP055', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP056', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP057', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP058', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP059', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP060', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP061', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP063', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP064', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP066', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP067', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP068', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP069', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP070', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP071', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP072', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP073', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP074', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP075', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP076', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP077', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP078', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP079', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP080', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP081', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP082', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP083', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP084', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP085', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP086', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP087', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP088', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP089', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP090', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP092', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP093', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP094', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP095', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP096', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP098', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP099', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP104', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDAP105', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDFW201', '', '33', '0', '0', '5', '0'],
  ['UP', 'CLSGOCDTXDRT017', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDSW010', '', '18', '1', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDSW011', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDSW012', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDSW013', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDSW014', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDSW015', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDSW016', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDSW017', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDSW018', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOCDTXDSW019', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOCOSP6AP110', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCOSP6AP111', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCOSP6AP112', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCOSP6AP113', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCOSP6AP114', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCOSP6AP115', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCOSP6AP116', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCOSP6AP117', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCOSP6AP118', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCOSP6AP119', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCOSP6AP120', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCOSP6AP121', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCOSP6AP122', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCOSP6AP123', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCOSP6AP124', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCOSP6AP125', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCOSP6AP126', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCOSP6AP147', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCOSP6AP148', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCOSP6AP149', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOCOSP6RT001', '', '32', '0', '0', '0', '0'],
  ['UP', 'CLSGOCOSP6RT254', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSGOCOSP6SW010', '', '18', '1', '0', '0', '0'],
  ['UP', 'CLSGOCOSP6SW011', '', '18', '1', '0', '0', '0'],
  ['UP', 'CLSGOCOSP6SW012', '', '15', '1', '3', '0', '0'],
  ['UP', 'CLSGOCOSP6SW013', '', '19', '1', '0', '0', '0'],
  ['UP', 'CLSGOCOSP6SW014', '', '18', '1', '0', '0', '0'],
  ['UP', 'CLSGOCOSP6SW017', '', '18', '1', '0', '0', '0'],
  ['UP', 'CLSGOCOSP6SW018', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOCOSP6SW019', '', '21', '1', '0', '0', '0'],
  ['UP', 'CLSGOCOSP6SW020', '', '13', '1', '0', '0', '0'],
  ['UP', 'CLSGOCOSP6SW100', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOCOSP6SW101', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOCOSP6SW146', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGOCOSP7SW015', '', '18', '1', '0', '0', '0'],
  ['UP', 'CLSGOCOSP7SW016', '', '17', '1', '0', '0', '0'],
  ['UP', 'CLSGOEU221RT049', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGOEU221SW050', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLSGOEU222RT113', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGOEU222SW114', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLSGOEU224RT241', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGOEU224SW242', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLSGOEU225RT177', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGOEU225SW178', '', '6', '0', '0', '0', '0'],
  ['UNREACH', 'CLSGOEU580AP007', '', '0', '0', '0', '1', '0'],
  ['UNREACH', 'CLSGOEU580AP008', '', '0', '0', '0', '1', '0'],
  ['UNREACH', 'CLSGOEU580AP009', '', '0', '0', '0', '1', '0'],
  ['UNREACH', 'CLSGOEU580AP010', '', '0', '0', '0', '1', '0'],
  ['UNREACH', 'CLSGOEU580AP011', '', '0', '0', '0', '1', '0'],
  ['UNREACH', 'CLSGOEU580AP012', '', '0', '0', '0', '1', '0'],
  ['UNREACH', 'CLSGOEU580AP020', '', '0', '0', '0', '1', '0'],
  ['UNREACH', 'CLSGOEU580AP021', '', '0', '0', '0', '1', '0'],
  ['UNREACH', 'CLSGOEU580AP022', '', '0', '0', '0', '1', '0'],
  ['UNREACH', 'CLSGOEU580AP023', '', '0', '0', '0', '1', '0'],
  ['UNREACH', 'CLSGOEU580AP160', '', '0', '0', '0', '1', '0'],
  ['UNREACH', 'CLSGOEU580AP161', '', '0', '0', '0', '1', '0'],
  ['UNREACH', 'CLSGOEU580AP162', '', '0', '0', '0', '1', '0'],
  ['UNREACH', 'CLSGOEU580AP163', '', '0', '0', '0', '1', '0'],
  ['UNREACH', 'CLSGOEU580AP164', '', '0', '0', '0', '1', '0'],
  ['UNREACH', 'CLSGOEU580AP165', '', '0', '0', '0', '1', '0'],
  ['UNREACH', 'CLSGOEU580RT130', '', '12', '0', '1', '4', '0'],
  ['UNREACH', 'CLSGOEU580RT131', '', '13', '0', '0', '3', '0'],
  ['UNREACH', 'CLSGOEU580SW002', '', '11', '0', '0', '3', '0'],
  ['UNREACH', 'CLSGOEU580SW003', '', '11', '0', '0', '3', '0'],
  ['UNREACH', 'CLSGOEU580SW004', '', '10', '0', '0', '3', '0'],
  ['UNREACH', 'CLSGOEU580SW005', '', '13', '0', '0', '3', '0'],
  ['UNREACH', 'CLSGOEU580SW006', '', '10', '0', '0', '3', '0'],
  ['UNREACH', 'CLSGOEU580SW013', '', '10', '0', '0', '3', '0'],
  ['UNREACH', 'CLSGOEU580SW014', '', '11', '0', '0', '3', '0'],
  ['UNREACH', 'CLSGOEU580SW015', '', '3', '0', '0', '3', '0'],
  ['UNREACH', 'CLSGOEU580SW016', '', '9', '0', '0', '3', '0'],
  ['UNREACH', 'CLSGOEU580SW017', '', '8', '0', '0', '3', '0'],
  ['DOWN', 'CLSGOEU580SW140', '', '13', '0', '0', '3', '0'],
  ['UNREACH', 'CLSGOEU580SW141', '', '9', '0', '0', '3', '0'],
  ['UNREACH', 'CLSGOEU580SW142', '', '10', '0', '0', '3', '0'],
  ['UNREACH', 'CLSGOEU580SW143', '', '9', '0', '0', '3', '0'],
  ['UNREACH', 'CLSGOEU580SW145', '', '10', '0', '0', '3', '0'],
  ['UNREACH', 'CLSGOEU580SW146', '', '12', '0', '0', '3', '0'],
  ['UNREACH', 'CLSGOEU580SW147', '', '9', '0', '0', '3', '0'],
  ['UNREACH', 'CLSGOEU580SW148', '', '9', '0', '0', '3', '0'],
  ['UNREACH', 'CLSGOEU580SW151', '', '9', '0', '0', '3', '0'],
  ['DOWN', 'CLSGOJU503FW100', '', '1', '0', '0', '2', '0'],
  ['UP', 'CLSGOJU510FW061', '', '22', '0', '0', '0', '0'],
  ['UP', 'CLSGOJU599SW006-2', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOJU599SW007-2', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOJU599SW023', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOJU599SW024', '', '12', '0', '0', '1', '0'],
  ['UP', 'CLSGOJU599SW240', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOJU599SW241', '', '12', '0', '0', '0', '0'],
  ['DOWN', 'CLSGOJU992FW150', '', '1', '0', '0', '2', '0'],
  ['UP', 'CLSGOKOMAXAP020', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXAP021', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXAP022', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXAP023', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXAP024', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXAP025', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXAP026', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXAP027', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXAP028', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXAP029', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXAP030', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXAP031', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXAP032', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXAP033', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXAP034', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXAP035', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXAP036', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXAP037', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXAP038', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXAP039', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXAP040', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXAP041', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXAP042', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXAP043', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXAP044', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXAP045', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXAP046', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXAP047', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXAP048', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXAP049', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXAP050', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXAP051', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXAP052', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXAP053', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXAP054', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXAP055', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXAP056', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXAP057', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXAP058', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXRT002', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXRT003', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXSW010', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXSW011', '', '25', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXSW012', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXSW013', '', '24', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXSW014', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXSW015', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXSW016', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXSW017', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGOKOMAXSW018', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGOLOGINAP004', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLOGINAP005', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLOGINAP006', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLOGINAP012', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLOGINAP013', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLOGINAP014', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLOGINSW001', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOLOGINSW002', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOLOGINSW003', '', '12', '0', '0', '1', '0'],
  ['UP', 'CLSGOLOGINSW007', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGOLOGINSW008', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOLOGINSW009', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOLOGINSW010', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC01AP181', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC01AP182', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC01AP183', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC01AP184', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC01AP185', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC01AP186', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC01AP187', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC01AP217', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC01AP218', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC01AP219', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC01AP220', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC01AP221', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC01AP222', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC01SW159', '', '24', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC01SW160', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC01SW161', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC02AP080', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC02AP081', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC02AP082', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC02AP083', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC02AP084', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC02AP085', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC02AP086', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC02AP087', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC02AP088', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC02AP089', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC02AP114', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC02AP115', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC02AP117', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC02RT065', '', '22', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC02SW066', '', '23', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC02SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC02SW072', '', '14', '1', '0', '0', '0'],
  ['UP', 'CLSGOLSC03AP188', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC03AP189', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC03AP190', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC03AP191', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC03AP192', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC03AP193', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC03AP194', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC03AP195', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC03AP196', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC03AP197', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC03AP198', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC03AP199', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC03AP200', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC03AP201', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC03SW162', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC03SW163', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC04AP090', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC04AP091', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC04AP092', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC04AP093', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC04AP094', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC04AP095', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC04AP096', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC04AP097', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC04AP098', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC04AP099', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC04AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC04AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC04AP116', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC04AP154', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC04SW071', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC05AP202', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC05AP203', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC05AP204', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC05AP205', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC05AP206', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC05AP207', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC05AP208', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC05AP209', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC05AP210', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC05AP211', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC05AP212', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC05AP213', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC05AP214', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC05SW164', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC05SW165', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC06AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC06AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC06AP104', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC06AP105', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC06AP106', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC06AP107', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC06AP108', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC06AP109', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC06AP110', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC06AP111', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC06AP112', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC06AP113', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC06SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC06SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC08AP067', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC08AP074', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC08AP075', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC08AP076', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC08AP077', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC08AP078', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC08AP079', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC08AP118', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC08AP119', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC08AP120', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC08AP121', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC08AP122', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC08AP123', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC08AP125', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC08AP126', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC08SW073', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC08SW124', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC09AP225', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC09AP226', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC09AP227', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC09AP228', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC09AP229', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC09AP230', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC09AP231', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC09AP232', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC09AP233', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC09AP234', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC09AP235', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC09AP236', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC09AP237', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC09AP238', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC09AP239', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC09AP240', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC09AP241', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC09AP242', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC09AP243', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC09AP244', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC09AP245', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC09AP246', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC09AP247', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC09SW166', '', '20', '0', '0', '1', '0'],
  ['UP', 'CLSGOLSC09SW167', '', '18', '0', '0', '1', '0'],
  ['UP', 'CLSGOLSC09SW168', '', '18', '0', '0', '1', '0'],
  ['UP', 'CLSGOLSC09SW169', '', '22', '0', '0', '1', '0'],
  ['UP', 'CLSGOLSC10AP141', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC10AP142', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC10AP143', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC10AP144', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC10AP145', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC10AP146', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC10AP147', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC10AP148', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC10AP149', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC10AP150', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC10AP151', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC10AP152', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC10AP153', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC10AP223', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC10AP224', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC10SW130', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGOLSC10SW131', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGON589RT042', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGON589RT126', '', '15', '1', '1', '0', '0'],
  ['UP', 'CLSGON630RT002', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGON630RT003', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSGON630SW004', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLSGON667AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON667AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON667AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON667AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON667AP104', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON667AP105', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON667AP106', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON667AP107', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON667AP108', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON667AP109', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON667AP110', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON667AP111', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON667AP112', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON667AP113', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON667AP114', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON667AP115', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON667RT002', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGON667RT003', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSGON667SW025', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSGON667SW116', '', '14', '1', '0', '0', '0'],
  ['UP', 'CLSGON667SW117', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGON667SW119', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGON698AP030', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON698AP031', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON698AP032', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON698AP033', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON698AP034', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON698AP035', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON698AP036', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON698AP037', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON698AP038', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON698AP039', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON698AP040', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON706AP005', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON706AP006', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON706AP007', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON706FW160', '', '15', '0', '0', '3', '0'],
  ['UP', 'CLSGON706RT002', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSGON706RT003', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLSGON706SW025', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGON706SW116', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGON706SW117', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGON706SW119', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGON706SW120', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGON747AP002', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON747AP003', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON747AP004', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON747FW155', '', '21', '1', '0', '8', '0'],
  ['UP', 'CLSGON747RT001', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLSGON747SW025', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLSGON747SW116', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSGON747SW117', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGON747SW119', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSGON747SW120', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGON747SW122', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGON747SW123', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGON755RT253', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGON755RT254', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGON914FW181', '', '13', '0', '0', '3', '0'],
  ['UP', 'CLSGON914RT125', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGON914RT126', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSGON926AP110', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON926AP111', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON926AP112', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON926AP113', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON926AP114', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON926AP115', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON926AP116', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON926AP117', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON926AP118', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON926AP119', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON926AP120', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON926AP121', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON926AP122', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON971AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON971AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON971AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON971FW128', '', '16', '1', '1', '0', '0'],
  ['UP', 'CLSGON971RT125', '', '23', '0', '0', '0', '0'],
  ['UP', 'CLSGON971RT126', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSGON971SW066', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSGON971SW067', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGON971SW068', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGON971SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGON971SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGON971SW071', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGON971SW072', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGON971VG130', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLSGON975AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON975AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON975AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON975AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON975RT125', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGON975RT126', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGON975SW066', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGON975SW067', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGON975SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGON975SW069', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGON975SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGON975SW071', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGON976AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON976AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON976AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON976AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON976FW130', '', '10', '1', '1', '1', '0'],
  ['UP', 'CLSGON976RT125', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSGON976RT126', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGON976SW066', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSGON976SW067', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGON976SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGON976SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGON976SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGON976SW071', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGON987AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON987AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON987AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON987AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGON987FW194', '', '13', '0', '0', '12', '0'],
  ['UP', 'CLSGON987RT125', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGON987SW066', '', '15', '1', '1', '0', '0'],
  ['UP', 'CLSGON987SW067', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGON987SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGON987SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGON987SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGON987SW071', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGONA594AP010', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA594AP011', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA594AP012', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA594AP013', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA594AP014', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA594AP015', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA594AP016', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA594AP017', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA594AP018', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA594AP019', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA594AP020', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA594FW080', '', '16', '1', '1', '0', '0'],
  ['UP', 'CLSGONA594RT002', '', '17', '1', '1', '0', '0'],
  ['UP', 'CLSGONA594RT003', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSGONA594SW116', '', '15', '1', '0', '0', '0'],
  ['UP', 'CLSGONA594SW117', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGONA594SW120', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGONA594SW123', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGONA601AP010', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA601AP011', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA601AP012', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA601AP013', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA601AP014', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA601AP015', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA601AP016', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA601AP017', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA601AP018', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA601AP019', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA601AP020', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA601AP021', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA601AP022', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA601AP023', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA601AP024', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA601AP025', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA601AP026', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA601AP027', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA601AP028', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA601AP029', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA601AP030', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA601AP031', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA601AP032', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA601FW127', '', '16', '1', '1', '0', '0'],
  ['UP', 'CLSGONA601RT002', '', '16', '0', '0', '3', '0'],
  ['UP', 'CLSGONA601RT003', '', '20', '0', '0', '0', '0'],
  ['UP', 'CLSGONA601SW116', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGONA601SW117', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGONA601SW119', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGONA601SW120', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGONA601SW122', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGONA601SW123', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGONA602AP030', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA602AP031', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA602AP032', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA602AP033', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA602AP034', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA602AP035', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA602AP037', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA602AP038', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA602AP039', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA602AP040', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA602AP041', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA602AP042', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA602AP043', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA602AP044', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA602AP045', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA602AP047', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA602RT125', '', '17', '0', '0', '2', '0'],
  ['UP', 'CLSGONA602RT126', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGONA602SW116', '', '15', '1', '0', '1', '0'],
  ['UP', 'CLSGONA602SW117', '', '10', '0', '0', '1', '0'],
  ['UP', 'CLSGONA602SW122', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGONA602SW190', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLSGONA602SW191', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGONA603AP050', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA603AP051', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA603AP052', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA603AP053', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA603AP054', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA603AP055', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA603AP056', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA603AP057', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA603AP058', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA603AP059', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA603AP060', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA603AP061', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA603AP062', '', '1', '0', '0', '0', '0'],
  ['DOWN', 'CLSGONA603AP063', '', '0', '0', '0', '1', '0'],
  ['UP', 'CLSGONA603AP064', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA603AP065', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA603FW082', '', '16', '1', '1', '0', '0'],
  ['UP', 'CLSGONA603RT002', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSGONA603RT003', '', '18', '0', '0', '1', '0'],
  ['UP', 'CLSGONA603SW025', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGONA603SW116', '', '15', '1', '0', '0', '0'],
  ['UP', 'CLSGONA603SW117', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGONA603SW119', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGONA603SW122', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGONA604AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA604AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA604AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA604AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA604AP104', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA604AP105', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA604AP106', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA604AP107', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA604AP108', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA604AP109', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA604AP110', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA604FW083', '', '15', '1', '1', '2', '0'],
  ['UP', 'CLSGONA604RT124', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSGONA604RT126', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSGONA604SW116', '', '16', '1', '0', '1', '0'],
  ['UP', 'CLSGONA604SW117', '', '16', '0', '0', '1', '0'],
  ['UP', 'CLSGONA604SW118', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGONA604SW119', '', '10', '0', '0', '0', '0'],
  ['UP', 'CLSGONA604SW120', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGONA604SW122', '', '11', '0', '0', '1', '0'],
  ['UP', 'CLSGONA604SW125', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGONA605AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA605AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA605AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA605AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA605AP104', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA605AP105', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA605AP106', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA605AP107', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA605AP108', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA605AP109', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA605AP110', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA605AP111', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA605AP112', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA605AP113', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA605FW084', '', '12', '1', '1', '0', '0'],
  ['UP', 'CLSGONA605RT125', '', '17', '0', '0', '2', '0'],
  ['UP', 'CLSGONA605RT126', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSGONA605SW025', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGONA605SW116', '', '16', '1', '0', '0', '0'],
  ['UP', 'CLSGONA605SW117', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLSGONA605SW118', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGONA605SW119', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGONA605SW120', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGONA605SW121', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGONA605SW122', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGONA606AP030', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA606AP031', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA606AP032', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA606AP033', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA606AP034', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA606AP035', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA606AP036', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA606AP037', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA606AP038', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA606AP039', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA606AP040', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA606AP041', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA606AP042', '', '1', '0', '0', '0', '0'],
  ['DOWN', 'CLSGONA606AP043', '', '0', '0', '0', '1', '0'],
  ['UP', 'CLSGONA606FW059', '', '17', '2', '1', '2', '0'],
  ['UP', 'CLSGONA606RT001', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGONA606SW025', '', '8', '2', '1', '0', '0'],
  ['UP', 'CLSGONA606SW116', '', '12', '2', '0', '0', '0'],
  ['UP', 'CLSGONA606SW117', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGONA606SW119', '', '10', '2', '0', '0', '0'],
  ['UP', 'CLSGONA606SW120', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGONA606SW122', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGONA608AP005', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA608AP006', 'flapping', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA608AP007', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA608AP008', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA608AP009', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA608AP010', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA608AP011', 'flapping', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA608AP012', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA608AP013', 'flapping', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA608AP014', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA608AP015', 'flapping', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA608AP016', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA608AP017', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA608AP018', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA608AP019', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA608FW085', '', '12', '1', '1', '0', '0'],
  ['UP', 'CLSGONA608RT002', '', '18', '0', '0', '1', '0'],
  ['UP', 'CLSGONA608RT003', '', '18', '0', '0', '1', '0'],
  ['UP', 'CLSGONA608SW025', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGONA608SW116', '', '13', '1', '0', '1', '0'],
  ['UP', 'CLSGONA608SW117', '', '10', '0', '0', '2', '0'],
  ['UP', 'CLSGONA608SW119', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGONA610AP003', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA610AP004', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA610AP005', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA610FW110', '', '19', '1', '1', '0', '0'],
  ['UP', 'CLSGONA610RT001', '', '16', '0', '0', '2', '0'],
  ['UP', 'CLSGONA610SW025', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGONA610SW116', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGONA610SW117', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGONA610SW119', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGONA610SW122', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGONA611AP005', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLSGONA611AP006', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLSGONA611AP008', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLSGONA611AP009', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLSGONA611AP010', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLSGONA611AP011', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLSGONA611AP012', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLSGONA611AP013', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLSGONA611AP014', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLSGONA611AP015', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLSGONA611AP016', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLSGONA611AP017', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLSGONA611AP018', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLSGONA611AP019', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLSGONA611AP020', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLSGONA611AP021', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLSGONA611AP022', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLSGONA611FW004', '', '18', '2', '1', '0', '0'],
  ['UP', 'CLSGONA611RT001', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGONA611SW025', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGONA611SW116', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGONA611SW117', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGONA611SW119', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGONA612AP010', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA612AP011', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA612AP012', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA612AP013', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA612AP014', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA612AP015', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA612AP016', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA612AP017', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA612AP018', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA612AP019', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA612AP020', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA612FW087', '', '18', '1', '1', '0', '0'],
  ['UP', 'CLSGONA612RT002', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGONA612RT003', '', '16', '0', '0', '3', '0'],
  ['UP', 'CLSGONA612SW025', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGONA612SW116', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGONA612SW117', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGONA612SW120', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGONA612SW123', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGONA612SW124', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGONA625SW025', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGONA625SW119', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGONA641AP141', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641AP142', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641AP143', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641AP144', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641AP145', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641AP146', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641AP147', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641AP148', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641AP150', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641AP151', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641AP152', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641AP159', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641AP160', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641AP161', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641AP162', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641AP163', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641AP164', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641AP165', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641AP166', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641AP167', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641AP168', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641AP169', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641AP170', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641AP171', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641AP172', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641AP173', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641AP174', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641AP175', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641AP176', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641AP177', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641AP178', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641AP179', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641AP180', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641AP181', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641AP182', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641AP183', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641AP184', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641AP185', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641AP186', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641AP187', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641RT001', '', '116', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641RT013', '', '20', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641RT017', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641SW034', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641SW035', '', '20', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641SW036', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641SW038', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641SW039', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641SW040', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGONA641SW041', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSGONA651AP090', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA651AP091', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA651AP092', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA651AP093', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA651AP094', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA651AP095', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA651AP096', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA651AP097', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA651AP098', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA651AP099', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA651RT125', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGONA651RT126', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSGONA651SW066', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLSGONA651SW067', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGONA651SW068', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGONA651SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGONA651SW070', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGONA651SW071', '', '10', '0', '0', '0', '0'],
  ['UP', 'CLSGONA654AP140', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA654AP141', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA654AP142', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA654AP143', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA654AP144', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA654AP145', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA654AP146', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA654AP147', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA654AP148', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA654AP149', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA654FW091', '', '14', '1', '1', '0', '0'],
  ['UP', 'CLSGONA654RT130', '', '12', '0', '0', '1', '0'],
  ['UP', 'CLSGONA654RT253', '', '22', '0', '0', '0', '0'],
  ['UP', 'CLSGONA654RT254', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGONA654SW130', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSGONA654SW131', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGONA654SW132', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGONA654SW133', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGONA654SW134', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGONA654SW135', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGONA654SW136', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGONA654VG131', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLSGONA672AP156', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA672AP157', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA672AP158', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA672AP159', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA672AP160', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA672AP161', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA672AP162', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA672AP163', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA672AP164', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA672AP165', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA672AP166', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA672AP167', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA672AP168', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA672AP169', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA672AP170', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA672AP171', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA672AP172', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA672AP173', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA672FW182', '', '23', '2', '0', '5', '0'],
  ['UP', 'CLSGONA672RT129', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGONA672SW140', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLSGONA672SW141', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLSGONA672SW143', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLSGONA672SW144', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLSGONA672SW145', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLSGONA692AP020', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA692AP021', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA692AP022', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA692AP023', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA692AP024', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA692AP025', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA692AP026', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA692AP027', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA692AP028', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA692AP029', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA692FW093', '', '14', '1', '1', '0', '0'],
  ['UP', 'CLSGONA692RT002', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSGONA692RT003', '', '15', '0', '0', '1', '0'],
  ['UP', 'CLSGONA692SW116', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGONA692SW117', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGONA692SW120', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGONA692SW121', '', '14', '1', '0', '0', '0'],
  ['UP', 'CLSGONA702AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA702AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA702AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA702AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA702AP104', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA702AP105', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA702AP106', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA702AP107', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA702AP108', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA702AP109', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA702AP110', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA702AP111', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA702AP112', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA702FW096', '', '16', '1', '1', '0', '0'],
  ['UP', 'CLSGONA702RT002', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSGONA702RT003', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGONA702SW025', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGONA702SW116', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGONA702SW117', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGONA702SW119', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGONA702SW120', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGONA703AP002', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA703AP003', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA703AP004', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA703AP005', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA703AP006', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA703AP007', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA703AP008', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA703AP009', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA703AP010', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA703AP011', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA703AP012', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA703AP013', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA703AP014', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA703AP015', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA703AP016', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA703FW070', '', '47', '0', '0', '8', '0'],
  ['UP', 'CLSGONA703RT049', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGONA703SW034', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLSGONA703SW035', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLSGONA703SW036', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLSGONA703SW037', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLSGONA703SW038', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLSGONA703SW039', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLSGONA725SW051', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGONA725SW052', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGONA725SW053', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGONA725SW054', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGONA725SW055', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGONA725SW056', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGONA725SW057', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGONA725SW058', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSGONA725SW059', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSGONA725SW060', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSGONA725SW065', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGONA725SW066', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGONA725SW067', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGONA725SW068', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGONA771AP010', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA771AP011', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA771AP012', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA771AP013', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA771AP014', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA771AP015', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA771AP016', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA771AP017', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA771AP018', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA771AP019', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA771AP020', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA771AP021', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA771AP022', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA771AP023', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGONA771FW164', '', '15', '0', '0', '3', '0'],
  ['UP', 'CLSGONA771RT002', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSGONA771RT003', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLSGONA771SW116', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSGONA771SW117', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGONA771SW118', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGONA771SW119', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGONA771SW122', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLSGONA771SW123', '', '10', '0', '0', '2', '0'],
  ['UP', 'CLSGONA771SW124', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOO486AP215', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOO486AP216', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOO486AP217', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOO486RT209', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSGOO486SW210', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGOO486SW211', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOO487AP215', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOO487AP216', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOO487AP217', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOO487FW006', '', '20', '0', '0', '8', '0'],
  ['UP', 'CLSGOO487RT209', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSGOO487SW210', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGOO487SW211', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOO496AP210', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOO496AP211', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOO496AP212', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOO496FW164', '', '38', '1', '0', '5', '0'],
  ['UP', 'CLSGOO496RT193', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGOO496SW226', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLSGOO496SW227', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLSGOO498AP210', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOO498AP211', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOO498AP212', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOO498FW172', '', '36', '2', '0', '5', '0'],
  ['UP', 'CLSGOO498RT193', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGOO498SW226', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLSGOO498SW227', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLSGOO714AP210', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOO714AP211', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOO714AP212', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOO714FW113', '', '35', '1', '0', '5', '0'],
  ['UP', 'CLSGOO714RT194', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGOO714SW226', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLSGOO714SW227', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLSGOO716AP210', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOO716AP211', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOO716FW106', '', '38', '2', '0', '5', '0'],
  ['UP', 'CLSGOO716RT193', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSGOO716SW226', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLSGOO716SW227', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP083', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP084', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP085', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP087', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP088', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP089', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP090', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP095', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP099', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP104', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP105', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP106', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP107', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP108', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP109', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP110', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP111', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP112', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP113', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP136', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP137', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP138', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP139', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP140', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP141', '', '1', '0', '0', '0', '0'],
  ['DOWN', 'CLSGOPA502AP142', '', '0', '0', '0', '1', '0'],
  ['UP', 'CLSGOPA502AP143', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP144', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP145', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP146', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP147', '', '1', '0', '0', '0', '0'],
  ['DOWN', 'CLSGOPA502AP148', '', '0', '0', '0', '1', '0'],
  ['UP', 'CLSGOPA502AP149', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP150', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP151', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP152', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP153', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP154', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP155', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP158', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP159', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP160', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP161', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP162', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP163', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP164', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP165', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP166', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP167', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP168', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP169', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP170', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP172', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP173', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP174', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP175', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP176', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP177', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502AP185', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502FW186', '', '31', '0', '0', '9', '0'],
  ['UP', 'CLSGOPA502RT002', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502SW004', '', '9', '1', '1', '0', '0'],
  ['UP', 'CLSGOPA502SW021', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502SW022', '', '13', '1', '1', '0', '0'],
  ['UP', 'CLSGOPA502SW023', '', '9', '1', '1', '0', '0'],
  ['UP', 'CLSGOPA502SW024', '', '11', '1', '1', '0', '0'],
  ['UP', 'CLSGOPA502SW025', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502SW026', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502SW027', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502SW029', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502SW030', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502SW031', '', '11', '1', '1', '0', '0'],
  ['UP', 'CLSGOPA502SW032', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502SW033', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502SW034', '', '10', '1', '1', '0', '0'],
  ['UP', 'CLSGOPA502SW035', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502SW036', '', '10', '1', '1', '0', '0'],
  ['UP', 'CLSGOPA502SW038', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502SW039', '', '9', '1', '2', '0', '0'],
  ['UP', 'CLSGOPA502SW050', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502SW051', '', '9', '1', '2', '0', '0'],
  ['UP', 'CLSGOPA502SW052', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502SW178', '', '14', '1', '1', '0', '0'],
  ['UP', 'CLSGOPA502SW179', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502SW181', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA502SW247', '', '12', '1', '1', '0', '0'],
  ['UP', 'CLSGOPA502SW248', '', '14', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA502SW249', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA502SW250', '', '12', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA503AP070', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503AP140', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503AP141', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503AP142', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503AP144', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503AP145', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503AP146', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503AP147', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503AP148', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503AP149', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503AP150', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503AP151', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503AP152', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503AP153', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503AP154', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503AP155', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503AP156', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503AP157', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503AP158', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503AP159', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503AP160', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503AP161', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503AP162', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503AP163', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503AP172', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503AP173', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503AP174', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503AP175', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503AP180', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503AP181', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503AP182', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503AP183', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503AP184', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503AP185', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503AP186', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503AP187', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503AP188', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503AP189', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503AP190', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503AP191', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503AP192', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503FW014', '', '25', '1', '0', '8', '0'],
  ['UP', 'CLSGOPA503RT002', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503SW004', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA503SW017', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503SW018', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA503SW056', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA503SW057', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA503SW058', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503SW059', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503SW060', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA503SW061', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA503SW062', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503SW063', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA503SW064', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503SW065', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA503SW066', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503SW164', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503SW165', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503SW251', '', '26', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA503SW252', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP098', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP099', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP104', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP105', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP106', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP107', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP108', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP109', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP110', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP111', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP112', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP113', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP114', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP115', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP140', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP141', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP142', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP143', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP144', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP145', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP146', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP147', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP148', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP149', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP150', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP151', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP152', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP153', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP154', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP155', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP156', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP157', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP158', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP159', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP160', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP161', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP162', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP163', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP164', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP165', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP166', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP167', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP168', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP169', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP173', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP205', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510AP206', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510FW197', '', '21', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA510RT001', '', '9', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510RT002', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510RT003', '', '22', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510SW004', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA510SW017', '', '15', '0', '0', '1', '0'],
  ['UP', 'CLSGOPA510SW018', '', '13', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA510SW052', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510SW054', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA510SW055', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510SW056', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510SW057', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510SW058', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA510SW059', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA510SW060', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA510SW061', '', '12', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA510SW062', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA510SW063', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA510SW064', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA510SW065', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA510SW066', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510SW067', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA510SW068', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510SW072', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA510SW074', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510SW075', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510SW170', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510SW171', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510SW172', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510SW251', '', '22', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA510SW252', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP002', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP003', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP004', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP005', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP006', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP007', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP008', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP009', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP010', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP011', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP012', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP013', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP014', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP015', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP016', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP017', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP018', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP019', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP020', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP021', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP022', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP023', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP024', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP025', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP026', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP027', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP028', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP029', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP030', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP031', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP032', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP033', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP034', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP035', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP036', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP037', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP038', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP039', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP040', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP041', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP042', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP043', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP044', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP045', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP046', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP047', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP048', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP049', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511AP050', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511FW196', '', '72', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA511SW130', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511SW131', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511SW132', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511SW133', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511SW134', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511SW135', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511SW136', '', '5', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511SW137', '', '5', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511SW140', '', '32', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511SW141', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511SW142', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511SW143', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511SW144', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511SW145', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511SW146', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511SW147', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511SW148', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511SW149', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511SW150', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511SW151', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511SW152', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511SW154', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511SW155', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511SW156', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511SW157', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511SW158', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511SW159', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511SW161', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511SW162', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511SW164', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511SW166', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511SW167', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA511SW168', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518AP150', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518AP151', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518AP152', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518AP153', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518AP154', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518AP155', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518AP156', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518AP157', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518AP158', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518AP159', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518AP160', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518AP161', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518AP162', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518AP163', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518AP164', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518AP165', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518AP166', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518AP167', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518AP168', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518AP169', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518AP170', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518AP171', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518AP172', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518AP173', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518AP174', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518AP175', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518FW134', '', '17', '0', '0', '3', '0'],
  ['UP', 'CLSGOPA518RT253', '', '20', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518RT254', '', '21', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518SW004', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518SW130', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518SW131', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518SW132', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518SW133', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518SW134', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518SW135', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518SW136', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518SW137', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518SW138', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518SW139', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518SW140', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518SW141', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA518SW142', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP150', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP151', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP152', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP153', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP154', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP155', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP156', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP157', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP158', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP159', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP160', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP161', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP162', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP163', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP164', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP165', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP166', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP167', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP168', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP169', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP170', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP171', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP172', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP173', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP174', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP175', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP176', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP177', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP178', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP179', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP180', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP181', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP182', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP183', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP184', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP185', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP186', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP187', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP188', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP189', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP190', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP191', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP192', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP193', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP194', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP195', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP196', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP197', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP198', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP199', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP200', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP201', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP202', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP203', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563AP204', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563FW136', '', '17', '0', '0', '2', '0'],
  ['UP', 'CLSGOPA563RT002', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563RT003', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563SW004', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563SW017', '', '19', '0', '1', '0', '0'],
  ['UP', 'CLSGOPA563SW018', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563SW050', '', '12', '0', '0', '1', '0'],
  ['UP', 'CLSGOPA563SW051', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563SW052', '', '10', '1', '1', '0', '0'],
  ['UP', 'CLSGOPA563SW053', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563SW054', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563SW055', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563SW057', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563SW058', '', '17', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA563SW059', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA563SW060', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563SW061', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563SW062', '', '2', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA563SW063', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563SW075', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563SW085', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563SW091', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563SW093', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563SW094', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563SW095', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563SW096', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563SW097', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA563SW098', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP110', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP111', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP112', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP113', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP114', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP115', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP116', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP117', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP118', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP119', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP120', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP121', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP122', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP123', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP124', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP125', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP126', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP127', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP128', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP129', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP130', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP210', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP211', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP212', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP213', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP214', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP215', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP216', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP217', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP218', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP219', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP220', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP221', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP222', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP223', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP224', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP225', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP226', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP227', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP229', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP230', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP231', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP232', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP233', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP234', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP235', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP236', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP237', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP238', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP239', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP240', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP241', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP242', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP243', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP244', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP245', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564AP247', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564FW015', '', '33', '1', '0', '5', '0'],
  ['UP', 'CLSGOPA564RT001', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564SW004', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA564SW017', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564SW018', '', '22', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564SW047', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA564SW048', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA564SW049', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA564SW050', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA564SW051', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA564SW052', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA564SW053', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA564SW054', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA564SW055', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA564SW056', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA564SW057', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA564SW058', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564SW059', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA564SW060', '', '12', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA564SW061', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA564SW062', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA564SW063', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA564SW064', '', '14', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA564SW068', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564SW099', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA564SW200', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564SW201', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564SW202', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564SW251', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA564SW252', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP069', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP071', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP073', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP076', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP079', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP080', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP081', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP140', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP141', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP142', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP143', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP144', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP145', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP147', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP148', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP150', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP151', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP152', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP153', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP154', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP155', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP156', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP157', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP158', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP159', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP160', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP161', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP162', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP163', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP164', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP165', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP166', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP167', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP168', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP169', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP170', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP171', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP172', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP173', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP174', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP175', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP180', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP181', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP182', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP183', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP184', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP185', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP186', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP187', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP188', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP189', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP190', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP191', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP192', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP193', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567AP194', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567FW008', '', '32', '2', '0', '5', '0'],
  ['UP', 'CLSGOPA567RT001', '', '21', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567SW004', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567SW017', '', '16', '0', '0', '1', '0'],
  ['UP', 'CLSGOPA567SW018', '', '14', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA567SW048', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567SW049', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA567SW050', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567SW051', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567SW052', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567SW053', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567SW055', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567SW056', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA567SW057', '', '12', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA567SW058', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA567SW059', '', '12', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA567SW060', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA567SW061', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567SW063', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567SW064', '', '9', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA567SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567SW077', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA567SW176', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567SW177', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567SW178', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567SW251', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA567SW252', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA571SW004', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA575AP002', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575AP003', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575AP004', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575AP005', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575AP006', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575AP007', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575AP008', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575AP009', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575AP010', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575AP011', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575AP012', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575AP013', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575AP014', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575AP015', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575AP016', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575AP017', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575AP018', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575AP019', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575AP020', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575AP021', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575AP022', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575AP023', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575AP024', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575AP025', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575AP026', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575AP027', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575AP028', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575AP070', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575AP073', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575AP074', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575AP076', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575AP077', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575AP078', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575AP085', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575AP086', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575AP087', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575AP088', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575AP097', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575FW018', '', '23', '0', '0', '8', '0'],
  ['UP', 'CLSGOPA575FW196', '', '54', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575RT002', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575SW004', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575SW017', '', '19', '0', '0', '1', '0'],
  ['UP', 'CLSGOPA575SW018', '', '17', '0', '0', '1', '0'],
  ['UP', 'CLSGOPA575SW052', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575SW053', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575SW054', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575SW055', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575SW056', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575SW057', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575SW058', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575SW059', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575SW060', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA575SW061', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA575SW062', '', '12', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA575SW063', '', '9', '1', '0', '1', '0'],
  ['UP', 'CLSGOPA575SW064', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575SW065', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575SW066', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575SW130', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575SW131', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575SW132', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575SW133', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA575SW134', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA588AP099', '', '1', '0', '0', '0', '0'],
  ['DOWN', 'CLSGOPA588RT003', '', '12', '0', '0', '5', '0'],
  ['UP', 'CLSGOPA588SW224', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA588SW225', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP070', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP071', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP072', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP073', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP074', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP075', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP076', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP077', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP078', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP079', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP080', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP081', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP082', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP083', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP084', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP085', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP086', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP087', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP088', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP089', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP090', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP091', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP092', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP093', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP094', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP095', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP096', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP097', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP098', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP099', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP104', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP105', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP106', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP107', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP108', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP109', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP110', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP111', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP112', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600AP113', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600FW138', '', '1', '0', '0', '0', '0'],
  ['DOWN', 'CLSGOPA600FW235', '', '0', '0', '0', '1', '0'],
  ['UP', 'CLSGOPA600RT002', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600RT003', '', '12', '0', '0', '2', '0'],
  ['UP', 'CLSGOPA600RT004', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600SW004', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA600SW018', '', '12', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA600SW053', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600SW054', '', '10', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600SW056', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600SW057', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600SW058', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600SW059', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA600SW060', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600SW061', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA600SW062', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600SW063', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600SW064', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA600SW065', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA600SW066', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600SW067', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600SW251', '', '21', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA600SW252', '', '20', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA627AP150', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA627AP151', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA627AP152', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA627AP153', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA627AP154', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA627AP155', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA627AP156', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA627AP157', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA627AP158', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA627AP159', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA627AP160', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA627AP161', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA627AP162', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA627AP163', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA627AP164', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA627AP165', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA627AP166', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA627AP167', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA627AP168', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA627AP169', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA627AP170', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA627AP171', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA627AP172', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA627FW020', '', '18', '1', '0', '8', '0'],
  ['UP', 'CLSGOPA627RT002', '', '16', '0', '0', '1', '0'],
  ['UP', 'CLSGOPA627SW004', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA627SW017', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA627SW018', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA627SW054', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA627SW055', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA627SW056', '', '10', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA627SW057', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA627SW058', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA627SW059', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA627SW060', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA627SW061', '', '10', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA627SW062', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA627SW063', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA627SW064', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA627SW065', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628AP104', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628AP105', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628AP106', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628AP107', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628AP108', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628AP109', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628AP110', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628AP111', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628AP112', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628AP113', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628AP114', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628AP115', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628AP116', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628AP117', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628AP118', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628AP119', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628AP120', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628AP121', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628AP122', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628AP123', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628AP124', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628AP125', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628AP126', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628AP127', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628AP128', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628AP129', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628AP130', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628AP131', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628AP132', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628AP133', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628AP134', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628AP135', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628FW021', '', '23', '3', '0', '5', '0'],
  ['UP', 'CLSGOPA628RT002', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628SW004', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA628SW053', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA628SW054', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628SW055', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628SW056', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628SW057', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628SW058', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA628SW059', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA628SW060', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA628SW061', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628SW062', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA628SW063', '', '12', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA628SW064', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA628SW065', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA628SW066', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628SW067', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628SW251', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA628SW252', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920AP160', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920AP161', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920AP162', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920AP163', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920AP164', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920AP165', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920AP166', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920AP167', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920AP168', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920AP169', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920AP170', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920AP171', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920AP172', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920AP173', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920AP174', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920AP175', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920AP176', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920AP177', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920AP178', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920AP179', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920AP180', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920AP181', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920AP182', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920AP183', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920AP184', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920AP185', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920FW139', '', '15', '0', '0', '3', '0'],
  ['UP', 'CLSGOPA920RT253', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920RT254', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920SW130', '', '18', '0', '0', '1', '0'],
  ['UP', 'CLSGOPA920SW131', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920SW132', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920SW133', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920SW134', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920SW135', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920SW136', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920SW137', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920SW138', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920SW139', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920SW140', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920SW141', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920SW142', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920SW143', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920SW144', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920SW145', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA920SW150', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA979AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA979AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA979AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA979AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA979AP104', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA979AP105', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA979AP106', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA979AP107', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA979AP108', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA979AP109', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA979AP110', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA979AP111', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA979AP112', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA979AP114', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA979AP115', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA979AP116', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA979AP117', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA979AP118', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA979AP119', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA979AP120', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA979AP121', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA979AP122', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA979AP123', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA979RT001', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA979RT002', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA979SW002', '', '52', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA979SW050', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA979SW051', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA979SW052', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA979SW053', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA979SW054', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA979SW055', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA979SW056', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA979SW057', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA979SW058', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA979SW059', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA979SW060', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA979SW061', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA979SW062', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA979SW063', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA979SW064', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989AP180', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989AP181', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989AP182', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989AP183', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989AP184', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989AP185', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989AP186', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989AP187', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989AP188', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989AP189', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989AP191', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989AP192', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989AP193', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989AP194', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989AP195', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989AP196', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989AP197', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989AP198', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989AP199', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989AP200', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989AP201', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989AP202', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989AP203', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989AP204', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989AP205', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989AP210', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989AP211', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989AP212', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989AP213', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989AP214', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989AP215', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989AP216', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989AP217', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989FW141', '', '22', '1', '0', '8', '0'],
  ['UP', 'CLSGOPA989SW131', '', '35', '1', '0', '0', '0'],
  ['UP', 'CLSGOPA989SW132', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989SW133', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989SW134', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989SW135', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989SW136', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989SW137', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989SW138', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989SW139', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989SW140', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989SW141', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989SW142', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989SW143', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989SW144', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989SW145', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989SW146', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGOPA989SW147', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK02AP005', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK02AP006', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK02AP007', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK02AP008', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK02AP009', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK02AP010', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK02AP011', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK02AP012', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK02AP013', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK02AP014', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK02AP015', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK02AP016', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK02AP017', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK02AP018', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK02SW003', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK02SW04', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK02SW022', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK04AP037', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK04AP038', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK04AP053', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK04AP055', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK04AP056', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK04AP059', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK04AP061', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK04AP063', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK04AP064', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK04AP068', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK04SW021', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK04SW028', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK06AP054', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK06AP057', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK06AP060', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK06AP062', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK06AP065', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK06AP066', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK06AP067', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK06AP214', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK06AP215', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK06RT253', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK06RT254', '', '14', '1', '1', '0', '0'],
  ['UP', 'CLSGOPMK06SW002', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK06SW019', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK06SW020', '', '25', '1', '0', '1', '0'],
  ['UP', 'CLSGOPMK06SW023', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK06SW024', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK08AP039', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK08AP051', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK08AP052', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK08AP069', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK08AP076', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK08AP077', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK08AP078', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK08AP079', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK08AP080', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK08AP081', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK08SW027', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK08SW033', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK10AP040', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK10AP058', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK10AP070', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK10AP071', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK10AP072', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK10AP073', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK10AP074', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK10AP075', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK10AP213', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK10SW024', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK10SW029', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11AP173', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11AP174', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11AP175', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11AP176', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11AP177', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11AP178', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11AP179', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11AP180', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11AP181', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11AP182', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11AP183', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11AP184', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11AP185', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11AP186', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11AP188', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11AP189', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11AP190', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11AP191', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11AP192', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11AP193', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11AP194', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11AP195', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11AP196', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11AP197', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11AP198', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11AP199', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11AP200', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11AP202', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11AP203', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11AP204', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11AP205', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11AP206', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11AP207', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11AP208', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11AP209', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11AP212', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11SW170', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11SW171', '', '12', '0', '0', '2', '0'],
  ['UP', 'CLSGOPMK11SW172', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11SW220', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11SW221', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11SW222', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSGOPMK11SW223', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOPML08AP034', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPML08AP035', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPML08AP082', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPML08AP083', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPML08AP084', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPML08AP085', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPML08AP086', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPML08AP091', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPML08AP092', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPML08AP099', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPML08AP104', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPML08SW026', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSGOPML10AP094', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPML10AP095', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPML10AP096', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPML10AP097', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPML10AP098', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPML10AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPML10AP105', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPML10AP107', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPML10AP231', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPML10SW030', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOPML10SW224', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOPML12AP036', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPML12AP087', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPML12AP088', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPML12AP089', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPML12AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPML12AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPML12AP106', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPML12AP210', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOPML12SW025', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOU005RT049', '', '16', '1', '0', '0', '0'],
  ['UP', 'CLSGOU005SW050', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLSGOU008RT241', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGOU008SW242', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLSGOU025RT049', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGOU025SW050', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLSGOU181RT113', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGOU181SW114', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLSGOU182RT049', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGOU182SW050', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLSGOU184RT177', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGOU184SW178', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLSGOU187RT113', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLSGOU187SW114', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLSGOU188RT177', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGOU188SW178', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLSGOX513AP031', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX513AP032', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX513AP033', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX513AP034', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX513AP035', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX513AP036', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX513AP040', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX513AP041', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX513AP042', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX513AP043', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX513AP044', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX513AP045', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX513FW114', '', '21', '1', '1', '0', '0'],
  ['UP', 'CLSGOX513RT001', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGOX513SW002', '', '21', '0', '0', '0', '0'],
  ['UP', 'CLSGOX513SW003', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOX513SW004', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOX513SW005', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOX513SW006', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOX513SW007', '', '20', '0', '0', '0', '0'],
  ['UP', 'CLSGOX513SW008', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581AP104', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581AP105', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581AP106', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581AP109', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581AP110', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581AP111', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581AP112', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581AP113', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581AP115', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581AP116', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581AP117', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581AP118', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581AP119', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581AP120', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581AP121', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581AP122', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581AP123', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581AP124', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581AP125', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581AP152', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581AP157', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581AP159', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581AP161', '', '1', '0', '0', '0', '0'],
  ['DOWN', 'CLSGOX581AP162', '', '0', '0', '0', '1', '0'],
  ['UP', 'CLSGOX581AP163', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581AP164', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581RT002', '', '12', '1', '1', '0', '0'],
  ['UP', 'CLSGOX581RT003', '', '13', '1', '0', '0', '0'],
  ['UP', 'CLSGOX581SW011', '', '22', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581SW012', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581SW013', '', '13', '0', '0', '1', '0'],
  ['UP', 'CLSGOX581SW014', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581SW015', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581SW016', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581SW017', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581SW018', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581SW019', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581SW020', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581SW021', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581SW022', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581SW023', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581SW024', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581SW025', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581SW026', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581SW027', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOX581SW028', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOX939AP080', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX939AP081', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX939AP082', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX939AP083', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX939AP084', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX939AP085', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX939AP086', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX939AP087', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX939AP088', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX939AP089', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX939AP090', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX939AP091', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX939AP092', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX939AP093', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX939AP094', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX939AP095', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX939AP096', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX939AP097', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX939AP098', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX939AP099', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX939AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX939AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX939AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX939FW108', '', '23', '0', '0', '8', '0'],
  ['UP', 'CLSGOX939RT065', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGOX939SW066', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSGOX939SW067', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGOX939SW068', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGOX939SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOX939SW070', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOX939SW071', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOX939SW072', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGOX939SW073', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLSGOX966AP110', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX966AP111', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX966AP112', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX966AP113', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX966AP114', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX966AP115', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX966AP116', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX966AP117', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX966AP118', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX966AP119', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX966AP120', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLSGOX966FW198', '', '16', '3', '0', '8', '0'],
  ['UP', 'CLSGOX966RT065', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLSGOX966SW066', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLSGOX966SW067', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLSGOX966SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOX966SW069', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLSGOX966SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOX966SW071', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLSGOX966SW072', '', '11', '1', '0', '0', '0'],
  ['UP', 'CLSGOX966SW073', '', '10', '0', '0', '0', '0'],
  ['UP', 'CLTALN741AP140', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLTALN741AP141', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLTALN741AP142', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLTALN741AP143', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLTALN741AP144', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLTALN741AP145', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLTALN741FW162', '', '15', '0', '0', '3', '0'],
  ['UP', 'CLTALN741RT189', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLTALN741RT190', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLTALN741SW130', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLTALN741SW131', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLTALN741SW132', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLTALN741SW133', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLTALN741SW134', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLTALN741SW135', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLTALU015RT113', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLTALU015SW114', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLTARN590AP107', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLTARN590AP108', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLTARN590AP109', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLTARN590FW049', '', '18', '1', '0', '8', '0'],
  ['UP', 'CLTARN590SW077', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLTARN590SW078', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLTARN590SW079', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLTARN590SW080', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLTARN590SW081', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLTARN590SW082', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLTARN590SW083', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLTARN590SW084', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLTARN590SW090', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLTARN811AP201', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLTARN811AP202', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLTARN811AP203', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLTARN811AP204', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLTARN811FW165', '', '12', '1', '0', '3', '0'],
  ['UP', 'CLTARN811RT253', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLTARN811RT254', '', '16', '1', '0', '0', '0'],
  ['UP', 'CLTARN811SW130', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLTARN811SW131', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLTARN811SW132', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLTARN811SW133', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLTARN811SW134', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLTARN811SW135', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLTARN811SW136', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLTARN811VG131', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLTARPA590AP200', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLTARPA590AP201', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLTARPA590AP202', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLTARPA590AP203', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLTARPA590AP204', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLTARPA590AP205', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLTARPA590AP206', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLTARPA590AP207', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLTARPA590AP208', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLTARPA590AP209', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLTARPA590AP210', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLTARPA590AP211', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLTARPA590AP212', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLTARPA590AP213', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLTARPA590AP214', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLTARPA590AP215', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLTARPA590AP216', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLTARPA590AP217', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLTARPA590AP218', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLTARPA590AP219', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLTARPA590AP220', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLTARPA590RT001', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLTARPA590SW004', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLTARPA590SW017', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLTARPA590SW018', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLTARPA590SW064', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLTARPA590SW067', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLTARPA590SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLTARPA590SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLTARPA590SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLTARPA590SW071', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLTARPA590SW072', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLTARPA590SW073', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLTARPA590SW074', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLTARPA590SW075', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLTARPA590SW076', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLTEMU002RT113', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLTEMU002SW114', '', '6', '0', '0', '0', '0'],
  ['UP', 'CLVALN536AP010', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLVALN536AP011', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLVALN536AP012', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLVALN536AP013', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLVALN536AP014', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLVALN536AP015', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLVALN536AP016', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLVALN536AP017', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLVALN536AP018', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLVALN536AP019', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLVALN536AP020', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLVALN536FW035', '', '19', '2', '1', '0', '0'],
  ['UP', 'CLVALN536RT001', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLVALN536SW025', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN536SW116', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLVALN536SW117', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN536SW146', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN536SW147', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLVALN537AP006', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN537AP007', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN537AP008', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN537AP009', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN537AP010', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN537AP011', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN537AP012', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN537AP013', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN537AP014', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN537AP015', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN537AP016', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN537AP017', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN537AP019', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN537AP020', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN537FW036', '', '16', '1', '1', '0', '0'],
  ['UP', 'CLVALN537RT001', '', '16', '1', '1', '0', '0'],
  ['UP', 'CLVALN537SW025', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLVALN537SW116', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN537SW117', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN537SW118', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLVALN537SW119', '', '11', '0', '0', '0', '0'],
  ['DOWN', 'CLVALN538AP005', '', '0', '0', '0', '1', '0'],
  ['UP', 'CLVALN538AP006', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN538AP007', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN538AP008', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN538AP009', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN538AP010', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN538AP011', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN538AP012', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN538AP013', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN538AP014', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN538AP015', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN538AP016', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN538AP017', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN538AP018', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN538AP019', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN538AP020', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN538FW136', '', '11', '0', '0', '12', '0'],
  ['UP', 'CLVALN538RT001', '', '16', '1', '1', '0', '0'],
  ['UP', 'CLVALN538SW025', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN538SW116', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLVALN538SW117', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLVALN538SW120', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALN539AP003', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN539AP004', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN539AP005', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN539AP006', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN539AP007', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN539FW037', '', '15', '1', '1', '0', '0'],
  ['UP', 'CLVALN539RT001', '', '17', '1', '1', '0', '0'],
  ['UP', 'CLVALN539SW120', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALN539SW121', '', '21', '0', '0', '0', '0'],
  ['UP', 'CLVALN539SW122', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLVALN539SW123', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLVALN539SW124', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLVALN539SW125', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN540AP130', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN540AP131', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN540FW038', '', '19', '1', '1', '0', '0'],
  ['UP', 'CLVALN540RT001', '', '16', '1', '1', '0', '0'],
  ['UP', 'CLVALN540SW145', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN540SW146', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALN540SW147', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLVALN540SW148', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN540SW149', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN541AP010', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN541AP011', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN541AP012', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN541AP013', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN541AP014', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN541AP015', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN541AP016', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN541AP017', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN541AP018', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN541AP019', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN541AP020', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN541AP021', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN541AP022', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN541AP023', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN541AP024', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN541AP025', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN541AP026', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN541FW039', '', '19', '2', '1', '0', '0'],
  ['UP', 'CLVALN541RT001', '', '16', '1', '1', '0', '0'],
  ['UP', 'CLVALN541SW116', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLVALN541SW117', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN541SW119', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLVALN541SW120', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN541SW122', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALN543AP035', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN543AP045', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN543AP046', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN543AP047', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN543AP048', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN543AP049', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN543FW041', '', '18', '2', '1', '1', '0'],
  ['UP', 'CLVALN543RT001', '', '16', '1', '1', '0', '0'],
  ['UP', 'CLVALN543SW116', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLVALN543SW117', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN543SW119', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLVALN544AP004', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN544AP005', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN544AP130', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN544FW042', '', '18', '1', '1', '1', '0'],
  ['UP', 'CLVALN544RT001', '', '16', '1', '1', '0', '0'],
  ['UP', 'CLVALN544SW025', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALN544SW116', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLVALN544SW119', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN545AP006', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN545AP007', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN545AP008', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN545AP009', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN545AP010', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN545AP011', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN545AP012', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN545AP013', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN545AP014', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN545AP015', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN545AP016', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN545AP017', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN545AP018', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN545AP019', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN545AP020', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN545AP021', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN545FW219', '', '18', '2', '1', '1', '0'],
  ['UP', 'CLVALN545RT001', '', '16', '1', '1', '0', '0'],
  ['UP', 'CLVALN545SW025', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN545SW116', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLVALN545SW117', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLVALN545SW119', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALN545SW120', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALN545SW121', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN548AP060', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN548AP061', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN548AP062', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN548AP063', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN548AP064', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN548AP065', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN548AP066', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN548AP067', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN548AP068', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN548AP069', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN548AP070', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN548AP071', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN548AP072', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN548AP073', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN548AP074', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN548AP075', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN548AP076', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN548AP077', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN548AP078', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN548FW242', '', '16', '1', '0', '8', '0'],
  ['UP', 'CLVALN548RT001', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLVALN548SW025', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN548SW116', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLVALN548SW117', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALN548SW118', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLVALN548SW119', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN548SW120', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALN552AP030', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN552AP031', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN552AP032', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN552AP033', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN552AP034', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN552AP035', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN552AP036', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN552AP037', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN552AP038', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN552AP039', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN552AP040', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN552AP041', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN552AP042', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN552AP043', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN552AP044', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN552AP045', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN552AP046', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN552AP047', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN552AP048', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN552AP049', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN552AP050', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN552AP051', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN552FW046', '', '15', '1', '1', '0', '0'],
  ['UP', 'CLVALN552RT001', '', '16', '1', '1', '0', '0'],
  ['UP', 'CLVALN552SW002', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALN552SW025', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLVALN552SW116', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLVALN552SW117', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLVALN552SW120', '', '10', '0', '0', '1', '0'],
  ['UP', 'CLVALN561AP150', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN561AP151', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN561AP152', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN561AP153', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN561AP154', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN561AP155', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN561AP156', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN561AP157', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN561AP158', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN561AP159', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN561AP160', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN561AP161', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN561AP162', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN561AP163', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN561AP164', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN561AP165', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN561FW055', '', '14', '1', '1', '0', '0'],
  ['UP', 'CLVALN561RT002', '', '17', '1', '1', '0', '0'],
  ['UP', 'CLVALN561RT003', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLVALN561SW116', '', '15', '1', '0', '0', '0'],
  ['UP', 'CLVALN561SW117', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN561SW118', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLVALN561SW119', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLVALN561SW120', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLVALN561SW122', '', '8', '1', '2', '0', '0'],
  ['UP', 'CLVALN561SW145', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALN572AP139', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN572AP140', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN572AP141', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN572FW137', '', '17', '0', '0', '8', '0'],
  ['UP', 'CLVALN572RT129', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLVALN572SW131', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLVALN572SW132', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLVALN572SW133', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN572SW134', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN638AP080', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN638AP081', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN638AP082', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN638FW052', '', '19', '1', '1', '0', '0'],
  ['UP', 'CLVALN638RT065', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLVALN638SW066', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLVALN638SW067', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLVALN638SW068', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLVALN638SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN638SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN638SW071', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALN657AP150', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN657AP151', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN657AP152', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN657AP153', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN657AP154', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN657AP155', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN657AP156', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN657AP157', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN657AP158', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN657AP159', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN657AP160', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN657AP161', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN657AP162', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN657AP163', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN657AP164', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN657AP165', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN657AP166', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN657AP167', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN657AP168', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN657FW055', '', '20', '1', '0', '8', '0'],
  ['UP', 'CLVALN657RT129', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLVALN657RT130', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLVALN657SW130', '', '20', '0', '0', '0', '0'],
  ['UP', 'CLVALN657SW131', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN657SW132', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN657SW133', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN657SW134', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN657SW135', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLVALN657SW136', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN657VG131', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLVALN662AP006', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN662AP007', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN662AP008', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN662AP009', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN662AP010', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN662AP011', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN662AP012', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN662AP013', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN662AP014', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN662AP015', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN662AP016', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN662AP017', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN662AP018', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN662AP019', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN662AP020', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN662AP021', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN662AP022', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN662FW056', '', '18', '2', '1', '0', '0'],
  ['UP', 'CLVALN662RT001', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLVALN662SW025', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN662SW116', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLVALN662SW117', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN662SW119', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALN664AP005', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLVALN664AP006', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLVALN664AP007', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLVALN664AP008', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLVALN664AP009', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLVALN664AP010', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLVALN664AP011', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLVALN664AP012', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLVALN664AP013', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLVALN664AP014', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLVALN664AP015', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLVALN664AP016', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLVALN664AP017', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLVALN664AP018', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLVALN664AP019', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLVALN664AP020', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLVALN664AP021', '', '2', '0', '0', '1', '0'],
  ['UP', 'CLVALN664FW057', '', '16', '1', '1', '0', '0'],
  ['UP', 'CLVALN664RT001', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLVALN664SW025', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLVALN664SW116', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLVALN664SW119', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALN664SW120', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN665AP005', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN665AP006', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN665AP007', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN665AP008', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN665AP009', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN665AP010', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN665AP011', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN665AP012', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN665AP013', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN665AP014', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN665AP015', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN665AP016', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN665AP017', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN665AP018', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN665FW058', '', '18', '2', '1', '0', '0'],
  ['UP', 'CLVALN665RT001', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLVALN665SW025', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLVALN665SW116', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLVALN665SW117', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALN665SW118', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALN665SW119', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALN674AP030', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN674AP031', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN674AP032', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN674AP033', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN674AP034', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN674AP035', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN674AP036', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN674AP037', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN674AP038', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN674AP039', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN674AP040', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN674AP041', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN674AP042', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN674AP043', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN674AP044', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN674AP045', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN674AP046', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN674AP047', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN674AP048', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN674AP049', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN674AP050', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN674AP051', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN674FW227', '', '21', '1', '0', '8', '0'],
  ['UP', 'CLVALN674RT001', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLVALN674SW025', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN674SW116', '', '15', '0', '1', '0', '0'],
  ['UP', 'CLVALN674SW118', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLVALN674SW119', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALN674SW120', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLVALN674SW121', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN691AP002', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN691AP003', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN691AP004', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN691FW063', '', '19', '1', '1', '0', '0'],
  ['UP', 'CLVALN691RT001', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLVALN691SW025', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN691SW117', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN691SW120', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALN691SW146', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN691SW147', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLVALN693AP002', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN693AP003', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN693AP004', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN693FW094', '', '13', '1', '1', '0', '0'],
  ['UP', 'CLVALN693RT002', '', '20', '0', '0', '0', '0'],
  ['UP', 'CLVALN693RT003', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLVALN693SW025', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALN693SW116', '', '15', '1', '0', '0', '0'],
  ['UP', 'CLVALN693SW117', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN693SW120', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN693SW121', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALN694AP008', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN694AP009', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN694AP010', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN694AP011', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN694AP012', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN694AP013', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN694AP014', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN694AP015', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN694AP016', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN694AP017', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN694AP018', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN694AP019', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN694AP020', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN694AP021', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN694AP022', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN694FW129', '', '17', '0', '0', '8', '0'],
  ['UP', 'CLVALN694RT001', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLVALN694SW025', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALN694SW116', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLVALN694SW117', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN694SW119', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALN694SW120', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN711AP021', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN711AP022', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN711AP023', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN711AP024', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN711AP025', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN711AP026', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN711AP027', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN711FW013', '', '17', '1', '1', '2', '0'],
  ['UP', 'CLVALN711RT001', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLVALN711SW002', '', '23', '0', '0', '0', '0'],
  ['UP', 'CLVALN711SW003', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLVALN711SW004', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLVALN711SW005', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLVALN711SW006', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLVALN711SW007', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLVALN711SW008', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLVALN711SW009', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLVALN722AP130', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN722AP131', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN722AP132', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN722FW064', '', '19', '1', '1', '0', '0'],
  ['UP', 'CLVALN722RT001', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLVALN722SW116', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLVALN722SW117', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLVALN722SW119', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLVALN722SW120', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLVALN722SW122', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLVALN722SW145', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLVALN731AP002', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN731AP003', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN731AP004', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN731FW066', '', '16', '1', '1', '0', '0'],
  ['UP', 'CLVALN731RT001', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLVALN731SW025', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLVALN731SW116', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLVALN731SW117', '', '7', '0', '0', '4', '0'],
  ['UP', 'CLVALN731SW119', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN731SW122', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN753AP080', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN753AP081', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN753AP082', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN753AP083', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN753AP084', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN753AP085', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN753AP086', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN753AP087', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN753AP088', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN753AP089', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN753AP090', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN753AP091', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN753AP092', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN753FW071', '', '15', '1', '1', '0', '0'],
  ['UP', 'CLVALN753RT001', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLVALN753SW066', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLVALN753SW067', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALN753SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN753SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN753SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN753VG130', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLVALN756AP210', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN756AP211', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN756AP212', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN756AP213', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN756AP214', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN756RT129', '', '16', '1', '1', '0', '0'],
  ['UP', 'CLVALN756SW149', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN756SW150', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN756SW151', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLVALN756SW152', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLVALN756SW153', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLVALN756SW154', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN756SW155', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN756VG003', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLVALN781AP210', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN781AP211', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN781AP212', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN781AP213', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN781RT002', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLVALN781SW021', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN781SW022', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN781SW023', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN781SW024', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLVALN781SW025', '', '11', '0', '0', '1', '0'],
  ['UP', 'CLVALN781SW026', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN781SW029', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLVALN781VG003', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLVALN805AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN805AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN805AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN805AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN805FW079', '', '19', '1', '0', '1', '0'],
  ['UP', 'CLVALN805RT065', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLVALN805SW066', '', '20', '0', '0', '0', '0'],
  ['UP', 'CLVALN805SW067', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN805SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN805SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN805SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN805SW071', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN805SW072', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN805VG130', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLVALN808AP201', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN808AP202', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN808AP203', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN808AP204', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN808FW241', '', '17', '2', '1', '1', '0'],
  ['UP', 'CLVALN808RT129', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLVALN808SW131', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN808SW132', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLVALN808SW133', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLVALN808SW134', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN808SW135', '', '11', '0', '0', '1', '0'],
  ['UP', 'CLVALN808SW136', '', '11', '1', '0', '1', '0'],
  ['UP', 'CLVALN808VG131', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLVALN813AP140', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN813AP141', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN813AP142', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN813AP143', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN813AP144', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN813AP145', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN813AP146', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN813AP147', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN813AP148', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN813AP149', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN813AP150', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN813AP151', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN813AP152', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN813FW081', '', '15', '1', '1', '0', '0'],
  ['UP', 'CLVALN813RT001', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLVALN813SW130', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLVALN813SW131', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN813SW132', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLVALN813SW133', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALN813SW134', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALN813SW135', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLVALN813SW136', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN813VG131', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLVALN822RT001', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLVALN822SW130', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLVALN822SW132', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN822SW133', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLVALN822SW134', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLVALN822SW135', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLVALN822SW136', '', '11', '0', '0', '1', '0'],
  ['UP', 'CLVALN839AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN839AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN839AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN839AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN839FW120', '', '10', '1', '1', '1', '0'],
  ['UP', 'CLVALN839RT066', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLVALN839RT125', '', '19', '0', '0', '0', '0'],
  ['UP', 'CLVALN839RT126', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLVALN839SW066', '', '18', '1', '0', '0', '0'],
  ['UP', 'CLVALN839SW067', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLVALN839SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN839SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN839SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN839SW071', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLVALN839SW072', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN839VG067', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLVALN840AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN840AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN840AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN840AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN840FW188', '', '17', '0', '0', '8', '0'],
  ['UP', 'CLVALN840RT065', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLVALN840SW066', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLVALN840SW067', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN840SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN840SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN840SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN842AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN842AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN842AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN842AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN842FW089', '', '19', '1', '1', '0', '0'],
  ['UP', 'CLVALN842RT065', '', '16', '1', '1', '0', '0'],
  ['UP', 'CLVALN842SW066', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLVALN842SW067', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN842SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN842SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN842SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN842VG130', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLVALN848AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN848AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN848AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN848AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN848FW189', '', '17', '0', '0', '8', '0'],
  ['UP', 'CLVALN848RT193', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLVALN848SW066', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLVALN848SW067', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLVALN848SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN848SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN848SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN848SW071', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN848SW072', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLVALN848VG130', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLVALN849AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN849AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN849AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN849FW139', '', '18', '2', '1', '0', '0'],
  ['UP', 'CLVALN849RT065', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLVALN849SW066', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLVALN849SW067', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLVALN849SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN849SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN849SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN849SW071', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN852AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN852AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN852AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN852AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN852AP104', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN852FW135', '', '16', '1', '0', '8', '0'],
  ['UP', 'CLVALN852RT001', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLVALN852SW066', '', '20', '0', '0', '0', '0'],
  ['UP', 'CLVALN852SW067', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN852SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN852SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN852SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN852SW071', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN852SW072', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN852VG130', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLVALN911AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN911AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN911AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN911AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN911FW192', '', '16', '1', '0', '8', '0'],
  ['UP', 'CLVALN911RT065', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLVALN911SW066', '', '20', '0', '0', '0', '0'],
  ['UP', 'CLVALN911SW067', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN911SW068', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN911SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN911SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN911SW071', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALN911VG130', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLVALN993AP100', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN993AP101', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN993AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN993AP103', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN993AP104', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALN993FW016', '', '19', '1', '1', '0', '0'],
  ['UP', 'CLVALN993RT065', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLVALN993SW066', '', '22', '0', '0', '0', '0'],
  ['UP', 'CLVALN993SW067', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLVALN993SW068', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLVALN993SW069', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLVALN993SW070', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLVALN993SW071', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLVALNA542AP010', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA542AP011', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA542AP012', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA542AP013', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA542AP014', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA542AP015', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA542AP016', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA542AP017', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA542AP018', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA542AP019', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA542AP020', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA542AP021', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA542AP022', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA542AP023', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA542AP024', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA542FW040', '', '16', '1', '1', '0', '0'],
  ['UP', 'CLVALNA542RT001', '', '16', '1', '1', '0', '0'],
  ['UP', 'CLVALNA542SW116', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLVALNA542SW117', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALNA542SW119', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALNA542SW121', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLVALNA542SW122', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALNA542SW123', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLVALNA543AP030', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA543AP031', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA543AP032', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA543AP033', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA543AP034', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA543AP036', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA543AP037', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA543AP038', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA543AP039', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA543AP040', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA543AP041', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA543AP042', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA543AP043', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA543AP044', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA543SW025', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALNA543SW122', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALNA549AP005', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA549AP006', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA549AP007', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA549AP008', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA549AP009', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA549AP010', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA549AP011', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA549AP013', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA549AP014', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA549AP015', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA549AP016', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA549AP017', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA549AP018', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA549AP019', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA549AP020', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA549FW044', '', '19', '1', '1', '0', '0'],
  ['UP', 'CLVALNA549RT001', '', '16', '1', '1', '0', '0'],
  ['UP', 'CLVALNA549SW025', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALNA549SW116', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLVALNA549SW117', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALNA549SW119', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALNA822AP140', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA822AP141', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA822AP142', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA822AP143', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA822AP144', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA822AP145', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA822AP146', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA822AP147', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA822AP148', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA822AP149', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA822AP150', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA822AP151', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALNA822FW083', '', '18', '2', '1', '0', '0'],
  ['UP', 'CLVALNA822RT001', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLVALNA822RT130', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLVALNA822SW130', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLVALNA822SW131', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLVALNA822SW132', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALNA822SW135', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLVALNA822SW136', '', '11', '0', '0', '1', '0'],
  ['UP', 'CLVALNA822VG131', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520AP110', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520AP111', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520AP112', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520AP113', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520AP114', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520AP115', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520AP116', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520AP117', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520AP118', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520AP119', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520AP120', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520AP121', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520AP122', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520AP123', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520AP124', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520AP125', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520AP126', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520AP127', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520AP128', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520AP129', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520AP130', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520AP131', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520AP132', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520AP133', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520AP134', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520AP135', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520AP136', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520AP137', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520AP138', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520AP139', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520AP140', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520AP141', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520AP142', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520AP143', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520AP144', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520AP145', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520AP146', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520AP147', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520FW180', '', '19', '0', '0', '8', '0'],
  ['UP', 'CLVALPA520RT002', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520SW004', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520SW017', '', '14', '1', '0', '0', '0'],
  ['UP', 'CLVALPA520SW018', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520SW019', '', '25', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520SW051', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLVALPA520SW052', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520SW053', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLVALPA520SW054', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520SW055', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520SW056', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520SW058', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520SW060', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520SW062', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520SW064', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520SW065', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLVALPA520SW066', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP094', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP102', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP131', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP132', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP133', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP134', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP135', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP136', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP137', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP138', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP139', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP140', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP141', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP143', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP144', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP145', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP146', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP147', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP149', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP150', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP151', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP152', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP153', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP154', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP155', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP156', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP157', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP158', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP159', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP160', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP161', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP162', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP163', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP165', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP166', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP167', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP168', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP169', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP170', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP171', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP172', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP173', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP175', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP176', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP181', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP182', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572AP183', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572FW097', '', '27', '1', '0', '5', '0'],
  ['UP', 'CLVALPA572RT001', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572SW004', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572SW018', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572SW048', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572SW049', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572SW050', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572SW052', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572SW053', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572SW057', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLVALPA572SW058', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572SW059', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572SW060', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572SW061', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572SW062', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572SW063', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572SW064', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572SW065', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572SW070', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572SW080', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572SW081', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572SW082', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572SW083', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572SW084', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572SW085', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572SW086', '', '13', '1', '1', '0', '0'],
  ['UP', 'CLVALPA572SW087', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572SW088', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALPA572SW177', '', '13', '1', '1', '0', '0'],
  ['UP', 'CLVALPA572SW178', '', '12', '1', '1', '0', '0'],
  ['UP', 'CLVALPA572SW179', '', '13', '1', '1', '0', '0'],
  ['UP', 'CLVALPA572SW180', '', '13', '1', '1', '0', '0'],
  ['UP', 'CLVALPA572SW251', '', '18', '1', '0', '0', '0'],
  ['UP', 'CLVALPA572SW252', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLVALPA756AP220', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA756AP221', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA756AP222', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA756AP223', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA756AP224', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA756AP225', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA756AP226', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA756AP227', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA756AP228', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA756AP229', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA756AP230', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA756AP231', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA756AP232', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA756AP233', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA756AP234', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA756AP235', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA756AP236', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA756FW102', '', '18', '1', '0', '8', '0'],
  ['UP', 'CLVALPA756RT002', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLVALPA756SW004', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALPA756SW130', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLVALPA756SW131', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLVALPA756SW132', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLVALPA756SW133', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALPA756SW134', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLVALPA756SW135', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALPA756SW136', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALPA756SW139', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALPA756SW141', '', '10', '0', '0', '0', '0'],
  ['UP', 'CLVALPA756SW142', '', '10', '0', '0', '0', '0'],
  ['UP', 'CLVALPA756SW144', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALPA756SW145', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALPA756SW146', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALPA756SW147', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALPA756SW148', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALPA756VG004', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLVALPA781AP040', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA781AP041', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA781AP042', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA781AP043', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA781AP044', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA781AP045', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA781AP046', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA781AP047', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA781AP048', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA781AP049', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA781AP050', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA781AP051', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA781AP052', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA781AP053', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA781AP054', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA781AP055', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA781AP056', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA781AP057', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA781AP058', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA781AP059', '', '1', '0', '0', '0', '0'],
  ['DOWN', 'CLVALPA781AP060', '', '0', '0', '0', '1', '0'],
  ['UP', 'CLVALPA781AP061', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA781AP062', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA781AP063', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA781AP064', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALPA781FW174', '', '20', '1', '0', '0', '0'],
  ['UP', 'CLVALPA781RT254', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLVALPA781SW003', '', '10', '2', '0', '0', '0'],
  ['UP', 'CLVALPA781SW004', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLVALPA781SW004-2', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLVALPA781SW005', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALPA781SW006', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALPA781SW007', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALPA781SW008', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLVALPA781SW009', '', '12', '1', '0', '0', '0'],
  ['UP', 'CLVALPA781SW010', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALPA781SW011', '', '10', '1', '0', '0', '0'],
  ['UP', 'CLVALPA781SW012', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALPA781SW013', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALPA781SW014', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLVALPA781SW090', '', '14', '0', '0', '0', '0'],
  ['UP', 'CLVALPA781SW251', '', '17', '1', '0', '0', '0'],
  ['UP', 'CLVALPA781SW252', '', '13', '1', '2', '0', '0'],
  ['UP', 'CLVALPA781VG004', '', '4', '0', '0', '0', '0'],
  ['UP', 'CLVALU020RT241', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLVALU020SW242', '', '2', '1', '0', '0', '0'],
  ['UP', 'CLVALX944AP110', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALX944AP111', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALX944AP112', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALX944AP113', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALX944AP114', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALX944AP115', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALX944AP116', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALX944AP117', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALX944AP118', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALX944AP119', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALX944FW112', '', '18', '1', '0', '8', '0'],
  ['UP', 'CLVALX944RT065', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLVALX944SW066', '', '16', '0', '0', '0', '0'],
  ['UP', 'CLVALX944SW067', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLVALX944SW068', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLVALX944SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALX944SW070', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALX944SW071', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALX944SW072', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALX968AP110', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALX968AP111', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALX968AP112', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALX968AP113', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALX968AP114', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALX968AP115', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALX968AP116', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALX968AP117', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALX968AP118', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALX968AP119', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALX968AP120', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALX968AP121', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALX968AP122', '', '1', '0', '0', '0', '0'],
  ['UP', 'CLVALX968FW032', '', '19', '0', '0', '8', '0'],
  ['UP', 'CLVALX968RT065', '', '18', '0', '0', '0', '0'],
  ['UP', 'CLVALX968SW066', '', '17', '0', '0', '0', '0'],
  ['UP', 'CLVALX968SW067', '', '11', '0', '0', '0', '0'],
  ['UP', 'CLVALX968SW068', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLVALX968SW069', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALX968SW070', '', '13', '0', '0', '0', '0'],
  ['UP', 'CLVALX968SW071', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALX968SW072', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVALX968SW073', '', '12', '0', '0', '0', '0'],
  ['UP', 'CLVDMU003RT177', '', '15', '0', '0', '0', '0'],
  ['UP', 'CLVDMU003SW178', '', '6', '0', '0', '0', '0'],
  ['UP', 'D095SRTEC30', '', '2', '0', '0', '1', '0'],
  ['UP', 'G100603SV17R', '', '2', '0', '0', '1', '0'],
  ['UP', 'G500603RT003', '', '27', '0', '0', '0', '0'],
  ['UP', 'G500603RT004', '', '31', '0', '0', '0', '0'],
  ['UP', 'G500603RT200-STW', '', '138', '0', '0', '3', '0'],
  ['UP', 'G500603RTCON01', '', '22', '0', '0', '0', '0'],
  ['UP', 'g500603svgdy', '', '3', '0', '0', '2', '0'],
  ['UP', 'g500603svnjm', '', '32', '3', '0', '1', '0'],
  ['UP', 'G501603FW001_Bilbao', '', '13', '0', '0', '0', '0'],
  ['UP', 'GW_VOZ_2901', '', '4', '0', '0', '0', '0'],
  ['UP', 'MOXA_NPORT_5110', '', '2', '0', '0', '1', '0'],
  ['DOWN', 'MOXA_NPORT_5150_TRAP', '', '1', '0', '0', '2', '0'],
  ['UP', 'OFC_GATEWAY_RP1', '', '4', '0', '0', '0', '0'],
  ['UP', 'PARIS_GTD_MONEDA_920', '', '17', '0', '0', '0', '0'],
  ['DOWN', 'PBX_Jkennedy', '', '1', '0', '0', '2', '0'],
  ['UP', 'TESt', '', '53', '5', '0', '2', '0'],
];

export {REQUEST};
