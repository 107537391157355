// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.region-map-section {
  background-color: #093b5f;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.region-map-section .image-header {
  position: absolute;
  top: 12px;
  color: white;
  font-weight: bold;
}

.region-map-section .image-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.region-map-section .image-container svg {
  transform: scale(1.75) translateX(-50%) translateY(20px);
}

.region-map-section .image-container a {
  cursor: not-allowed;
  pointer-events: all !important;
}

.region-map-section .image-container .exist:hover {
  fill: #A6B2D8 !important;
  stroke: #FFFFFF !important;
  stroke-width: 4px;
  transition: fill 0.4s;
  cursor: pointer;
}

.region-map-section .image-container .choosen:hover {
  cursor: pointer;
}

.button-image-region {
  border-radius: 3px;
  background-color: #10D884;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./components/GeographicMap/index.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,wDAAwD;AAC1D;;AAEA;EACE,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,wBAAwB;EACxB,0BAA0B;EAC1B,iBAAiB;EACjB,qBAAqB;EACrB,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,eAAe;AACjB","sourcesContent":[".region-map-section {\n  background-color: #093b5f;\n  width: 100%;\n  height: 100%;\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.region-map-section .image-header {\n  position: absolute;\n  top: 12px;\n  color: white;\n  font-weight: bold;\n}\n\n.region-map-section .image-container {\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.region-map-section .image-container svg {\n  transform: scale(1.75) translateX(-50%) translateY(20px);\n}\n\n.region-map-section .image-container a {\n  cursor: not-allowed;\n  pointer-events: all !important;\n}\n\n.region-map-section .image-container .exist:hover {\n  fill: #A6B2D8 !important;\n  stroke: #FFFFFF !important;\n  stroke-width: 4px;\n  transition: fill 0.4s;\n  cursor: pointer;\n}\n\n.region-map-section .image-container .choosen:hover {\n  cursor: pointer;\n}\n\n.button-image-region {\n  border-radius: 3px;\n  background-color: #10D884;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
