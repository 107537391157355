import React from 'react';
import './index.css';

type Props = {
  status: any;
  deviceName: any;
  deviceIp: any;
  deviceId: any;
  deviceType: any;
};

const CardDevice: React.FC<Props> = ({ status, deviceName, deviceIp, deviceId, deviceType }) => {
  function getImageByType() {
    if (deviceType === 'SW') {
      return 'workgroup-switch';
    } else if (deviceType === 'AP') {
      return 'accesspoint';
    } else if (deviceType === 'RT') {
      return 'router';
    } else {
      return 'router';
    }
  }

  function getStatus() {
    if (status === 'UP') {
      return '#10D884';
    } else {
      return '#ED4C5C';
    }
  }

  return (
    <section className="card-device">
      <div className={`device-logo`}>
        <div className={`${getImageByType()}`}></div>
      </div>
      <div className="device-info">
        <div className="device-info-id">{deviceId}</div>
        <div className="device-info-name">{deviceName}</div>
        <div className="device-info-ip">{`IP ${deviceIp}`}</div>
      </div>
      <div className="device-status">
        <div className="indicator" style={{ backgroundColor: getStatus() }}></div>
      </div>
    </section>
  );
};

export default CardDevice;
