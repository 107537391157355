import React from 'react';
// @ts-ignore
import { regiones } from '../../constants/general.js';
import MauleMapSvg from './comunas/maule';
import AntofagastaSvg from './comunas/antofagasta';
import AricaSvg from './comunas/arica';
import TarapacaSvg from './comunas/tarapaca';
import AtacamaSvg from './comunas/atacama';
import CoquimboSvg from './comunas/coquimbo';
import ValparaisoSvg from './comunas/valparaiso';
import SantiagoSvg from './comunas/santiago';
import OHigginsSvg from './comunas/o_higgins';
import BiovioSvg from './comunas/biobio';
import AraucaniaSvg from './comunas/araucania';
import LosRiosSvg from './comunas/los_rios';
import LosLagosSvg from './comunas/los_lagos';
import AisenSvg from './comunas/aisen';
import MagallanesSvg from './comunas/magallanes';

import './index.css';

type Props = {
  region: any;
  selectComuna: any;
  hostsByRegion: any;
};

const GeographicRegion: React.FC<Props> = ({ region, selectComuna, hostsByRegion }) => {
  function getRegion() {
    if (region) {
      const regionDictionary = {
        Maule: <MauleMapSvg />,
        Antofagasta: <AntofagastaSvg />,
        Arica_y_Parinacota: <AricaSvg />,
        Tarapaca: <TarapacaSvg />,
        Atacama: <AtacamaSvg />,
        Coquimbo: <CoquimboSvg />,
        Valparaiso: <ValparaisoSvg />,
        Region_Metropolitana_de_Santiago: <SantiagoSvg choose={selectComuna} comunas={hostsByRegion} />,
        Libertador_General_Bernardo_OHiggins: <OHigginsSvg />,
        'Bio-Bio': <BiovioSvg />,
        La_Araucania: <AraucaniaSvg />,
        Los_Rios: <LosRiosSvg />,
        Los_Lagos: <LosLagosSvg />,
        Aisen_del_General_Carlos_Ibanez_del_Campo: <AisenSvg />,
        Magallanes_y_Antartica_Chilena: <MagallanesSvg />,
      };
      // @ts-ignore
      return regionDictionary[region];
    } else {
      return null;
    }
  }

  return <section className="comuna-map">{getRegion()}</section>;
};

export default GeographicRegion;
