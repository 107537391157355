const info_chile = {
  Aisen_del_General_Carlos_Ibanez_del_Campo: [
    'comuna-Coihaique',
    'comuna-Lago_Verde',
    'comuna-Cisnes',
    'comuna-Guaitecas',
    'comuna-Aisen',
    'comuna-Rio_Ibanez',
    'comuna-Chile_Chico',
    'comuna-Tortel',
    'comuna-Cochrane',
    'comuna-OHiggins',
  ],
  Antofagasta: [
    'comuna-Antofagasta',
    'comuna-Tocopilla',
    'comuna-Maria_Elena',
    'comuna-Calama',
    'comuna-Ollague',
    'comuna-San_Pedro_de_Atacama',
    'comuna-Sierra_Gorda',
    'comuna-Mejillones',
    'comuna-Taltal',
  ],
  La_Araucania: [
    'comuna-Carahue',
    'comuna-Pitrufquen',
    'comuna-Gorbea',
    'comuna-Galvarino',
    'comuna-Perquenco',
    'comuna-Lautaro',
    'comuna-Cholchol',
    'comuna-Vilcun',
    'comuna-Temuco',
    'comuna-Melipeuco',
    'comuna-Padre_Las_Casas',
    'comuna-Cunco',
    'comuna-Freire',
    'comuna-Nueva_Imperial',
    'comuna-Teodoro_Schmidt',
    'comuna-Curarrehue',
    'comuna-Tolten',
    'comuna-Pucon',
    'comuna-Villarrica',
    'comuna-Loncoche',
    'comuna-Saavedra',
    'comuna-Renaico',
    'comuna-Collipulli',
    'comuna-Angol',
    'comuna-Los_Sauces',
    'comuna-Puren',
    'comuna-Ercilla',
    'comuna-Lonquimay',
    'comuna-Traiguen',
    'comuna-Lumaco',
    'comuna-Victoria',
    'comuna-Curacautin',
  ],
  Arica_y_Parinacota: ['comuna-Arica', 'comuna-Camarones', 'comuna-Putre', 'comuna-General_Lagos'],
  Atacama: [
    'comuna-Diego_de_Almagro',
    'comuna-Chanaral',
    'comuna-Copiapo',
    'comuna-Tierra_Amarilla',
    'comuna-Caldera',
    'comuna-Alto_del_Carmen',
    'comuna-Vallenar',
    'comuna-Freirina',
    'comuna-Huasco',
  ],
  'Bio-Bio': [
    'comuna-Tirua',
    'comuna-Contulmo',
    'comuna-Canete',
    'comuna-Los_Alamos',
    'comuna-Lebu',
    'comuna-Curanilahue',
    'comuna-Arauco',
    'comuna-Florida',
    'comuna-Lota',
    'comuna-Coronel',
    'comuna-San_Pedro_de_la_Paz',
    'comuna-Penco',
    'comuna-Talcahuano',
    'comuna-Chiguayante',
    'comuna-Hualqui',
    'comuna-Santa_Juana',
    'comuna-Concepcion',
    'comuna-Hualpén',
    'comuna-Tome',
    'comuna-Negrete',
    'comuna-Nacimiento',
    'comuna-Mulchen',
    'comuna-Laja',
    'comuna-Quilaco',
    'comuna-Alto_Biobio',
    'comuna-Santa_Barbara',
    'comuna-Quilleco',
    'comuna-Los_Angeles',
    'comuna-San_Rosendo',
    'comuna-Yumbel',
    'comuna-Tucapel',
    'comuna-Antuco',
    'comuna-Cabrero',
  ],
  Coquimbo: [
    'comuna-Vicuna',
    'comuna-La_Higuera',
    'comuna-Paihuano',
    'comuna-La_Serena',
    'comuna-Andacollo',
    'comuna-Coquimbo',
    'comuna-Combarbala',
    'comuna-Ovalle',
    'comuna-Rio_Hurtado',
    'comuna-Monte_Patria',
    'comuna-Punitaqui',
    'comuna-Canela',
    'comuna-Los_Vilos',
    'comuna-Salamanca',
    'comuna-Illapel',
  ],
  Los_Lagos: [
    'comuna-Frutillar',
    'comuna-Fresia',
    'comuna-Llanquihue',
    'comuna-Los_Muermos',
    'comuna-Cochamo',
    'comuna-Puerto_Varas',
    'comuna-Puerto_Montt',
    'comuna-Maullin',
    'comuna-Calbuco',
    'comuna-Ancud',
    'comuna-Quemchi',
    'comuna-Dalcahue',
    'comuna-Quinchao',
    'comuna-Castro',
    'comuna-Curaco_de_Velez',
    'comuna-Chonchi',
    'comuna-Puqueldon',
    'comuna-Quellon',
    'comuna-Queilen',
    'comuna-Chaiten',
    'comuna-Hornopiren',
    'comuna-Futaleufu',
    'comuna-Palena',
    'comuna-San_Juan_de_la_Costa',
    'comuna-San_Pablo',
    'comuna-Osorno',
    'comuna-Rio_Negro',
    'comuna-Purranque',
    'comuna-Puyehue',
    'comuna-Puerto_Octay',
  ],
  Los_Rios: [
    'comuna-Mariquina',
    'comuna-Lanco',
    'comuna-Panguipulli',
    'comuna-Mafil',
    'comuna-Los_Lagos',
    'comuna-Valdivia',
    'comuna-Corral',
    'comuna-Paillaco',
    'comuna-Futrono',
    'comuna-La_Union',
    'comuna-Rio_Bueno',
    'comuna-Lago_Ranco',
  ],
  Magallanes_y_Antartica_Chilena: [
    'comuna-Antartica',
    'comuna-Cabo_de_Hornos',
    'comuna-Timaukel',
    'comuna-Porvenir',
    'comuna-Primavera',
    'comuna-Torres_del_Paine',
    'comuna-Natales',
    'comuna-Laguna_Blanca',
    'comuna-San_Gregorio',
    'comuna-Rio_Verde',
    'comuna-Punta_Arenas',
  ],
  Maule: [
    'comuna-Talca',
    'comuna-Constitucion',
    'comuna-Curepto',
    'comuna-Empedrado',
    'comuna-Maule',
    'comuna-Pelarco',
    'comuna-Pencahue',
    'comuna-Rio_Claro',
    'comuna-San_Clemente',
    'comuna-San_Rafael',
    'comuna-Cauquenes',
    'comuna-Chanco',
    'comuna-Pelluhue',
    'comuna-Curico',
    'comuna-Hualane',
    'comuna-Licanten',
    'comuna-Molina',
    'comuna-Rauco',
    'comuna-Romeral',
    'comuna-Sagrada_Familia',
    'comuna-Teno',
    'comuna-Vichuquen',
    'comuna-Linares',
    'comuna-Colbun',
    'comuna-Longavi',
    'comuna-Parral',
    'comuna-Retiro',
    'comuna-San_Javier',
    'comuna-Villa_Alegre',
    'comuna-Yerbas_Buenas',
  ],
  Libertador_General_Bernardo_OHiggins: [
    'comuna-Navidad',
    'comuna-Litueche',
    'comuna-Pichilemu',
    'comuna-Paredones',
    'comuna-La_Estrella',
    'comuna-Marchihue',
    'comuna-Pumanque',
    'comuna-Lolol',
    'comuna-Peralillo',
    'comuna-Palmilla',
    'comuna-Santa_Cruz',
    'comuna-Chepica',
    'comuna-Nancagua',
    'comuna-Placilla',
    'comuna-Chimbarongo',
    'comuna-San_Fernando',
    'comuna-Las_Cabras',
    'comuna-Pichidegua',
    'comuna-Peumo',
    'comuna-Coltauco',
    'comuna-Donihue',
    'comuna-Coinco',
    'comuna-El_Olivar',
    'comuna-Rancagua',
    'comuna-Graneros',
    'comuna-Quinta_de_Tilcoco',
    'comuna-San_Vicente',
    'comuna-Malloa',
    'comuna-Rengo',
    'comuna-Requinoa',
    'comuna-Machali',
    'comuna-Codegua',
    'comuna-Mostazal',
  ],
  Region_Metropolitana_de_Santiago: [
    'comuna-Cerrillos',
    'comuna-Cerro_Navia',
    'comuna-Conchali',
    'comuna-El_Bosque',
    'comuna-Estacion_Central',
    'comuna-Huechuraba',
    'comuna-Independencia',
    'comuna-La_Cisterna',
    'comuna-La_Florida',
    'comuna-La_Granja',
    'comuna-La_Pintana',
    'comuna-La_Reina',
    'comuna-Las_Condes',
    'comuna-Lo_Barnechea',
    'comuna-Lo_Espejo',
    'comuna-Lo_Prado',
    'comuna-Macul',
    'comuna-Maipu',
    'comuna-Nunoa',
    'comuna-Pedro_Aguirre_Cerda',
    'comuna-Penalolen',
    'comuna-Providencia',
    'comuna-Pudahuel',
    'comuna-Quilicura',
    'comuna-Quinta_Normal',
    'comuna-Recoleta',
    'comuna-Renca',
    'comuna-Santiago',
    'comuna-San_Joaquin',
    'comuna-San_Miguel',
    'comuna-San_Ramon',
    'comuna-Vitacura',
    'comuna-Puente_Alto',
    'comuna-Pirque',
    'comuna-San_Jose_de_Maipo',
    'comuna-Colina',
    'comuna-Lampa',
    'comuna-Tiltil',
    'comuna-San_Bernardo',
    'comuna-Buin',
    'comuna-Calera_de_Tango',
    'comuna-Paine',
    'comuna-Melipilla',
    'comuna-Alhue',
    'comuna-Curacavi',
    'comuna-Maria_Pinto',
    'comuna-San_Pedro',
    'comuna-Talagante',
    'comuna-El_Monte',
    'comuna-Isla_de_Maipo',
    'comuna-Padre_Hurtado',
    'comuna-Penaflor',
  ],
  Tarapaca: [
    'comuna-Iquique',
    'comuna-Alto_Hospicio',
    'comuna-Pozo_Almonte',
    'comuna-Camina',
    'comuna-Colchane',
    'comuna-Huara',
    'comuna-Pica',
  ],
  Valparaiso: [
    'comuna-Santo_Domingo',
    'comuna-San_Antonio',
    'comuna-Cartagena',
    'comuna-El_Tabo',
    'comuna-El_Quisco',
    'comuna-Algarrobo',
    'comuna-Limache',
    'comuna-Quillota',
    'comuna-Hijuelas',
    'comuna-Olmue',
    'comuna-La_Cruz',
    'comuna-Calera',
    'comuna-Nogales',
    'comuna-Valparaiso',
    'comuna-Casablanca',
    'comuna-Vina_del_Mar',
    'comuna-Concon',
    'comuna-Villa_Alemana',
    'comuna-Quilpue',
    'comuna-Juan_Fernandez',
    'comuna-Quintero',
    'comuna-Puchuncavi',
    'comuna-Catemu',
    'comuna-Llaillay',
    'comuna-Panquehue',
    'comuna-San_Felipe',
    'comuna-Putaendo',
    'comuna-Santa_Maria',
    'comuna-San_Esteban',
    'comuna-Los_Andes',
    'comuna-Rinconada',
    'comuna-Calle_Larga',
    'comuna-Zapallar',
    'comuna-Papudo',
    'comuna-La_Ligua',
    'comuna-Petorca',
    'comuna-Cabildo',
    'comuna-Isla_de_Pascua',
  ],
  Nuble: [
    'comuna-Cobquecura',
    'comuna-Coelemu',
    'comuna-Ninhue',
    'comuna-Portezuelo',
    'comuna-Quirihue',
    'comuna-Ránquil',
    'comuna-Treguaco',
    'comuna-Bulnes',
    'comuna-Chillán Viejo',
    'comuna-Chillán',
    'comuna-El Carmen',
    'comuna-Pemuco',
    'comuna-Pinto',
    'comuna-Quillón',
    'comuna-San Ignacio',
    'comuna-Yungay',
    'comuna-Coihueco',
    'comuna-Ñiquén',
    'comuna-San Carlos',
    'comuna-San Fabián',
    'comuna-San Nicolás',
  ],
};

const region_dictionary = {
  ANT: 'Antofagasta',
  ARA: 'La_Araucania',
  ARI: 'Arica_y_Parinacota',
  ATA: 'Atacama',
  BIO: 'Bio-Bio',
  BUE: 'Buenos_Aires',
  CON: 'Concon',
  COQ: 'Coquimbo',
  CUR: 'Curico',
  IND: 'Independencia',
  LAG: 'Los_Lagos',
  LAN: 'Lanco',
  LIN: 'Linares',
  MAU: 'Maule',
  NUB: 'Nuble',
  OHI: 'Libertador_General_Bernardo_OHiggins',
  OSO: 'Osorno',
  QUI: 'Quilpue',
  RAN: 'Rancagua',
  SGO: 'Region_Metropolitana_de_Santiago',
  TAL: 'Talca',
  TAR: 'Tarapaca',
  TEM: 'Temuco',
  VAL: 'Valdivia',
};

const region_number = {
  Aisen_del_General_Carlos_Ibanez_del_Campo: 'XI',
  Magallanes_y_Antartica_Chilena: 'XII',
  Tarapaca: 'I',
  Arica_y_Parinacota: 'XV',
  Antofagasta: 'II',
  Atacama: 'III',
  Coquimbo: 'IV',
  Region_Metropolitana_de_Santiago: 'RM',
  Valparaiso: 'V',
  La_Araucania: 'IX',
  Los_Rios: 'XIV',
  'Bio-Bio': 'VIII',
  Libertador_General_Bernardo_OHiggins: 'VI',
  Los_Lagos: 'X',
  Maule: 'VII',
};

export { info_chile, region_dictionary, region_number };
