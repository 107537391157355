import React from 'react';
import './index.css';

type Props = {
  hostName: any;
  numServicesCrit: any;
  numServicesOk: any;
  numServicesPending: any;
  numServicesUnknown: any;
  numServicesWarn: any;
};

const ServicesForHost: React.FC<Props> = ({
  hostName,
  numServicesCrit,
  numServicesOk,
  numServicesPending,
  numServicesUnknown,
  numServicesWarn,
}) => {
  return (
    <section className="services-for-host">
      <div className="svc-name">{hostName}</div>
      <div
        className="svc-ok"
        style={{
          background: numServicesOk > 0 ? '#10D884' : 'white',
          // color: numServicesOk > 0 ? 'white' : 'black',
          padding: '2px',
        }}
      >
        {numServicesOk}
      </div>
      <div
        className="svc-warn"
        style={{
          background: numServicesWarn > 0 ? '#EFCB0D' : 'white',
          // color: numServicesOk > 0 ? 'white' : 'black',
          padding: '2px',
        }}
      >
        {numServicesWarn}
      </div>
      <div
        className="svc-crit"
        style={{
          background: numServicesCrit > 0 ? '#ED4C5C' : 'white',
          // color: numServicesOk > 0 ? 'white' : 'black',
          padding: '2px',
        }}
      >
        {numServicesCrit}
      </div>
      <div className="svc-pending">{numServicesPending}</div>
      <div className="svc-unknown">{numServicesUnknown}</div>
    </section>
  );
};

export default ServicesForHost;
