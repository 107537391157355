import { PanelPlugin } from '@grafana/data';
// import { PanelOptions } from './types';
import { ImagePanel } from './components';

/**
 * Panel Plugin
 */
export const plugin = new PanelPlugin(ImagePanel).setPanelOptions((builder) => {
  // builder.addFieldNamePicker({
  //   path: 'name',
  //   name: 'Field name',
  //   description: 'Name of the field with URL',
  //   settings: {
  //     filter: (f: Field) => f.type === FieldType.string,
  //     noFieldsMessage: 'No strings fields found'
  //   },
  // });
  // return builder;
  return builder
    .addTextInput({
      path: 'COUNTRY',
      name: 'Pais',
      description: 'Pais a los que pertenecen los hosts del dashboard',
      defaultValue: 'CL',
    })
    .addTextInput({
      path: 'STORE_WARNING',
      name: 'Umbral warning para cada tienda',
      description: 'Umbral maximo de hosts en estado down dentro de una tienda',
      defaultValue: '1',
    })
    .addTextInput({
      path: 'STORE_CRITICAL',
      name: 'Umbral critical para cada tienda',
      description: 'Umbral maximo de hosts en estado down dentro de una tienda',
      defaultValue: '2',
    })
    .addTextInput({
      path: 'STORE_RELATIONSHIP',
      name: 'Relacion de comunas y tiendas',
      description: 'El formato es: <Comuna>:<Iniciales tienda>',
      defaultValue: '',
      settings: {
        useTextarea: true,
      }
    })

    // .addBooleanSwitch({
    //   path: 'showSeriesCount',
    //   name: 'Show series counter',
    //   defaultValue: false,
    // })
    // .addRadio({
    //   path: 'seriesCountSize',
    //   defaultValue: 'sm',
    //   name: 'Series counter size',
    //   settings: {
    //     options: [
    //       {
    //         value: 'sm',
    //         label: 'Small',
    //       },
    //       {
    //         value: 'md',
    //         label: 'Medium',
    //       },
    //       {
    //         value: 'lg',
    //         label: 'Large',
    //       },
    //     ],
    //   },
    //   showIf: (config) => config.showSeriesCount,
    // });
});
