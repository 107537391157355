import React, { useEffect, useState } from 'react';
import StoreItem from './StoreItem/StoreItem';
import './index.css';

type Props = {
  storeHosts: any;
  warning: any;
  critical: any;
  selectStore: any;
};

const StoreList: React.FC<Props> = ({ storeHosts, warning, critical, selectStore }) => {
  const [infoStore, setInfoStore] = useState<any>({});

  function getStores() {
    const storesOk = [];
    const storesWarning = [];
    const storesCritical = [];

    let storeStatus = '';
    for (const key in infoStore) {
      if (Object.prototype.hasOwnProperty.call(infoStore, key)) {
        let auxCount = 0;
        infoStore[key].forEach((host: any) => {
          // @ts-ignore
          const { hostName, hostStatus } = host;
          if (hostStatus !== 'UP') {
            auxCount++;
          }
        });
        if (auxCount >= critical) {
          storeStatus = 'CRITICAL';
        } else if (auxCount >= warning) {
          storeStatus = 'WARNING';
        } else {
          storeStatus = 'OK';
        }
        const storeElement = (
          <StoreItem
            name={key}
            arrayHosts={infoStore[key]}
            warning={warning}
            critical={critical}
            selectStore={selectStore}
            infoStore={infoStore[key]}
            storeStatus={storeStatus}
          />
        );
        if (storeStatus === 'CRITICAL') {
          storesCritical.push(storeElement);
        } else if(storeStatus === 'WARNING') {
          storesWarning.push(storeElement);
        } else if (storeStatus === 'OK') {
          storesOk.push(storeElement);
        }
      }
    }
    // @ts-ignore
    return [...storesCritical, ...storesWarning, ...storesOk];
  }

  useEffect(() => {
    const arryStoresInfo = {};
    storeHosts.map((store: any) => {
      const [
        hostStatus,
        hostName,
        storeName,
        device,
        numberDevice,
        numServicesOk,
        numServicesWarn,
        numServicesUnknown,
        numServicesCrit,
        numServicesPending,
      ] = store;
      // @ts-ignore
      if (!arryStoresInfo[storeName]) {
        // @ts-ignore
        arryStoresInfo[storeName] = [];
      }
      // @ts-ignore
      arryStoresInfo[storeName].push({
        hostName,
        hostStatus,
        numServicesOk,
        numServicesWarn,
        numServicesUnknown,
        numServicesCrit,
        numServicesPending,
        device,
        numberDevice,
      });
    });
    // for (const str in arryStoresInfo) {
    //   if (Object.prototype.hasOwnProperty.call(arryStoresInfo, str)) {
    //     // @ts-ignore
    //     const element = arryStoresInfo[str];
    //     console.log(element);

    //     // @ts-ignore
    //     element.sort((first, second)=>{
    //       if (first.hostStatus === 'UP') {
    //         return 1;
    //       }
    //       return -1;
    //     })
    //   }
    // }
    setInfoStore(arryStoresInfo);
  }, [storeHosts]);

  return <section className="store-list">{storeHosts.length ? getStores() : null}</section>;
};

export default StoreList;
