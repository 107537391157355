const comunas_maule = {
  status: [
      // 0,
      0,
      2,
      0,
      0,
      3,
      2,
      0,
      0,
      1,
      0,
      2,
      0,
      0,
      0,
      0,
      1,
      0,
      3,
      0,
      0,
      0,
      1,
      3,
      0,
      1,
      0,
      0,
      1,
      0,
  ],
  Maule: [
      // "Talca",
      "Constitucion",
      "Curepto",
      "Empedrado",
      "Maule",
      "Pelarco",
      "Pencahue",
      "Rio_Claro",
      "San_Clemente",
      "San_Rafael",
      "Cauquenes",
      "Chanco",
      "Pelluhue",
      "Curico",
      "Hualane",
      "Licanten",
      "Molina",
      "Rauco",
      "Romeral",
      "Sagrada_Familia",
      "Teno",
      "Vichuquen",
      "Linares",
      "Colbun",
      "Longavi",
      "Parral",
      "Retiro",
      "San_Javier",
      "Villa_Alegre",
      "Yerbas_Buenas",
  ],
};

const regiones = [
  'Aisen_del_General_Carlos_Ibanez_del_Campo',
  'Magallanes_y_Antartica_Chilena',
  'Tarapaca',
  'Arica_y_Parinacota',
  'Antofagasta',
  'Atacama',
  'Coquimbo',
  'Region_Metropolitana_de_Santiago',
  'Valparaiso',
  'La_Araucania',
  'Los_Rios',
  'Bio-Bio',
  'Libertador_General_Bernardo_OHiggins',
  'Los_Lagos',
  'Maule',
];

export { regiones, comunas_maule };
