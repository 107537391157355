// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.arrowIndicator {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.arrowIndicator .arrow {
  height: 1px;
  background-color: #728acc;
}

.arrowIndicator .button {
  width: 14px;
  height: 14px;
  background-color: #10D884;
  border-radius: 3px;
  cursor: pointer;
}

.arrowIndicator .number {
  width: 30px;
  color: white;
  margin-left: 8px;
  font-weight: 300;
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./components/GeographicMap/components/ArrowIndicator/index.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".arrowIndicator {\n  position: fixed;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n.arrowIndicator .arrow {\n  height: 1px;\n  background-color: #728acc;\n}\n\n.arrowIndicator .button {\n  width: 14px;\n  height: 14px;\n  background-color: #10D884;\n  border-radius: 3px;\n  cursor: pointer;\n}\n\n.arrowIndicator .number {\n  width: 30px;\n  color: white;\n  margin-left: 8px;\n  font-weight: 300;\n  font-size: 14px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
