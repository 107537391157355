// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.comuna-map {
  width: 100%;
  height: 100%;
  background-color: #4D7CA1;
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: relative; */
}
.comuna-map
svg {
  width: max-content;
  /* height: 100%; */
  /* position: absolute; */
  /* height: 100vh;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0; */
}

.comuna-map
.antofagasta
svg {
  transform: scale(0.8);
}

.comuna-map
.atacama,
.comuna-map
.coquimbo,
.comuna-map
.santiago {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.comuna-map
.atacama
svg {
  /* transform: scale(0.8); */
  width: 400px;
  height: 550px;
}

.comuna-map
.coquimbo
svg {
  transform: scale(0.8);
}

.comuna-map
.santiago
svg {
  transform: scale(1.2);
}

.comuna-map
.magallanes
svg {
  transform: scale(0.7);
}`, "",{"version":3,"sources":["webpack://./components/GeographicRegion/index.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,wBAAwB;AAC1B;AACA;;EAEE,kBAAkB;EAClB,kBAAkB;EAClB,wBAAwB;EACxB;;;;cAIY;AACd;;AAEA;;;EAGE,qBAAqB;AACvB;;AAEA;;;;;;EAME,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;;;EAGE,2BAA2B;EAC3B,YAAY;EACZ,aAAa;AACf;;AAEA;;;EAGE,qBAAqB;AACvB;;AAEA;;;EAGE,qBAAqB;AACvB;;AAEA;;;EAGE,qBAAqB;AACvB","sourcesContent":[".comuna-map {\n  width: 100%;\n  height: 100%;\n  background-color: #4D7CA1;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  /* position: relative; */\n}\n.comuna-map\nsvg {\n  width: max-content;\n  /* height: 100%; */\n  /* position: absolute; */\n  /* height: 100vh;\n  right: 0;\n  left: 0;\n  top: 0;\n  bottom: 0; */\n}\n\n.comuna-map\n.antofagasta\nsvg {\n  transform: scale(0.8);\n}\n\n.comuna-map\n.atacama,\n.comuna-map\n.coquimbo,\n.comuna-map\n.santiago {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.comuna-map\n.atacama\nsvg {\n  /* transform: scale(0.8); */\n  width: 400px;\n  height: 550px;\n}\n\n.comuna-map\n.coquimbo\nsvg {\n  transform: scale(0.8);\n}\n\n.comuna-map\n.santiago\nsvg {\n  transform: scale(1.2);\n}\n\n.comuna-map\n.magallanes\nsvg {\n  transform: scale(0.7);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
