/* eslint-disable */ 
import React, { useEffect, useState } from 'react';
import ComunaList from '../ComunaList/ComunaList';
import StoreList from '../StoreList/StoreList';

import './index.css';

type Props = {
  region: String;
  comunasByRegion: any;
  hostsByRegion: any;
  choosenRegion: any;
  STORE_WARNING: any;
  STORE_CRITICAL: any;
  selectStore: any;
  width: any;
  onChange: any;
  comunaSelected: any;
  selectComuna: any;
};

const ComunaStoreList: React.FC<Props> = ({
  region,
  comunasByRegion,
  hostsByRegion,
  choosenRegion,
  STORE_WARNING,
  STORE_CRITICAL,
  selectStore,
  width,
  onChange,
  selectComuna,
  comunaSelected,
}) => {
  const [arrayComunas, setArrayComunas] = useState<any>([]);
  const [storesByComuna, setStoresByComuna] = useState<any>({});
  // const [comunaSelected, setComunaSelected] = useState<any>(null);

  // function selectComuna(comuna: any) {
  //   // console.log(comuna);
  //   setComunaSelected(comuna);
  // }

  useEffect(() => {
    // console.log(region);
    // console.log(comunasByRegion);
    // console.log(hostsByRegion);

    const comunasWithStores = [];
    for (const cmn in hostsByRegion) {
      if (Object.prototype.hasOwnProperty.call(hostsByRegion, cmn)) {
        const indexCmn = comunasByRegion.findIndex((nameCmn: any) => `comuna-${cmn}` === nameCmn);
        if (indexCmn !== -1) {
          comunasWithStores.push(cmn);
          const enteredStores = hostsByRegion[cmn];
          const copyStoresByComuna = JSON.parse(JSON.stringify(storesByComuna));
          // console.log(enteredStores);
          // console.log(choosenRegion);

          // const intersection = enteredStores.filter((element: any) => choosenRegion.includes(element));
          // const intersection = enteredStores.filter((item1: any) => {
          //   return choosenRegion.some((item2: any) => {
          //     const thirdElement = item2[2];
          //     return item1.includes(thirdElement);
          //   });
          // });
          const intersection = choosenRegion.filter((item2: any) => {
            const thirdElement = item2[2];
            return enteredStores.some((item1: any) => item1.includes(thirdElement));
          });
          copyStoresByComuna[cmn] = intersection;
          setStoresByComuna(copyStoresByComuna);
          // console.log(copyStoresByComuna);
        }
      }
    }
    setArrayComunas(comunasWithStores);
    // }, [region, comunasByRegion, hostsByRegion, arrayComunas]);
  }, [region, comunasByRegion, hostsByRegion]);

  useEffect(() => {
    onChange(!!arrayComunas.length);
  }, [arrayComunas]);
  // console.log(storesByComuna);
  // console.log(storesByComuna[comunaSelected]);
  // console.log(arrayComunas);

  return (
    <section className="wrap-region-comuna-list" style={{ width: width }}>
      {arrayComunas.length ? (
        <ComunaList
          listNames={arrayComunas}
          listStatus={[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]}
          hostsByRegion={hostsByRegion}
          onSelect={selectComuna}
          width={arrayComunas.length ? '44%' : '0px'}
        />
      ) : null}
      <section className="wrap-region-store-list" style={{ width: arrayComunas.length ? '56%' : '100%' }}>
        {storesByComuna[comunaSelected] ? (
          <StoreList
            storeHosts={storesByComuna[comunaSelected]}
            warning={STORE_WARNING}
            critical={STORE_CRITICAL}
            selectStore={selectStore}
          />
        ) : (
          <StoreList
            storeHosts={choosenRegion}
            warning={STORE_WARNING}
            critical={STORE_CRITICAL}
            selectStore={selectStore}
          />
        )}
      </section>
    </section>
  );
};

export default ComunaStoreList;
